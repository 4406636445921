import { api, escalateError, getResponseData } from './index';

export default class ProjectApi {
    static saveProject(project, projectId) {
        return api.post(`/project${projectId ? `/${projectId}` : ''}`, project,
            { headers: { 'Content-Type': 'multipart/form-data' } })
                .then(getResponseData)
                .catch(escalateError);
    }

    static getProjects() {
        return api.get('/project')
                .then(getResponseData)
                .catch(escalateError);
    }

    static getProjectsByUser(user) {
        return api.get(`/project/${user}`)
                .then(getResponseData)
                .catch(escalateError);
    }

    static deleteProjectByProjectId(projectId) {
        return api.delete(`/project/${projectId}`)
                .then(getResponseData)
                .catch(escalateError);
    }

    static createProjectRegistry({ projectId, ...all }) {
        return api.post(`/project/registries/${projectId}`,
            { ...all })
                .then(getResponseData)
                .catch(escalateError);
    }

    static getProjectRegistriesByProjectId({ projectId, period }) {
        return api.get(`/project/registries/${projectId}/${period}`)
                .then(getResponseData)
                .catch(escalateError);
    }
}
