const useWalletData = () => {
  const initialProjectState = {
    name: '',
    address: '',
    admin: '',
    date1: '',
    date2: '',
  };

  const checkProjectState = (obj) => {
    let allFieldsFilled = false;
    Object.keys(initialProjectState).forEach((data, pos) => {
      if (obj[`${data}`] !== initialProjectState[`${data}`]) allFieldsFilled = true;
    });
    return allFieldsFilled;
  };

  const initialDataState = {
    projectId: '',
    amount: '',
    panels: '',
  };

  const checkDataState = (obj) => {
    let allFieldsFilled = false;
    Object.keys(initialDataState).forEach((data, pos) => {
      if (obj[`${data}`] !== initialDataState[`${data}`]) allFieldsFilled = true;
    });
    return allFieldsFilled;
  };

  return {
    initialDataState,
    checkDataState,
    initialProjectState,
    checkProjectState,
  };
};

export default useWalletData;
