/* eslint-disable react/prop-types */
import React from 'react';
import user from '../../../res/images/userValidation.png';
import Icon from '@mdi/react';
import { mdiAlertCircleOutline } from '@mdi/js';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogButton
} from '@rmwc/dialog';
import { Link } from 'react-router-dom';

const ValidationDialog = ({ openDialog, setOpenDialog, type }) => {
    return (
      <Dialog
            open={openDialog}
            onClose={() => {
                setOpenDialog(false);
            } }
        >
        <DialogTitle>
          {(type === 'notValidated') && <>necesitamos verificar tu identidad</>}
          {(type === 'noPanels') && <>tienes que tener un panel o más</>}
          {(type === 'noPay') && <>UPS !!!!</>}
          {(type === 'noDocuments') && <>Necesitamos conocer un poco más de ti.</>}
        </DialogTitle>
        <DialogContent>
          {(type === 'notValidated')
         && <>
           validate de forma correcta
           y acepta el marco de colaboración
           <div>
             <img src={user} width={'100%'} style={{ marginTop: '2rem' }}
                  alt={'validation error'}/>
           </div>
         </>}
          {(type === 'noPay') && <>
            <div>
              <Icon path={mdiAlertCircleOutline} size={4}/>
            </div>
            el dinero en billetera no es suficiente</>}
          {(type === 'noPanels') && <>
            <div>
              <Icon path={mdiAlertCircleOutline} size={4}/>
            </div></>}
          {(type === 'noDocuments') && <>
            Por favor, completa la información de tu perfil primero.</>}
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-around' }}>
          {(type === 'notValidated' || type === 'noPanels')
          && <DialogButton action={'close'}>
            Aceptar
          </DialogButton>}
          {(type === 'noPay')
            && <>
              <DialogButton action={'close'}>
                Aceptar
              </DialogButton>
              <Link to={'/recharge'} style={{ textDecoration: 'none' }}>
                <DialogButton isDefaultAction unelevated>
                  Recargar Billetera
                </DialogButton>
              </Link>
            </>
          }
          {(type === 'noDocuments')
            && <>
              <DialogButton action={'close'}>
                Aceptar
              </DialogButton>
              <Link to={'/profile'} style={{ textDecoration: 'none' }}>
                <DialogButton isDefaultAction unelevated>
                  Ir a mi perfil
                </DialogButton>
              </Link>
            </>
          }
        </DialogActions>
      </Dialog>
    );
};

export default ValidationDialog;
