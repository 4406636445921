import React from 'react';
import {
    mdiCalendarBlankOutline,
    mdiCurrencyUsd,
    mdiMapMarker,
    mdiLayersTriple,
    mdiLightningBolt,
    mdiClockTimeNineOutline,
    mdiCashMultiple,
    mdiSale,
    mdiCash,
    mdiTransmissionTower,
    mdiWrenchClock,
    mdiAccountCowboyHat,
    mdiAccountCog
} from '@mdi/js';
import styles from '../description.module.css';
import Icon from '@mdi/react';
import PropTypes from 'prop-types';
import Utils from '../../../../../utils/Utils';
import { Tooltip } from '@rmwc/tooltip';

// eslint-disable-next-line react/prop-types
const BasicInformation = ({ label, firstText, icon, description }) => {
    return <>
      <div style={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}>
        <div style={{ marginTop: '0.8rem' }}>
          <Icon path={icon} size={1} className={styles.svg}/>
        </div>
        <div className={styles.text}>
          <Tooltip content={description || label}>
            <p><span>{label}</span>{firstText}</p>
          </Tooltip>
        </div>
      </div>
    </>;
};

// eslint-disable-next-line react/prop-types
const GeneralSection = ({ project = {}, infoDiscount = {}, dataFormProject = { } }) => {
    const { projectData = {}, project: auxProjectData = {} } = project;
    const data = projectData?.text ? projectData : auxProjectData;

    return <>
      <div className={styles['general-information']} >
        <div className={styles.grid} >
          <BasicInformation firstText={`${data.beginningDateSeed || data.beginningDate || '--'}`}
                            label={'Inicio estimado de operación: '}
                            icon={mdiCalendarBlankOutline}/>
          <BasicInformation firstText={
            <>
              {data.farmsize || 0}
              &nbsp;m<sup>2</sup>
            </>}
                            label={'Tamaño de la granja: '}
                            icon={mdiLayersTriple}/>
          <BasicInformation firstText={Utils.formatter.format(data.totalCost) || 'Ubicación'}
                            icon={mdiCurrencyUsd}
                            label={'Inversión total: '}/>
          <BasicInformation firstText={`${data.noOfDigitalPanels || 0} paneles`}
                            icon={mdiLightningBolt}
                            label={'Números de papeles: '}/>
          <BasicInformation firstText={`${data.address || ''}`}
                            label={'Ubicación: '}
                            icon={mdiMapMarker}
                            description={'Ubicación del proyecto'}/>
          <BasicInformation
            firstText={`Contrato a ${data.contract || '0'}
            ${project.isCrowdfunding ? 'Días' : 'Años'}`}
            label={'Vida útil: '}
            icon={mdiClockTimeNineOutline}/>
          <BasicInformation firstText={`${parseFloat(data.powerInstalled || 0).toFixed(2)}  kWp`}
                            label={'Potencia Instalada: '}
                            icon={mdiTransmissionTower}/>
        </div>
        <hr/>
        <div className={styles.percentage}>
          <Tooltip content={'asignacion de dinero'}>
            <h4>Porcentajes de Asignación de Dinero</h4>
          </Tooltip>
          <div className={styles.grid} >
            <BasicInformation firstText={`${(Number(data?.percentage?.farmer)) || 0}%`}
                              label={'Granjeros: '}
                              icon={mdiAccountCowboyHat}/>

            <BasicInformation firstText={`${(Number(data?.percentage?.maintance)) || 0}%`}
                              icon={mdiWrenchClock}
                              label={'Fondo de Manteniento: '}/>
            <BasicInformation firstText={`${(Number(data?.percentage?.administration)) || 0}%`}
                              icon={mdiAccountCog}
                              label={'Administración: '}/>
          </div>
        </div>
        <hr/>
        <h4>Precios y descuentos</h4>
        <div className={styles.discount}>
          <div>
            <h5>
              <Icon className={styles['icon-sum']} path={mdiCashMultiple} size={1}/>
              <span className={styles['space-betwen']}>
                <span>Precio por {project.isCrowdfunding ? 'Unidad' : 'Panel'}: </span><span>
                  {`${Utils.formatterEmpty.format(data?.panelCost)} COP` }
                </span>
              </span>
            </h5>
            <h5>
              <Icon className={styles['icon-sum']} path={mdiSale} size={1}/>
              <span className={styles['space-betwen']}>
                <span>Descuento en preventa: </span><span>
                  {parseFloat(infoDiscount.percentageDiscount || 0).toFixed(2)} %
                </span>
              </span>
            </h5>
          </div>
          <h5>
            <Icon className={styles['icon-sum']} path={mdiCash} size={1}/>
            <span className={styles['space-betwen']}>
              <span>Precio de preventa por {project.isCrowdfunding ? 'Unidad' : 'Panel'}: </span>
              <span>
                {Utils.formatterEmpty.format(
                  Math.ceil(parseFloat(infoDiscount?.discount || project.project.panelCost)))} COP
              </span>
            </span>
          </h5>
          <hr/>
        </div>
        <hr/>
        <div className={styles.grid} >
          <BasicInformation
            firstText={`${parseFloat(infoDiscount.percentageDiscount || 0).toFixed(2)}%`}
            label={'Descuentos preventa actual: '}
            icon={mdiAccountCowboyHat}/>

          <BasicInformation
            firstText={
              `${parseFloat((dataFormProject?.totalPanelParticipation * 100) || 0).toFixed(2)}%`
            }
            icon={mdiWrenchClock}
            label={'% de participación total de paneles: '}/>
          <BasicInformation
            firstText={
              `${parseFloat((dataFormProject?.discountCurrentAmount || 0)).toFixed(2)}%`}
            icon={mdiAccountCog}
            label={'Bonificación cantidad actual : '}/>

          <BasicInformation
            firstText={`${(dataFormProject?.tirFarmer
               ? parseFloat((dataFormProject?.tirFarmer * 100)).toFixed(2)
               : 0)}%`}
            icon={mdiAccountCog}
            label={'Rentabilidad anual estimada parcela: '}/>
        </div>
      </div>
    </>;
};

GeneralSection.propTypes = {
    project: PropTypes.object.isRequired,
};

export default GeneralSection;
