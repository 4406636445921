import React, { useEffect, useCallback } from 'react';
import Toolbar from '../mdc/toolbar/Toolbar';
import DrawerWrapper from '../mdc/drawer/DrawerWrapper';
import SiteBody from '../mdc/SiteBody';
import ProjectContext from '../../context/ProjectContext';
import Wallet from '../wallet/wallet';
import useAnalytics from '../../hooks/useAnalytics';

const WalletSite = () => {
  const { trackViewPage } = useAnalytics('wallet');
  const setEvent = useCallback(trackViewPage, []);
  useEffect(() => setEvent(), [setEvent]);
    return <>
      <Toolbar/>
      <DrawerWrapper/>
      <SiteBody>
        <ProjectContext>
          <Wallet/>
        </ProjectContext>
      </SiteBody>
    </>;
};

export default WalletSite;
