import React, { useEffect, useCallback } from 'react';
import Toolbar from '../mdc/toolbar/Toolbar';
import PaymentComponent from '../payment/Payment';
import DrawerWrapper from '../mdc/drawer/DrawerWrapper';
import ProjectContext from '../../context/ProjectContext';
import ProfileContext from '../../context/ProfileContext';
import useAnalytics from '../../hooks/useAnalytics';

const Payment = () => {
  const { trackViewPage } = useAnalytics('payment');
  const setEvent = useCallback(trackViewPage, []);
  useEffect(() => setEvent(), [setEvent]);
    return <>
      <Toolbar/>
      <DrawerWrapper/>
      <ProfileContext>
        <ProjectContext>
          <PaymentComponent/>
        </ProjectContext>
      </ProfileContext>
    </>;
};

export default Payment;
