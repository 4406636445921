/* eslint-disable react/prop-types */
import React from 'react';
import styles from './payment-response.module.css';
import { PDFDownloadLink } from '@react-pdf/renderer';
import SuscriptionTablePdf from '../payment-pdf/suscriptiontable';
import { Link } from 'react-router-dom';
import { Button } from '@rmwc/button';
import logo from '../../../res/images/logoDark.png';
import Icon from '@mdi/react';
import { mdiHome, mdiDownload } from '@mdi/js';
import {
  DataTable, DataTableCell, DataTableRow, DataTableBody
} from '@rmwc/data-table';

const RowData = ({ label, text }) => {
  return (
    <DataTableRow className={styles['table-row']}>
      <DataTableCell className={styles.text}>{label}</DataTableCell>
      <DataTableCell className={styles.text}>{text}</DataTableCell>
    </DataTableRow>
  );
};

const UIPaymentResponse = ({ data, user, project, pdfData }) => {
  return (
    <>
      <div className={styles.container}>
        <header>
          <div>
            <div>
              <img className={styles.img}
                   width={'100%'}
                   alt={'energy-logo'}
                   src={logo}/>
            </div>
          </div>
        </header>
        <div>
          <div>
            <div>
              <h4 style={{ color: '#006F97' }}> Respuesta de la Transacción </h4>
            </div>
            <hr/>
            <div className={styles['tables-container']}
                 style={
                  data.x_description === 'Recharge'
                  ? { display: 'flex', justifyContent: 'center' }
                  : {}
                 }>
              <div >
                <DataTable>
                  <DataTableRow className={styles['table-tittle']}>
                    <DataTableCell className={styles.text}>
                      Respuesta de la transacción
                    </DataTableCell>
                  </DataTableRow>
                  <DataTableBody className={styles['table-body']}>
                    <RowData label={'Referencia'} text={data.x_id_invoice} />
                    <RowData label={'Fecha'} text={data.x_transaction_date} />
                    <RowData label={'Respuesta'} text={data.x_response} />
                    <RowData
                    label={'Motivo'}
                    text={data.x_response_reason_text}
                  />
                    <RowData label={'Banco'} text={data.x_bank_name} />
                    <RowData label={'Recibo'} text={data.x_transaction_id} />
                    <RowData
                    label={'Total'}
                    text={`${data.x_amount} ${data.x_currency_code}`}
                  />
                  </DataTableBody>
                </DataTable>
              </div>
              <div
                style={
                  data.x_description !== 'Recharge'
                    ? { display: 'flex', justifyContent: 'center' }
                    : { display: 'none' }
                }
              >
                <DataTable>
                  <DataTableRow className={styles['table-tittle']}>
                    <DataTableCell className={styles.text}>
                      Comprobante de suscripción
                    </DataTableCell>
                  </DataTableRow>
                  <DataTableBody className={styles['table-body']}>
                    <RowData label={'Nombre del inversor:'} text={user.name} />
                    <RowData label={'c.c. /Nit:'} text={user.nit} />
                    <RowData label={'Nombre del proyecto:'} text={project.name} />
                    <RowData
                    label={'Ubicación del proyecto:'}
                    text={project.address}
                    />
                    <RowData
                    label={'Valor de la inversión:'}
                    text={`${data.x_amount} ${data.x_currency_code}`}
                    />
                    <RowData
                    label={'N° de paneles digitales comprados:'}
                    text={data.x_extra2}
                    />
                    <RowData label={'Interés Estimado E.A. :'} text={'10.3%'} />
                    <RowData
                    label={'Inicio de etapa de construcción:'}
                    text={project.date1}
                    />
                    <RowData
                    label={'Inicio de etapa de operación:'}
                    text={project.date2}
                    />
                    <RowData
                    label={'Porcentaje de la administración de FARM ENERGY'}
                    text={project.admin}
                    />
                    <RowData
                    label={'Estado de la transacción:'}
                    text={data.x_response}
                    />
                  </DataTableBody>
                </DataTable>
              </div>
            </div>
          </div>
          <div className={styles['button-container']}>
            <Link to={'/'} style={{ textDecoration: 'none' }}>
              <Button unelevated
                      className={styles.button}
                      style={{ marginTop: '0.5rem' }}>
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <Icon path={mdiHome} size={1}/>
                  volver al inicio
                </span>
              </Button>
            </Link>
            <PDFDownloadLink
                  document={
                    <SuscriptionTablePdf
                      data={pdfData}
                      recharge={data.x_description === 'Recharge'}
                      />
                  }
                  fileName={'suscripcion_comprobante'}
                  style={{ textDecoration: 'none' }}
                  >
              <Button
                    className={styles.button}
                    style={{ marginTop: '0.5rem' }}
                    unelevated
                  >
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <Icon path={mdiDownload} size={1} style={{ marginRigth: '0.5rem' }}/>
                  Descargar comprobante
                </span>
              </Button>
            </PDFDownloadLink>
          </div>
        </div>
        <footer>
          <div>
            <div>
              <div
                style={{
                  display: 'flex',
                  columnGap: '10rem',
                  justifyContent: 'center',
                  marginTop: '5rem',
                }}
              >
                <img
                  alt={'epayco-logo'}
                  src={
                    'https://369969691f476073508a-60bf0867add971908d4f26a64519c2aa.ssl.cf5.rackcdn.com/btns/epayco/pagos_procesados_por_epayco_260px.png'
                  }
                  style={{ width: '15%' }}
                />
                <img
                  alt={'credibanc-logo'}
                  src={
                    'https://369969691f476073508a-60bf0867add971908d4f26a64519c2aa.ssl.cf5.rackcdn.com/btns/epayco/credibancologo.png'
                  }
                  style={{ width: '10%' }}
                />
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default UIPaymentResponse;
