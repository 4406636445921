import React, { useContext } from 'react';
import { LandinContext } from '../../../context/LandinContext';
import QuestionDataForm from '../QuestionDataForm';

const Question = () => {
  const {
    questions = {}, QuestionDispatch,
  } = useContext(LandinContext);

  const setting = {
    endPoint: 'questions',
    label: 'pregunta frencuente',
    update: 'nueva pregunta frecuente',
  };

  return (
    <QuestionDataForm setting={setting} data={[questions, QuestionDispatch]}/>
  );
};

export default Question;
