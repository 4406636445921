import React from 'react';
import styles from './loading.module.css';

const LoadingEffect = () => {
    return <>
      <p className={styles.circle}>diaphragmatic animation</p>
    </>;
};

export default LoadingEffect;
