/* eslint-disable max-depth */
/* eslint-disable max-len */
import React, { createContext, useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { createLandinReducer } from '../reducer/LandinPageReducer';
import LandinPageAPI from '../api/LandinPageApi';

export const LandinContext = createContext();

const LandinContextProvider = ({ children }) => {
    const [questions, QuestionDispatch] = useReducer(createLandinReducer, []);
    const [aboutUs, AboutUsDispatch] = useReducer(createLandinReducer, []);
    // SNACK MESSAGE
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');

    const share = {
        questions,
        QuestionDispatch,
        aboutUs,
        AboutUsDispatch,
        open,
        setOpen,
        snackMessage,
        setSnackMessage,
    };

    useEffect(() => {
        LandinPageAPI.getQuestions('questions')
            .then(response => {
                QuestionDispatch({ type: 'GET_QUESTION', question: response });
            });
    }, []);

    useEffect(() => {
        LandinPageAPI.getQuestions('aboutUs')
            .then(response => {
                AboutUsDispatch({ type: 'GET_QUESTION', question: response });
            });
    }, []);

    return <LandinContext.Provider value={share}>{children}</LandinContext.Provider>;
};

LandinContextProvider.propTypes = {
    children: PropTypes.oneOfType(
        [PropTypes.object, PropTypes.arrayOf(PropTypes.element)]).isRequired,
};

export default LandinContextProvider;
