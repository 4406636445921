import React from 'react';
import DrawerWrapper from '../mdc/drawer/DrawerWrapper';
import SiteBody from '../mdc/SiteBody';
import Toolbar from '../mdc/toolbar/Toolbar';
import LandinPageHome from '../landinPage/LandinPage';
import LandinContext from '../../context/LandinContext';

export const LandinPage = () => {
  return (
    <>
      <Toolbar/>
      <DrawerWrapper/>
      <SiteBody>
        <LandinContext>
          <LandinPageHome/>
        </LandinContext>
        <br/>
      </SiteBody>
    </>
  );
};

export default LandinPage;
