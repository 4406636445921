const usePaymentData = () => {
  const initialBillState = {
    data: {
      x_id_invoice: '',
      x_transaction_date: '',
      x_response: '',
      x_response_reason_text: '',
      x_bank_name: '',
      x_transaction_id: '',
      x_amount: '',
      x_currency_code: '',
    },
  };

  const checkData = (obj) => {
    let allFieldsFilled = false;
    Object.keys(initialBillState.data).forEach((index, pos) => {
      if (obj[`${index}`] !== initialBillState.data[`${index}`]) allFieldsFilled = true;
    });
    return allFieldsFilled;
  };

  const initialProjectState = {
    name: '',
    adress: '',
    date1: '',
    date2: '',
  };

  const checkProject = (obj) => {
    let allFieldsFilled = false;
    Object.keys(initialProjectState).forEach((data, pos) => {
      if (obj[`${data}`] !== initialProjectState[`${data}`]) allFieldsFilled = true;
    });
    return allFieldsFilled;
  };

  return {
      initialBillState,
      checkData,
      initialProjectState,
      checkProject,
  };
};

export default usePaymentData;

