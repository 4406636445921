import React, { useEffect, useCallback } from 'react';
import Toolbar from '../mdc/toolbar/Toolbar';
import SiteBody from '../mdc/SiteBody';
import ProfileComponent from '../profile/Profile';
import DrawerWrapper from '../mdc/drawer/DrawerWrapper';
import ProfileContextData from '../../context/ProfileContext';
import useAnalytics from '../../hooks/useAnalytics';

const Profile = () => {
  const { trackViewPage } = useAnalytics('perfil');

  const setEvent = useCallback(trackViewPage, []);
  useEffect(() => setEvent(), [setEvent]);
    return <>
      <Toolbar/>
      <DrawerWrapper/>
      <SiteBody>
        <ProfileContextData>
          <ProfileComponent/>
        </ProfileContextData>
      </SiteBody>
    </>;
};

export default Profile;
