import React from 'react';
import Slider from 'react-slick';
import Icon from '@mdi/react';
import { mdiImageArea } from '@mdi/js';
import PropTypes from 'prop-types';
import styles from '../description.module.css';

const GallerySection = ({ project = {}, projectId }) => {
    const { auxPicture = [] } = project;
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      cssEase: 'linear',
    };

    return <>
      <h3>Galería</h3>
      <Slider {...settings} className={styles.gallery} >
        {
        (auxPicture.length === 0)
          ? <Icon path={mdiImageArea} color={'gray'}/>
          : auxPicture.map((pos = {}, i) => {
            return (<img alt={'slider'} key={i} src={(projectId && !Array.isArray(pos.file))
                                                    ? pos.file
                                                    : URL.createObjectURL(pos.file[0])}/>);
        })}
      </Slider>
    </>;
};

GallerySection.propTypes = {
    project: PropTypes.object.isRequired,
    projectId: PropTypes.string,
};

export default GallerySection;
