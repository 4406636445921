import {
  convertDataSimulator
} from '../components/projects/Project-Description/Description/Sections/converDataSimulator';
// eslint-disable-next-line
import calculateValuesGraphic from '../components/projects/Project-Description/Description/Sections/calculateValuesGrafic';

export default class Utils {
  static preventTextInNumberInput(e) {
    const key = e.keyCode || e.which || -1;
    if (key < 48 || key > 57) e.preventDefault();
  }

  static validateEmail(email) {
    try {
      return (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,7})+$/.test(email));
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
      return false;
    }
  }

  static formatter = new Intl.NumberFormat('es-CO', {
    style: 'currency',
    minimumFractionDigits: 0,
    currency: 'COP',
  })

  static formatterEmpty = new Intl.NumberFormat('es-CO', {});

  static getActualDate() {
    const date = new Date();
    const monthAux = (date.getMonth() + 1).toString();
    const month = `${date.getFullYear()}-${monthAux.length <= 1 ? `0${monthAux}` : monthAux}`;
    return { month, year: date.getFullYear().toString() };
  }

  static getTotalEarning(projectInfo, projects) {
    const { project, sharePercentage, projectId = {} } = projectInfo;
    const { percentage } = project;
    const [{ projectRegistries }] = projects.filter((project) => project.projectId === projectId);
    const totalEnergy = projectRegistries.map((registry) => {
      const {
        activeCost = [], activeSold = [], year,
        reactiveCost = [], reactiveSold = [], month,
      } = registry;
      const total = activeCost.map((item = 0, idx) => (
        (item * activeSold[idx]) + (reactiveCost[idx] * reactiveSold[idx])));
      return ({ month, year, total });
    });

    const total = totalEnergy.map((item) => {
      const { total: totalMonth, ...all } = item;
      const totalPerWeek = totalMonth.map((item) => (
        ((item) * (percentage.farmer / 100)) * (sharePercentage / 100)
      ));
      return { ...all, total: totalPerWeek };
    });
    return total;
  };

  static transformDataProject(project) {
    const dataProjectsUser = convertDataSimulator(project);
    const { info } = dataProjectsUser;
    const data = {
      cantidadPanelesParcela: 0,
      ...info,
    };
    return calculateValuesGraphic(data);
  };

  static generateChartData({ labels, chartData }) {
    const data = {
      labels,
      datasets: [
        {
          label: 'Ganancias',
          data: chartData,
          backgroundColor: '#42E2B8',
          borderColor: '#42E2B8',
          borderWidth: 1,
        },
      ],
    };
    return data;
  };

  static formatWeeks(weeks) {
    const weeksMonth = [];
    for (let i = 0; i <= weeks.length - 1; i++) {
      if (weeks[i + 1]) {
        weeksMonth[i] = [
          (new Date(weeks[i])).toLocaleDateString(),
          (new Date(weeks[i + 1])).toLocaleDateString(),
        ];
      }
    }
    return weeksMonth;
  }

  static getWeeks(period) {
    const arrayDate = period.split('-');
    const month = arrayDate[1] !== '12' ? Number(arrayDate[1]) + 1 : 1;
    const newPeriod = `${arrayDate[0]}-${month.toString().length === 1 ? `0${month}` : month}`;
    const newDate = new Date(newPeriod);
    const firstDay = new Date(newDate.getFullYear(), newDate.getMonth(), 1);
    const lastDay = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0);
    const lastDate = newDate.getDate();
    const registriesDay = [];

    for (let days = lastDate; days > 0; days -= 7) {
      const dateAux = new Date(newPeriod);
      dateAux.setDate(lastDate - days);
      registriesDay.push(dateAux);
    }

    registriesDay[0] = firstDay;
    registriesDay[registriesDay.length - 1] = lastDay;

    return this.formatWeeks(registriesDay);
  }

  static getRenuevePerProject(earnings) {
    const dataAux = [];
    if (earnings.length > 1) {
      for (let i = 0; i < earnings.length; i++) {
        const { total = [] } = earnings[i] || {};
        const { total: totalAux = [] } = earnings[i + 1] || {};
        if (totalAux.length >= 1) {
          for (let indice = 0; indice < total.length; indice++) {
            dataAux.push(total[indice] + (Number(totalAux[indice]) || 0));
          }
        }
      }
    } else {
      const { total = [] } = earnings[0] || {};
      dataAux.push(...total);
    }
    return dataAux;
  }

  static groupBy(data, param, { startDate, endDate }) {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const [filterData] = data.map((project) => {
      const { earnings } = project;
      return earnings.filter(({ month }) =>
        start.getTime() <= (new Date(month)).getTime()
        && (new Date(month)).getTime() <= end.getTime());
    });

    const groupedData = {};
    filterData.forEach((record) => {
      if (!Object.prototype.hasOwnProperty.call(groupedData, record[param])) {
        groupedData[record[param]] = [];
      }
      groupedData[record[param]].push({
        bonification: record.bonification,
        capitalSuscription: record.capitalSuscription,
        netIncome: record.netIncome,
        total: record.total.reduce((a, b) => a + b, 0),
      });
    });
    return groupedData;
  }

  static simulateCredit({ duration, annualRate, projectCost }) {
    const time = duration * 12;
    const tasaMensual = (annualRate / 100) / time;
    const tmp = Math.pow((1 + (tasaMensual)), (-time));
    const tmpd = 1 - tmp;
    const tmpn = projectCost * tasaMensual;
    const payment = tmpn / tmpd;
    const totalProjectCost = (payment * time).toFixed(2);

    return {
      payment,
      time,
      totalProjectCost,
    };
  }
}
