/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { TextField } from '@rmwc/textfield';
import { Button } from '@rmwc/button';
import { useParams, useHistory } from 'react-router-dom';
import DateCountdown from 'react-date-countdown-timer';
import useAnalytics from '../../../../hooks/useAnalytics';

import ProjectApi from '../../../../api/ProjectApi';
import { UserDataContext } from '../../../../context/UserContext';

import { ProjectContext } from '../../../../context/ProjectContext';

import styles from './progress.module.css';
import Utils from '../../../../utils/Utils';

const Progress = () => {
  const { projects = [], panels = 0, setPanels, dataFormProject, discount, setDiscount } = useContext(ProjectContext);
  const { projectId = '' } = useParams();
  const { trackEvent } = useAnalytics(projectId);

  const [myProjects = [], setMyProjects] = useState([]);
  const [error, setError] = useState('');

  const { user } = useContext(UserDataContext);

  const [auxProjectData = {}] = projects.filter((project) => project.projectId === projectId);
  const [myProjectsData = {}] = myProjects.filter((project) => project.projectId === projectId);

  const history = useHistory();

  const { project = {} } = auxProjectData;
  const maxPanels = Math.ceil(project?.noOfDigitalPanels * (project.UserPercentagePanels || 1));
  const availablePanels = project.noOfDigitalPanels - (project.numberOfPanels || 0);
  const discountValue = discount?.sort();

  useEffect(() => {
    if (!user) return;

    ProjectApi.getProjectsByUser(user.email).then((resp) => setMyProjects(resp));
  // eslint-disable-next-line
  }, []);

  const less = ((Number(dataFormProject.currentPresaleDiscount / 100))
      * (Number(project.panelCost)));
      const discountTotal = project.panelCost - less;

      const infoDiscount = {
        discount: discountTotal,
        percentageDiscount: (Number(dataFormProject?.currentPresaleDiscount)),
      };

  useMemo(() => {
    if ((projectId && auxProjectData?.projectId)
    || (!projectId)) {
      const infoValueDiscount = Object.values(project?.discount).sort() || [];
      if (infoValueDiscount.length > 0) {
        for (let i = 0; i < infoValueDiscount.length; i++) {
          if (infoValueDiscount[i] !== '0') {
            setDiscount([infoValueDiscount[i], infoValueDiscount[infoValueDiscount.length - 1]]);
            break;
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [project?.discount]);

  const validatePercentage = () => {
    const { sharePercentage = 0 } = myProjectsData;
    const percentAdd = (Number(panels) * 100) / project.noOfDigitalPanels;
    const maxPercent = Number(project.UserPercentagePanels || 1) * 100;

    if (sharePercentage + percentAdd < maxPercent) {
      trackEvent('click', 'buy');
      history.push(`/payment/${projectId}?panels=${panels}`);
    }
    setError(`No puedes comprar más de ${maxPercent}% del total de paneles`);
  };

  if ((projectId && auxProjectData?.projectId)
    || (!projectId)) {
  return <>
    <div className={styles['progress-container']}>
      <div className={styles.sticky}>
        <h5>Paneles disponibles: {availablePanels}/{project.noOfDigitalPanels}</h5>
        <hr/>
        <h4 className={styles['porcentual-discount']}>
          {
            dataFormProject?.tirFarmer
            ? (dataFormProject?.tirFarmer * 100).toFixed(2)
            : project.isCrowdfunding && project.annualRest * 100
          }%
        </h4>
        <p>Rentabilidad estimada anual</p>
        <hr/>
        {
          !project.isCrowdfunding
          && (<>
            <h4>Precio por panel en preventa</h4>
            <p className={styles['porcentual-discount_p']}>
              <span>-{parseFloat(infoDiscount.percentageDiscount) || 0}%</span>
              &nbsp;&nbsp;{Utils.formatterEmpty.format(Math.ceil(parseFloat(infoDiscount.discount)))}&nbsp;COP
            </p>
            <p className={styles['prevent-discount']}>Descuentos en preventa</p>
            <p className={styles['discount-range']}>
              Desde:  <span>{discountValue[0] || 0}%</span>&nbsp;
              Hasta:  <span>{discountValue[1] || 0}%</span></p>
            { Date.parse(new Date()) < Date.parse(new Date(project?.beginningDateHarvest))
              && <h4 className={styles['discount-date']}>Oferta valida hasta
                <span>
                  <DateCountdown
                    dateTo={new Date(project?.beginningDateHarvest).toISOString()}
                    locales={['Año', 'Mes', 'Día', 'h', 'm', 's']}
                    locales_plural={['Años', 'Mes', 'Días', 'h', 'm', 's']}
                  />
                </span>
              </h4>
            }
            <hr/>
          </>)
        }
        <div className={styles['panel-flex']}>
          <p>{project.isCrowdfunding ? 'Cantidad de unidades' : 'Número de paneles'}</p>
          <TextField
            disabled={availablePanels <= 0} type={'number'}
            className={styles['text-field']} outlined value={panels}
            onChange={e =>
              (e.target.value <= maxPanels)
              ? setPanels(parseInt(e.target.value) || 0)
              : panels
            } min={0}
            onKeyPress={Utils.preventTextInNumberInput}
          />
        </div>
        <p>Puedes comprar: Mínimo 1 - Máximo {(maxPanels || (project.totalCost - project.raisedMoney)).toFixed(0)}</p>
        <h4 className={styles['interactive-panel']}>
          {
            project.isCrowdfunding
            ? `${Utils.formatterEmpty.format(Math.round(parseFloat(project.panelCost * (panels || 1))))} COP`
            : `${Utils.formatterEmpty.format(Math.round(parseFloat(dataFormProject?.farmerInvestment || project.panelCost)))} COP`
          }
        </h4>
        {
          !user
            ? (<Button raised label={'Iniciar sesión'}
                       className={styles.button}
                       onClick={() => history.push('/')}/>)
            : (project.isCrowdfunding && project.raisedMoney <= project.totalCost
                ? (<Button raised label={'participar'}
                           className={styles.button}
                           onClick={() => validatePercentage()}/>)
                : (availablePanels > 0
                  && (myProjectsData.sharePercentage || 0) <= Number(project.UserPercentagePanels) * 100
                  && panels > 0)
                  && (<Button raised label={'participar'}
                              className={styles.button}
                              onClick={() => validatePercentage()}/>)
              )
        }
        { error && <p style={{ color: 'var(--mdc-theme-error)' }}>{error}</p> }
      </div>
    </div>
  </>;
  } else {
    return (<></>);
  }
};

export default Progress;
