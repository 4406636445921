import { projectStructure } from '../dto/project';

export const createProjectReducer = (state, action) => {
    switch (action.type) {
        case 'UPDATE_PROJECT': {
            const { project = {} } = state;
            let auxPicture = [];
            if (action.project.auxPicture) {
                const { auxPicture: pictures = [] } = project;

                const [imgData = {}] = action.project.auxPicture;

                const cleanPictures = pictures.filter(
                    (data = {}) => data.id !== imgData.id);

                if ((imgData.file || []).length) {
                    auxPicture = [...cleanPictures, ...action.project.auxPicture];
                } else {
                    auxPicture = [...cleanPictures, ...action.project.auxPicture]
                        .filter((picture) => imgData.id !== picture.id);
                }
                action.project.auxPicture = auxPicture;
            }
            if (action.project.projectData) {
                const { projectData = {} } = project;
                action.project.projectData = { ...projectData, ...action.project.projectData };
            }
            return { ...state, project: { ...state.project, ...action.project } };
        }
        case 'CLEAN_UPDATED_PROJECT': {
            return projectStructure;
        }
        default:
            return state;
    }
};

export const ProjectReducer = (state, action) => {
    switch (action.type) {
        case 'PROJECTS': {
            return action.projects;
        }
        case 'SELECTED_PROJECT': {
            return state;
        }
        default:
            return state;
    }
};
