import React from 'react';

import DrawerWrapper from '../mdc/drawer/DrawerWrapper';
import SiteBody from '../mdc/SiteBody';
import Toolbar from '../mdc/toolbar/Toolbar';

import ProjectContext from '../../context/ProjectContext';
import CrowdfundingComponent from '../Crowdfunding/CrowdfundingComponent';

const Crowdfunding = () => {
  return (<>
    <Toolbar />
    <DrawerWrapper />
    <SiteBody>
      <ProjectContext>
        <CrowdfundingComponent />
      </ProjectContext>
    </SiteBody>
  </>);
};

export default Crowdfunding;
