import React, { useContext } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import MaterialWrapper from './components/mdc/MaterialWrapper';
import Home from './components/sites/Home';
import SignUp from './components/account/signup/Signup';
import SignIn from './components/account/signin/Signin';
import NotFound from './components/sites/NotFound';
import ResetPass from './components/account/reset-password/ResetPass';
import Profile from './components/sites/Profile';
import Payment from './components/sites/Payment';
import PaymentResponse from './components/sites/Response';
import Description from './components/sites/Description';
import MyProjects from './components/sites/MyProjects';
import ManageProjects from './components/sites/ManageProjects';
import Projects from './components/projects/Project';
import General from './components/sites/General';
import WalletSite from './components/sites/Wallet';
import ContactSite from './components/sites/Contact';
import RechargeSite from './components/sites/recharge';
import WithDrawMoneySite from './components/sites/withDrawMoney';
import WalletResponseSite from './components/sites/WalletResponse';
import LandinPage from './components/sites/LandinPage';
import Records from './components/sites/Records';
import ManageCashout from './components/sites/ManageCashout';
import Crowdfunding from './components/sites/Crowdfunding';
import { UserDataContext } from './context/UserContext';

const App = () => {
  const { basicUserInformation, user } = useContext(UserDataContext);
  const { role } = basicUserInformation;
  const ADMIN_ROLE = 'admin';

  const adminRoutes = () => (<Switch>
    <Route exact path={'/manage-projects'} component={ManageProjects}/>
    <Route exact path={'/manage-projects/:projectId'} component={ManageProjects}/>
    <Route exact path={'/crowdfunding-projects'} component={Crowdfunding} />
    <Route exact path={'/crowdfunding-projects/:projectId'} component={Crowdfunding} />
    <Route exact path={'/landinPage'} component={LandinPage}/>
    <Route exact path={'/projects-records/:projectId'} component={Records}/>
    <Route exact path={'/cashout'} component={ManageCashout}/>
    <Route
      path={'/manage-projects'}
      render={({ match: { url } }) => (
        <>
          <Route path={`${url}/projects/:auth`} component={Projects} />
        </>
      )} />
    { renderDefaultRoutes() }
  </Switch>);

  const renderDefaultRoutes = () => (<Switch>
    <Route exact path={'/'} component={General} />
    <Route exact path={'/profile'} component={Profile} />
    <Route exact path={'/contact'} component={ContactSite} />
    <Route exact path={'/payment/:projectId'} component={Payment} />
    <Route exact path={'/response'} component={PaymentResponse} />
    <Route exact path={'/wallet'} component={WalletSite} />
    <Route exact path={'/recharge'} component={RechargeSite} />
    <Route exact path={'/withDraw'} component={WithDrawMoneySite} />
    <Route exact path={'/walletResponse'} component={WalletResponseSite} />
    <Route exact path={'/myProjects'} component={MyProjects} />
    <Route exact path={'/resetPass'} component={ResetPass} />
    <Route exact path={'/projects'} component={Home} />
    <Route exact path={'/description/:projectId'} component={Description} />
    <Route component={NotFound} />
  </Switch>);

  return <>
    <BrowserRouter>
      <MaterialWrapper>
        <Switch>
          {
            !user
            ? (<>
              <Route exact path={'/'} component={SignIn} />
              <Route exact path={'/signup'} component={SignUp} />
              <Route exact path={'/projects'} component={Home} />
              <Route exact path={'/description/:projectId'} component={Description} />
            </>)
            : (role === ADMIN_ROLE)
              ? (adminRoutes())
              : (renderDefaultRoutes())
          }
        </Switch>
      </MaterialWrapper>
    </BrowserRouter>
  </>;
};

export default App;
