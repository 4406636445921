import React from 'react';
import PropTypes from 'prop-types';
import {
    DataTable,
    DataTableRow,
    DataTableBody,
    DataTableCell,
    DataTableContent,
    DataTableHead,
    DataTableHeadCell
  } from '@rmwc/data-table';
import styles from './TableAdminValues.module.css';
import Utils from '../../../../../utils/Utils';

export const TableAdminValues = ({
        data, tableHeadCell = [], title,
        base, unit, isElement = [],
    }) => {
    return (
      <>
        <div className={styles.container_table}>
          <h4>
            {title}</h4>
          <DataTable>
            <DataTableContent>
              <DataTableHead>
                <DataTableRow>
                  {
                          tableHeadCell.map((e, id) => (
                            <DataTableHeadCell key={id}>{e}</DataTableHeadCell>
                          ))
                      }
                </DataTableRow>
              </DataTableHead>
              <DataTableBody>
                {
                     (tableHeadCell?.length > 0)
                     ? <>
                       {data.map((item, id) => (
                         <DataTableRow key={id}>
                           <DataTableCell>{base[id]}</DataTableCell>
                           <DataTableCell>
                             {`${Utils.formatterEmpty.format(item.farmers)} COP`}
                           </DataTableCell>
                           <DataTableCell>
                             {`${Utils.formatterEmpty.format(item.maintFund)} COP`}
                           </DataTableCell>
                           <DataTableCell>
                             {`${Utils.formatterEmpty.format(item.admin)} COP`}
                           </DataTableCell>
                           {tableHeadCell.length > 4
                           ? <DataTableCell>
                             {`${Utils.formatterEmpty.format(item.total)} COP`}
                           </DataTableCell>
                             : <></>
                         }
                         </DataTableRow>
                     ))}
                       {
                         Object.keys(isElement).length > 0
                           ? <DataTableRow >
                             <DataTableCell>{base[base.length - 1]}</DataTableCell>
                             <DataTableCell></DataTableCell>
                             <DataTableCell></DataTableCell>
                             <DataTableCell>
                               {`${Utils.formatter.format(isElement.total)} COP` }
                             </DataTableCell>
                           </DataTableRow>
                         : <></>
                     }
                     </>
                     : (data || []).map((item, id) => (
                       <DataTableRow key={id}>
                         <DataTableCell>{base[id]}</DataTableCell>
                         <DataTableCell>
                           {`${Utils.formatterEmpty.format(item)} ${unit}`}
                         </DataTableCell>
                       </DataTableRow>
                     ))
                   }
              </DataTableBody>
            </DataTableContent>
          </DataTable>
        </div>
      </>
      );
};

TableAdminValues.propTypes = {
    data: PropTypes.array,
    tableHeadCell: PropTypes.array,
    title: PropTypes.string,
    base: PropTypes.array,
    unit: PropTypes.string,
    isElement: PropTypes.object,
};
