/* eslint-disable max-len */
import React from 'react';

const CurrencyIcon = () => {
    return (
      <div style={{ width: '24px', height: '24px' }}>
        <svg viewBox={'0 0 24 24'}>
          <path
                fill={'currentColor'}
                d={'M7,15H9C9,16.08 10.37,17 12,17C13.63,17 15,16.08 15,15C15,13.9 13.96,13.5 11.76,12.97C9.64,12.44 7,11.78 7,9C7,7.21 8.47,5.69 10.5,5.18V3H13.5V5.18C15.53,5.69 17,7.21 17,9H15C15,7.92 13.63,7 12,7C10.37,7 9,7.92 9,9C9,10.1 10.04,10.5 12.24,11.03C14.36,11.56 17,12.22 17,15C17,16.79 15.53,18.31 13.5,18.82V21H10.5V18.82C8.47,18.31 7,16.79 7,15Z'} />
        </svg>
      </div>
    );
};

export default CurrencyIcon;

