import React, { useState, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { Button } from '@rmwc/button';
import { TextField } from '@rmwc/textfield';
import { Snackbar, SnackbarAction } from '@rmwc/snackbar';

import Utils from '../../utils/Utils';
import CrowdfundingApi from '../../api/CrowdfundingApi';

import { ProjectContext } from '../../context/ProjectContext';
import { UserDataContext } from '../../context/UserContext';

import LoadingEffect from '../loading/Loading';

import styles from './crowdfunding.module.css';
import { Checkbox } from '@rmwc/checkbox';

const CrowdfundingComponent = () => {
  const { projectId = '' } = useParams();
  const history = useHistory();
  const { user } = useContext(UserDataContext);
  const [projectName, setProjectName] = useState('');
  const [projectDescription, setProjectDescription] = useState('');
  const [beginningDate, setBeginningDate] = useState(new Date());
  const [percentageInversor, setPercentageInversor] = useState(0);
  const [percentagePlatform, setPercentagePlatform] = useState(0);
  const [raisedMoney, setRaiseMoney] = useState(0);
  const [interestAmount, setInterestAmount] = useState(0);
  const [contract, setContract] = useState(0);
  const [totalCost, setTotalCost] = useState('');
  const [panelCost, setPanelCost] = useState(0);
  const [noOfDigitalPanels, setNoOfDigitalPanels] = useState(100);
  const [snackMessage, setSnackMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [customMinAmount, setCustomMinAmount] = useState(false);
  const { projects = [] } = useContext(ProjectContext);

  useEffect(() => {
    if (!projectId || projects.length <= 0) return;

    const [auxProjectData = {}] = projects.filter((project) => project.projectId === projectId);
    if (Object.keys(auxProjectData).length < 0) history.push('/');
    const { project } = auxProjectData;
    setProjectName(project.projectName);
    setProjectDescription(project.text);
    setBeginningDate(project.beginningDate);
    setPercentageInversor(project.percentage.farmer);
    setPercentagePlatform(project.percentage.administration);
    setRaiseMoney(project.raisedMoney);
    setInterestAmount(project.annualRest * 100);
    setContract(project.contract);
    setTotalCost(project.totalCost);
    setPanelCost(project.panelCost);
    setNoOfDigitalPanels(project.noOfDigitalPanels);
      // eslint-disable-next-line
  }, [!projects.length]);

  useEffect(() => {
    if (customMinAmount) return setNoOfDigitalPanels((totalCost / panelCost).toFixed(2));

    return setPanelCost((totalCost / noOfDigitalPanels).toFixed(2));
    // eslint-disable-next-line
  }, [noOfDigitalPanels, panelCost]);

  const saveProject = async () => {
    if (!projectName || !projectDescription
      || !beginningDate || !percentageInversor
      || !percentagePlatform || !interestAmount
      || !contract || !totalCost
      || !panelCost || !noOfDigitalPanels) return;

    setLoading(true);
    await CrowdfundingApi.saveCrowdfundingProject({
      projectName,
      text: projectDescription,
      beginningDate,
      percentage: {
        administration: percentagePlatform,
        farmer: percentageInversor,
      },
      raisedMoney,
      annualRest: interestAmount / 100,
      contract,
      totalCost,
      panelCost,
      noOfDigitalPanels,
      user: user.email,
    }, projectId)
    .then(() => {
      setLoading(false);
      setSnackMessage('Proyecto guardado exitosamente');
      window.location.replace('/');
    })
    .catch(() => {
      setLoading(false);
      setSnackMessage('No pudimos guardar el proyecto');
      setOpen(true);
    });
  };

  return (<>
    {
      loading
      ? <LoadingEffect />
      : (<div className={styles.container_crowd}>
        <h1>Crear/Editar proyecto de financiación</h1>
        <h4>Informacion general del proyecto</h4>
        <div className={styles['container_crowd-content']}>
          <div className={styles['container_crowd-content_columns']}>
            <TextField label={'Nombre del proyecto'} value={projectName}
                       onChange={(e) => setProjectName(e.target.value)} />
            <TextField label={'Descripcion'} textarea value={projectDescription}
                       onChange={(e) => setProjectDescription(e.target.value)} />
            <TextField label={'Costo del proyecto'}
                       value={totalCost} type={'number'} min={0}
                       onChange={(e) => setTotalCost(e.target.value)}
                       onKeyPress={Utils.preventTextInNumberInput} />
            <TextField label={'Dinero recaudado'}
                       value={raisedMoney} type={'number'} min={0}
                       onChange={(e) => setRaiseMoney(e.target.value)}
                       onKeyPress={Utils.preventTextInNumberInput} />
            <TextField label={'Tasa de interés anual (%)'}
                       value={interestAmount} type={'number'} min={0}
                       onChange={(e) => setInterestAmount(e.target.value)}
                       onKeyPress={Utils.preventTextInNumberInput} />
            <TextField label={'Duración del proyecto'}
                       value={contract} type={'number'} min={0}
                       onChange={(e) => setContract(e.target.value)}
                       onKeyPress={Utils.preventTextInNumberInput} />
          </div>
          <div className={styles['container_crowd-content_columns']}>
            <TextField label={'Fecha de inicio del proyecto'} type={'date'}
                       value={beginningDate} onChange={(e) => setBeginningDate(e.target.value)}/>
            <TextField label={'Porcentaje de inversionistas'} type={'number'}
                       min={0} value={percentageInversor}
                       onKeyPress={Utils.preventTextInNumberInput}
                       onChange={(e) => setPercentageInversor(e.target.value)}/>
            <TextField label={'Porcentaje de plataforma'} type={'number'}
                       min={0} value={percentagePlatform}
                       onKeyPress={Utils.preventTextInNumberInput}
                       onChange={(e) => setPercentagePlatform(e.target.value)} />
            <TextField label={'Cantidad de unidades'}
                       value={noOfDigitalPanels} type={'number'} min={0}
                       disabled={customMinAmount} step={'0.01'}
                       onChange={(e) => setNoOfDigitalPanels(e.target.value)}
                       onKeyPress={Utils.preventTextInNumberInput} />
            <TextField label={'Cantidad de minima de inversión'}
                       value={panelCost} type={'number'} min={0}
                       disabled={!customMinAmount} step={'0.01'}
                       onChange={(e) => setPanelCost(e.target.value)}
                       onKeyPress={Utils.preventTextInNumberInput} />
            <Checkbox label={'Cantidad minima de invesion personalizada'} checked={customMinAmount}
                      onChange={(evt) => setCustomMinAmount(!!evt.currentTarget.checked)}
    />
          </div>
        </div>
        <Button label={'Guardar'} onClick={() => saveProject()} />
      </div>)
    }
    <Snackbar
      open={open}
      onClose={() => setOpen(false)}
      message={snackMessage}
      dismissesOnAction
      action={ <SnackbarAction label={'Dismiss'}/> }/>
  </>);
};

export default CrowdfundingComponent;
