import React from 'react';
import Toolbar from '../mdc/toolbar/Toolbar';
import SiteBody from '../mdc/SiteBody';
import DrawerWrapper from '../mdc/drawer/DrawerWrapper';
import ReadingsProject from '../../components/projects/readings/ReadingsProject';
import ProjectContext from '../../context/ProjectContext';

const Readings = () => {
  return (<>
    <Toolbar/>
    <DrawerWrapper/>
    <SiteBody>
      <ProjectContext>
        <ReadingsProject/>
      </ProjectContext>
    </SiteBody>
  </>);
};

export default Readings;
