import React from 'react';
import Icon from '@mdi/react';
import PropTypes from 'prop-types';
import styles from './qr.module.css';
import { Dialog } from '@rmwc/dialog';
import { IconButton } from '@rmwc/icon-button';
import { mdiCloseThick } from '@mdi/js';
import TwoFAuthentication from '../2FAuthentication/TwoFAuthentication';

export const Qr = ({ tfaProvider, text, open, setOpen, validate, setValidate }) => {
    return (
      <Dialog
        open={open}
        onClosed={() => {
            validate ? setOpen(false) : setOpen(true);
        }}
        onClose={() => setOpen(false)}
      >
        <div>
          <IconButton
            className={styles.dialog_exit}
            onClick={() => {
              setValidate(true);
              setOpen(false);
             }}
            >
            <Icon path={mdiCloseThick}></Icon>
          </IconButton>
        </div>
        <div className={styles.dialog_QR}>
          <p>{text}</p>
        </div>
        <TwoFAuthentication isValid={(event) => {
          const { verificationStatus } = event;
          setOpen(false);
          setValidate(verificationStatus);
        }} provider={tfaProvider} />
      </Dialog>
    );
};

Qr.propTypes = {
    text: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    tfaProvider: PropTypes.string.isRequired,
    setOpen: PropTypes.func.isRequired,
    validate: PropTypes.bool.isRequired,
    setValidate: PropTypes.func.isRequired,
};
