import React, { useState, useContext } from 'react';
import DescriptionSection from '../../Project-Description/Description/Sections/DescriptionSection';
import GallerySection from '../../Project-Description/Description/Sections/GallerySection';
import GeneralSection from '../../Project-Description/Description/Sections/GeneralSection';
import Graphic from '../../Project-Description/Description/Sections/Graphic';
import Location from '../../Project-Description/Description/Sections/Location';
import ProjectProgress from '../../Project-Description/Description/Sections/ProjectProgress';
import { ProjectContext } from '../../../../context/ProjectContext';
import { Button } from '@rmwc/button';
import { useParams } from 'react-router-dom';
import styles from './project-tab.module.css';
import Geocode from 'react-geocode';

Geocode.setApiKey('AIzaSyA3k3Op_D-g_qzrA0tSGzTlU2_sSRG2Rcw');
Geocode.enableDebug();

// eslint-disable-next-line react/prop-types
const SectionTab = ({ section, projectData = {}, summary = false }) => {
  const { projects = [], createProjectDispatch } = useContext(ProjectContext);
  const { projectId = '' } = useParams();
  const [auxProjectData = {}] = projects.filter((project) => project.projectId === projectId);

  if ((projectId && auxProjectData?.projectId)
    || (!projectId && Object.keys(projectData).length > 0)) {
      const data = (projectId && !projectData?.projectData?.address) ? auxProjectData : projectData;

  switch (section) {
    case 0:
      return <DescriptionSection project={data} projectId={projectId}/>;
    case 1:
      return (
        <div className={styles['gallery-container']}>
          <GallerySection project={data} projectId={projectId}/>
        </div>
      );
    case 2:
      return <GeneralSection project={data} projectId={projectId}/>;
    case 3:
      return <Graphic project={auxProjectData} bg={false} projectId={projectId}
                      nextTittle={false}/>;
    case 4:
      return <Location project={data} dispatch={createProjectDispatch}
                       projectId={projectId} summary={summary}/>;
    case 5:
      return <ProjectProgress project={data} projectId={projectId}/>;
    default:
      return <></>;
  }
  } else {
    return <></>;
  }
};

// eslint-disable-next-line react/prop-types
const ProjectTab = ({ projectData = {}, summary = false }) => {
  const [section, setSection] = useState(0);
  return (
    <div className={styles['preview-container']}>
      <h2>Previsualización</h2>
      <hr/>
      <div className={styles['button-container']}>
        <Button className={styles.button} label={'Descripción'} onClick={() => setSection(0)}
                unelevated/>
        <Button className={styles.button} label={'Galería'} onClick={() => setSection(1)}
                unelevated/>
        <Button className={styles.button} label={'General'} onClick={() => setSection(2)}
                unelevated/>
        <Button className={styles.button} label={'Simulador'} onClick={() => setSection(3)}
                unelevated/>
        <Button className={styles.button} label={'Localización'} onClick={() => setSection(4)}
                unelevated/>
        <Button className={styles.button} label={'Progreso'} onClick={() => setSection(5)}
                unelevated/>
      </div>
      <div className={styles.preview}>
        <SectionTab section={section} projectData={projectData} summary={summary}/>
      </div>
    </div>
  );
};
export default ProjectTab;
