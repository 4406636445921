import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import Geocode from 'react-geocode';
import { ProjectContext } from '../../../../context/ProjectContext';
import styles from './description.module.css';
import { useParams } from 'react-router-dom';

import DescriptionSection from './Sections/DescriptionSection';
import GallerySection from './Sections/GallerySection';
import GeneralSection from './Sections/GeneralSection';
import ProjectProgress from './Sections/ProjectProgress';
import Location from './Sections/Location';
import Graphic from './Sections/Graphic';
import Progress from '../Progress/Progress-content/Progress-content';
import CrowdfundingSimulator from '../../../CrowdfundingSimulator/CrowdfundingSimulator';

Geocode.setApiKey('AIzaSyA3k3Op_D-g_qzrA0tSGzTlU2_sSRG2Rcw');
Geocode.enableDebug();

// eslint-disable-next-line react/prop-types
const Description = ({ projectData = {}, summary = false }) => {
  const { projects = [], createProjectDispatch, dataFormProject } = useContext(ProjectContext);
  const { projectId = '' } = useParams();
  const [auxProjectData = {}] = projects.filter((project) => project.projectId === projectId);
  if ((projectId && auxProjectData?.projectId)
    || (!projectId && Object.keys(projectData).length > 0)) {
      const data = (projectId && !projectData?.projectData?.address) ? auxProjectData : projectData;

      const less = ((Number(dataFormProject.currentPresaleDiscount / 100))
      * (Number(data.project.panelCost)));
      const discount = data.project.panelCost - less;

      const infoDiscount = {
        discount,
        percentageDiscount: (Number(dataFormProject?.currentPresaleDiscount)),
      };

      return <>
        <div className={styles['description-container']}>
          <div className={styles.sections}>
            <>
              <DescriptionSection project={data} projectId={projectId}/>
              <div className={styles['progress-card']}>
                <Progress dataFormProject={dataFormProject} infoDiscount={infoDiscount}/>
              </div>
              <GeneralSection
                project={data}
                projectId={projectId}
                dataFormProject={dataFormProject}
                infoDiscount={infoDiscount}
                />
              {
                data.isCrowdfunding
                ? <CrowdfundingSimulator project={data} />
                : <Graphic project={data} bg={true}/>
              }
              <ProjectProgress project={data} projectId={projectId}/>
              {
                !data.project.isCrowdfunding
                && <Location project={data} dispatch={createProjectDispatch}
                             projectId={projectId} summary={summary}/>
              }
              {
                !data.project.isCrowdfunding
                && <GallerySection project={data} projectId={projectId}/>
              }
            </>
          </div>
        </div>
      </>;
  } else {
    return <></>;
  }
};

Description.propTypes = {
  projectData: PropTypes.object,
  summary: PropTypes.bool,
};

export default Description;
