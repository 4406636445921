import { api, escalateError, getResponseData } from './index';

export default class CrowdfundingApi {
  static saveCrowdfundingProject(body, projectId) {
    const url = `/crowdfunding${projectId ? `/${projectId}` : '/'}`;
    return api.post(url, { projectData: body })
        .then(getResponseData)
        .catch(escalateError);
  }

  static deleteCrowdfundingProjectByProjectId(projectId) {
    return api.delete(`/crowdfunding/${projectId}`)
            .then(getResponseData)
            .catch(escalateError);
  }
}
