import React, { useEffect, useCallback } from 'react';
import Description from './Description/Description';
import Progress from './Progress/Progress';
import ProjectSlice from './projectSlice/ProjectSlice';

import styles from './project-description.module.css';
import useAnalytics from '../../../hooks/useAnalytics';

const ProjectDescription = () => {
  const { trackViewPage } = useAnalytics('description');
  const setEvent = useCallback(trackViewPage, []);
  useEffect(() => setEvent(), [setEvent]);
    return <>
      <div className={styles['project-description']}>
        <div className={styles.top__content}>
          <Description summary={true}/>
          <div className={styles['progress-content']}>
            <Progress/>
          </div>
        </div>
        <ProjectSlice/>
      </div>
    </>;
};

export default ProjectDescription;
