import axios from 'axios';
import { escalateError, getResponseData } from './index';

const apiURL = 'https://secure.epayco.co/validation/v1/reference';

export default class ProjectApi {
    static getPaymentResponseData(reference) {
        return axios.get(`${apiURL}/${reference}`,
            { headers: { 'Content-Type': 'multipart/form-data' } })
                .then(getResponseData)
                .catch(escalateError);
    }
}
