/* eslint-disable react/prop-types */
import React, { useContext, useState, useEffect, useCallback } from 'react';
import styles from './withDrawMoney.module.css';
import { useHistory } from 'react-router-dom';
import { TextField } from '@rmwc/textfield';
import { Button } from '@rmwc/button';
import LastRecharge from '../LastRecharge/LastRecharge';
import { Radio } from '@rmwc/radio';
import { UserDataContext } from '../../context/UserContext';
import Utils from '../../utils/Utils';
import useAnalytics from '../../hooks/useAnalytics';
import CashoutApi from '../../api/CashoutApi';
import TwoFAuthentication from '../2FAuthentication/TwoFAuthentication';

const HowMuchSection = ({ step, setStep, user, setValue, value, where, setWhere }) => {
  const { wallet = {}, minValueCashout = 1000 } = user || {};
  const { trackEvent } = useAnalytics('withDraw');
  const balance = parseInt(wallet.amount) + parseInt(wallet.earnings);

  const getMessage = () => {
    const clearValue = value.replace('$', '')
      .split('.')
      .join('');
    if (parseInt(clearValue) === 0
      || parseInt(clearValue) < parseInt(minValueCashout)) return 'El monto mínimo es de $100.000';
    if (parseInt(clearValue) > balance) return 'No tienes suficiente saldo';
    if (where === '') return 'Selecciona una opción para enviar el dinero';
    return '';
  };

  const isDisabled = () => {
    const clearValue = parseInt(value.replace('$', '')
    .split('.')
    .join(''));

    return (clearValue === 0 || clearValue > balance
      || clearValue < minValueCashout || where === '');
  };

  return (
    <div className={styles['how-much-container']}>
      <h1 className={styles['recharge-tittle']}> Retirar </h1>
      <hr/>
      <div className={styles['recharge-quantity']}>
        <h3>Saldo disponible</h3>
        <h4 style={{ margin: '0', fontWeight: 'bold' }}>{Utils.formatter.format(balance)}</h4>
        <h3>¿Cúanto quieres retirar?</h3>
        <TextField className={styles['input-quantity']}
                   type={'text'}
                   min={'0'}
                   onKeyPress={Utils.preventTextInNumberInput}
                   onChange={(e) => {
                    const clearFormat = e.target.value
                      .replace('$', '')
                      .split('.')
                      .join('');
                    setValue(Utils.formatter.format(clearFormat));
                  }}
                   value={value}
                   outlined/>
        <div className={styles['where-container']}>
          <div className={styles['where-radios-form']}>
            <h5 style={{ margin: '1em' }}>¿A donde quieres enviar tu dinero?</h5>
            <div className={styles['radios-form']}>
              <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                <Radio className={styles.radios} name={'where'}
                       onChange={() => {
                        trackEvent('check', 'nequi');
                        setWhere('nequi');
                      }}>Nequi</Radio>
                <Radio className={styles.radios} name={'where'}
                       onChange={() => {
                        trackEvent('check', 'daviplata');
                        setWhere('daviplata');
                       }}>Daviplata</Radio>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.container__tooltip}>
          <p style={{ color: 'var(--mdc-theme-error)' }}>{getMessage()}</p>
          <Button label={'Continuar'}
                  disabled={isDisabled()}
                  onClick={() => {
                    trackEvent('click', 'continue');
                    setStep(1);
                  }}
                  type={'submit'}
                  className={styles['button-quantity']}
                  unelevated />
        </div>
        <hr className={styles['mobile-hr']}/>
      </div>
      <div className={styles['last-recharge-container']}>
        <LastRecharge isCashout />
      </div>
    </div>
  );
};

const ConfirmData = ({ setStep, user: userInfo, value, where = '' }) => {
  const { wallet = {}, user = {}, email } = userInfo || {};
  const { phoneNumber = '', phone } = user || {};
  const [cellPhone, setCellPhone] = useState(phoneNumber || phone);
  const transactionCost = 0;
  const { trackEvent } = useAnalytics('withDraw');
  const clearValue = value.replace('$', '')
    .split('.')
    .join('');
  const transaccionTotal = parseInt(clearValue) + transactionCost;
  const history = useHistory();

  const sendCashout = () => {
    const cashoutDetails = {
      total: transaccionTotal,
      projectName: 'Cashout',
      projectId: 'personal',
      description: 'Retiro de dinero',
      email: user.email,
      transactionType: '10',
    };

    const body = {
      cashoutDetails,
      amount: transaccionTotal,
      balance: parseInt(wallet.amount) + parseInt(wallet.earnings),
      bank: where,
      phoneNumber: cellPhone,
      transactionCost,
      email,
      user: user.name,
    };

    Promise.all([CashoutApi.sendCashout(body)])
      .then(() => {
        trackEvent('click', 'success cashout');
        history.push('/');
      })
      .catch(() => {
        trackEvent('error', 'failed cashout');
        setStep(0);
      });
  };

  return (
    <div className={styles['confirm-container']}>
      <h1 className={styles['recharge-tittle']}>Retirar saldo</h1>
      <hr/>
      <div className={styles['confirm-form']}>
        <h4>Confirmación</h4>
        <div className={styles['confirm-sum']}>
          <h5>Saldo disponible
            <span>{Utils.formatter.format(parseInt(wallet.amount) - wallet.earnings)}</span>
          </h5>
          <h5>Monto a retirar <span>{value}</span></h5>
          <h5>Costo de la transaccion
            <span>{Utils.formatter.format(transactionCost)}</span>
          </h5>
          <hr/>
          <h5>Total <span>{Utils.formatter.format(transaccionTotal)}</span></h5>
        </div>
        <div className={styles['confirm-sum']}>
          <h5>Banco: <span>{where.toUpperCase()}</span></h5>
          <TextField value={cellPhone} outlined type={'number'}
                     onChange={(e) => setCellPhone(e.target.value)}
                     onKeyPress={Utils.preventTextInNumberInput}/>
        </div>

        <div className={styles['buttons-confirm']}>
          <Button label={'Atrás'}
                  onClick={() => {
                    trackEvent('click', 'back');
                    setStep(0);
                  }}
                  className={styles['button-where']}
                  dense />
          <Button label={'Confirmar'}
                  onClick={() => sendCashout()}
                  dense />
        </div>
      </div>
    </div>
  );
};

const Sections = (props) => {
  const { step, setStep, user = {} } = props;
  const { tfaProvider } = user;
  const { trackEvent } = useAnalytics('withDraw');
  const handleTwoFAuth = (event) => {
    const { comeBack = false, verificationStatus } = event;
    const message = verificationStatus ? 'success' : 'failed';
    if (!comeBack) trackEvent('2fa', message);
    else trackEvent('2fa', 'back');
    setStep(comeBack
      ? 0
      : verificationStatus
        ? 2
        : 0);
  };

  switch (step) {
    case 0:
      return (<HowMuchSection { ...props }/>);
    case 1:
      return (<TwoFAuthentication provider={tfaProvider} isValid={(e) => handleTwoFAuth(e)}/>);
    case 2:
      return (<ConfirmData { ...props }/>);
    default:
      return (<></>);
  }
};

const WithDrawMoney = () => {
  const [step, setStep] = useState(0);
  const [value, setValue] = useState('0');
  const [where, setWhere] = useState('');
  const [userInfo, setUserInfo] = useState({});
  const { trackViewPage } = useAnalytics('withDraw');
  const { user = {}, basicUserInformation = {} } = useContext(UserDataContext);

  const setEvent = useCallback(trackViewPage, []);

  useEffect(() => {
    setEvent();
    setUserInfo({ ...user, ...basicUserInformation });
    // eslint-disable-next-line
  }, [basicUserInformation, setEvent]);

  if (Object.entries(basicUserInformation).length === 0) return <></>;
  return (
    <>
      <div className={styles['recharge-container']}>
        <Sections user={userInfo}
                  step={step}
                  value={value}
                  setValue={setValue}
                  setStep={setStep}
                  setWhere={setWhere}
                  where={where}/>
      </div>
    </>
  );
};

export default WithDrawMoney;
