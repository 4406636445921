import React, { useState } from 'react';
import { Button } from '@rmwc/button';
import { Tooltip } from '@rmwc/tooltip';
import PropTypes from 'prop-types';

const GoogleAuth = ({ qr, secret, isVerifiedAccount }) => {
  const [scanQr, setScanQr] = useState(true);
  const [message, setMessage] = useState('Copiar texto');

  return (<>
    <h2>Configura Google Authenticator</h2>
    <ol>
      {
        !isVerifiedAccount
          ? (<>
            <li>Descarga la aplicacion de Google Authenticator</li>
            <li>En la app selecciona el boton +</li>
            <li>{scanQr ? 'Escanea el código QR' : 'Ingresar clave de config.'}</li>
            <li>Ingresa el código generado por la app</li>
            {!scanQr && (<li>Copia el siguiente texto:</li>) }
          </>)
        : (<>
          <li>Ingresa a la aplicación de Google Authenticator</li>
          <li>Ingresa el código generado por la app</li>
        </>)
      }

    </ol>
    {!scanQr
      && (<Tooltip
        content={message}
        showArrow leaveDelay={1000}>
        <p
           onClick={() => {
            navigator.clipboard.writeText(secret);
            setMessage('¡Texto copiado!');
          }}>{secret}</p>
      </Tooltip>)}
    { (!qr && scanQr && !isVerifiedAccount)
      ? <p>Cargando QR</p>
      : qr && scanQr && (<object data={qr}>
        <embed src={qr}></embed>
      </object>)
    }
    {
      !isVerifiedAccount && (
        <Button label={'¿No puedes escanear el codigo?'}
                onClick={() => setScanQr(!scanQr)} />
      )
    }
  </>);
};

GoogleAuth.propTypes = {
  qr: PropTypes.string,
  secret: PropTypes.func,
  isVerifiedAccount: PropTypes.bool,
};

export default GoogleAuth;
