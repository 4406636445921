/* eslint-disable no-console */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MDIcon from '../mdc/MDIcon';
import Icon from '@mdi/react';

import { mdiPlusCircle, mdiCloseCircle } from '@mdi/js';

import './file-input.css';

const screenWidth = 1280;
const screenHeight = 720;

const FileInput = ({
    label, name,
    id, img,
    clean, onFilesChosen,
    customIcon, validate,
    context,
}) => {
    const [selectedFile, setSelectedFile] = useState('');
    const [clearSelectedFile, setClearSelectedFile] = useState(false);

    const handleClick = () => {
      const data = [];
      if (context) {
        const { documents = {} } = context;
        Object.keys(documents)
          .forEach((key) => {
              if (key === name) data.push(documents[key][0]);
            }
          );
      }
      if (selectedFile || data[0]) {
      const left = (window.screen.width - screenWidth) / 2;
      const top = (window.screen.height - screenHeight) / 4;
      const objectUrl = URL.createObjectURL(selectedFile || data[0]);
      const popUpObj = window.open(objectUrl, 'document',
       `width=${screenWidth},height=${screenHeight}`
      + `,menubar=yes,resizable=yes,scrollbars=no,status=no,location=no,top=${top},left=${left}`);
      popUpObj.focus();
      URL.revokeObjectURL(objectUrl);
     } else {
       if (context) {
        const { setSnackMessage, setOpen } = context;
        setSnackMessage(`El documento ${label} aun no sido subido`);
        setOpen(true);
       }
     }
    };

    const filesToArray = (files) => {
        if (!files) return [];
        const array = [];
        for (let i = 0; i < files.length; i += 1) {
          array.push(files.item(i));
        }
        return array;
      };

    const onDragOver = (e) => e.preventDefault();

    const onFilesAdded = (event) => {
      event.preventDefault();
      const files = filesToArray(event.dataTransfer
        ? event.dataTransfer.files
        : event.target.files);
      try {
        if (onFilesChosen) {
          setSelectedFile(files[0]);
          onFilesChosen(files);
        }
      } catch (e) {
      }
    };

    const customColor = ((customIcon) ? '' : { color: 'white' });
    const customBGC = validate ? { backgroundColor: '#006F97' } : { backgroundColor: '#d96465' };

    if (clean && selectedFile) {
      setClearSelectedFile(false);
      onFilesChosen([]);
      setSelectedFile('');
      document.getElementById(id).value = null;
    }

    return <>
      <div onDragOver={onDragOver} onDrop={onFilesAdded}>
        <input type={'file'} name={name} id={id} className={'file__input'}
               onChange={onFilesAdded} onClick={(event) => {
                if (selectedFile || (!selectedFile && clearSelectedFile)) {
                  event.preventDefault();
                  setClearSelectedFile(false);
                  onFilesChosen([]);
                  setSelectedFile('');
                  document.getElementById(id).value = null;
                }
                 }}/>

        <div style={{ display: 'flex', gridTemplateColumns: 'repeat(2,1fr)', columnGap: '1rem' }}>
          <label htmlFor={ !selectedFile ? id : ''}
                 style={{ width: '100%', ...customColor, ...customBGC }}
                 onClick={(e) => {
                    if (selectedFile || img) {
                      setClearSelectedFile(true);
                      setSelectedFile('');
                    }
                 }}>
            {(customIcon)
            ? <Icon id={id} htmlFor={id} path={(selectedFile || img)
              ? mdiCloseCircle
              : mdiPlusCircle} color={selectedFile || img ? 'red' : 'green'} size={'1.5rem'} />
            : label}
          </label>
          {(!customIcon)
            ? <div className={'file__input__icon'} onClick={handleClick}>
              <MDIcon icon={'eye-outline'}/>
            </div>
          : <></>}
        </div>
      </div>
    </>;
};

FileInput.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.string,
    clean: PropTypes.object,
    onFilesChosen: PropTypes.func,
    customIcon: PropTypes.bool,
    img: PropTypes.string,
    validate: PropTypes.array,
    context: PropTypes.object,
};

export default FileInput;
