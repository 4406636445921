/* eslint-disable max-len, max-lines */
import React from 'react';

const PaymentContract = () => {
    return <>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}>Con
        la suscripción del presente documento, se parametrizan las
        condiciones generales del Contrato de Colaboración que regularán la
        relación civil y/o comercial entre los Inversores que han
        manifestado su aceptación al mismo por medio de la Plataforma Farm
        Energy S.A.S. E.S.P. (que en adelante se denominará la
        “Plataforma”)</font><font color={'#202124'}><font face={'Kanit, serif'}><span style={{ background: '#ffffff' }}>,
          para la explotación comercial de un proyecto de energía renovable
          de fuente no convencional, que en principio se desarrollará bajo la
          modalidad de energía solar fotovoltaica, pero sin perjuicio que se
          pueda acudir a cualquier otra modalidad de obtención de energía de
          carácter renovable y no convencional. </span></font></font>
      </p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font color={'#202124'}><font face={'Kanit, serif'}><span style={{ background: '#ffffff' }}>Con
        la aceptación de los términos y condiciones del presente acuerdo,
        el cual se materializa pulsando el botón </span></font></font><font color={'#202124'}><font face={'Kanit, serif'}><span style={{ background: '#ffffff' }}>“Acepto”</span></font></font><font color={'#202124'}><font face={'Kanit, serif'}><span style={{ background: '#ffffff' }}>,
          y aportando mediante transferencia la suma determinada en la
          Plataforma por el Inversor, las Partes aceptan expresamente regirse
          por los términos del presente documento, así como de todos sus
          anexos.</span></font></font></p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font color={'#202124'}><font face={'Kanit, serif'}><span style={{ background: '#ffffff' }}>El
        presente Contrato de Colaboración, el cual entra en vigencia desde
        la fecha efectiva de su suscripción </span></font></font><font color={'#202124'}><font face={'Kanit, serif'}><span style={{ background: '#ffffff' }}>(la
          cual queda plasmada en el COMPROBANTE DE SUSCRIPCIÓN Y CONDICIONES ESPECÍFICAS DEL PROYECTO)</span></font></font><font color={'#202124'}><font face={'Kanit, serif'}><span style={{ background: '#ffffff' }}>
            y es ley para las partes que lo firman, es celebrado entre Farm
            Energy S.A.S. E.S.P., sociedad comercial constituida conforme a las
            leyes de la República de Colombia, identificada con el Número de
            Identificación Tributaria 901.528.767-6, domiciliada en Bogotá
            D.C., Colombia, representada en este acto por DAVID CAMILO EDUARDO
            CORREDOR CORREA, identificado con C.C. 1.052.389.390 (actuando en su
            calidad de representante legal), y el que en adelante se denominará
            Inversor, y se regirá por las siguientes cláusulas, previas las
            siguientes:</span></font></font></p>

      <p align={'center'} style={{ marginBottom: '0.11in' }}><font color={'#202124'}><font face={'Kanit, serif'}><b><span style={{ background: '#ffffff' }}>CONSIDERACIONES</span></b></font></font></p>
      <ol>
        <li><p align={'justify'} style={{ lineHeight: '105%', color: '#000' }}>
          <font face={'Kanit, serif'}>
            Que, Farm Energy S.A.S. E.S.P. es una
            sociedad por acciones simplificada, que en desarrollo de su objeto
            social se encarga de diseñar, buscar, materializar y explotar
            proyectos de energía renovable de fuente no convencional en
            Colombia, bajo la modalidad principalmente de </font><font color={'#202124'}><font face={'Kanit, serif'}><span style={{ background: '#ffffff' }}>energía
              solar fotovoltaica</span></font></font><font face={'Kanit, serif'}>.</font></p>
        </li>
        <li ><p align={'justify'} style={{ lineHeight: '105%', color: '#000' }}>
          <font face={'Kanit, serif'}>Que, Farm Energy S.A.S. E.S.P. también
            está facultada por su objeto social y por la regulación de la ley
            colombiana, para ser una plataforma digital por medio de la cual se
            promueve la participación de personas jurídicas y/o naturales en
            el desarrollo y explotación de proyectos de generación y/o
            autogeneración de energía renovable de fuente no convencional,
            facilitando la interacción entre quienes tienen la experiencia en
            el desarrollo, diseño, materialización y explotación de tales
            proyectos y quienes tienen la voluntad de participar en el
            desarrollo y explotación de los mismos, en calidad de inversores. </font>
        </p>
        </li>
        <li><p align={'justify'} style={{ lineHeight: '105%', color: '#000' }}>
          <font face={'Kanit, serif'}>Que, los Inversores son personas
            jurídicas y/o naturales que buscan participar en el desarrollo y
            explotación de proyectos de generación y/o autogeneración de
            energía renovable de fuente no convencional. </font>
        </p>
        </li>
        <li><p align={'justify'} style={{ lineHeight: '105%', color: '#000' }}>
          <font face={'Kanit, serif'}>Que dichos inversores se han registrado
            en la Plataforma, de tal forma que están plenamente identificados
            para todos los efectos legal, y han seleccionado el Proyecto en el
            que desean participar.</font></p>
        </li>
        <li><p align={'justify'} style={{ lineHeight: '105%', color: '#000' }}>
          <font face={'Kanit, serif'}>Que con la suscripción o firma digital
            del presente acuerdo, los Inversores aceptan haber leído el
            presente Contrato, así como la información que lo complementa, de
            tal forma que pulsando el botón de </font><font face={'Kanit, serif'}>“Aceptar”</font><font face={'Kanit, serif'}>,
              manifiestan su voluntad de acogerse a los parámetros del contrato y
              de sus anexos, así como manifiestan estar de acuerdo en todas las
              condiciones del mismo.</font></p>
        </li>
        <li><p align={'justify'} style={{ lineHeight: '105%', color: '#000' }}>
          <font face={'Kanit, serif'}>Que, por todo lo mencionado
            anteriormente, para todos los efectos legales, el consentimiento en
            la participación de los Inversores en los proyectos de Farm Energy
            S.A.S. E.S.P., se materializa y se perfecciona cuando el inversor
            cumple con todos los siguientes pasos: i) haya leído, entendido y
            aceptado el Contrato y sus complementos y anexos pulsando el botón
          </font><font face={'Kanit, serif'}>“Aceptar”</font><font face={'Kanit, serif'}>,
            ii) haya seleccionado el número de </font><font face={'Kanit, serif'}>paneles
              digitales</font><font face={'Kanit, serif'}> a adquirir, el cual
                representará, dependiendo el número total del proyecto, el
                porcentaje de participación, y iii) haya iniciado la ejecución del
                contrato de colaboración, trasfiriendo el dinero a Farm Energy
                S.A.S. E.S.P., por medio de las herramientas que tienen designada la
                Plataforma. </font>
        </p>
        </li>
      </ol>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}>Teniendo
        claras las anteriores consideraciones, una vez aceptadas por las
        partes, el Contrato de Colaboración se regirá por las siguientes
        cláusulas: </font>
      </p>

      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>PRIMERA.OBJETO.
        1.1.</b></font><font face={'Kanit, serif'}> En virtud de este
          Contrato las partes unen esfuerzos con el objetivo de diseñar,
          desarrollar, gestionar y explotar la operación mercantil del
          proyecto seleccionado por el Inversor, cuya información específica
          se encuentra de la Plataforma en </font><font face={'Kanit, serif'}>“PROYECTOS”, “DESCRIPCIÓN”, “DETALLES”, “UBICACION” ETC...</font><font face={'Kanit, serif'}>
            del Proyecto que se entienden anexas y por tanto hacen parte integral
            del Contrato. Consecuentemente, el Inversor se obliga a contribuir a
            la(s) actividad(es) a desarrollar por Farm Energy S.A.S. E.S.P.
            mediante la aportación económica detallada en el
          </font><font face={'Kanit, serif'}> COMPROBANTE DE SUSCRIPCIÓN Y CONDICIONES ESPECÍFICAS DEL PROYECTO </font><font face={'Kanit, serif'}>,
            ubicada en la pestaña del proyecto seleccionado por el Inversor,
            eligiendo además el número de paneles digitales a adquirir y que
            corresponderá proporcionalmente a la participación seleccionada por
            el Inversor en la Plataforma, en contraprestación a dicha
            aportación, Farm Energy S.A.S. E.S.P. reconocerá la participación
            en el resultado del Proyecto conforme a lo indicado en la Cláusula
          </font><font face={'Kanit, serif'}>Cuarta</font><font face={'Kanit, serif'}>.
          </font>
      </p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>1.2.</b></font><font face={'Kanit, serif'}>
        Farm Energy S.A.S. E.S.P. solo podrá destinar los aportes entregados
        por el Inversor para el diseño, desarrollo, gestión y explotación
        del Proyecto elegido por el Inversor. Por lo tanto Farm Energy S.A.S.
        E.S.P. no podrá dar al aporte una destinación diferente a la que se
        fija en esta cláusula.</font></p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>1.3.
      </b></font><font face={'Kanit, serif'}>Las Partes expresan en forma
        inequívoca que este es un contrato de colaboración y no es, ni
        constituye un contrato de mandato, sociedad, entidad legal de
        cualquier clase, joint venture, representación, agencia comercial,
        laboral, mutuo, crédito, préstamo, corretaje o cualquier otra
        relación entre las Partes. Además, cada Parte se compromete a no
        interpretar este Contrato, o cualquiera de las transacciones
        realizadas entre ellas, como una sociedad para cualquier propósito.
        En consecuencia, no habrá reconocimiento de ningún tipo de
        compensación o indemnización al momento de su terminación derivado
        simplemente de la naturaleza del Contrato. En todo caso, el Inversor
        renuncia a cualquier derecho que no se encuentre depositado en el
        Contrato. </font>
      </p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>1.4.</b></font><font face={'Kanit, serif'}>
        La relación entre las Partes se regirá por lo previsto en el
        Contrato, y en su defecto, por las disposiciones contenidas en los
        artículos 507 y 514 del Código de Comercio y los artículos 1263 y
        subsiguientes del Código de Comercio. </font>
      </p>

      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>SEGUNDA.DURACIÒN
        Y VIGENCIA. 2.1.</b></font><font face={'Kanit, serif'}> Sin perjuicio
          de las causales de terminación previstas en el contrato, este
          comenzará a regir desde su perfeccionamiento, para cada Inversor,
          siendo plenamente vigente mientras continúe el desarrollo, la
          gestión y/o la explotación de la operación mercantil del Proyecto
          según lo descrito en el contrato de PPA (Power Purchase Agreement) o
          Contrato de Compra de Energía a Largo Plazo, que se entenderá anexo
          y por tanto parte integral de este Contrato. </font>
      </p>

      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>TERCERA.OPERACIÒN.
        3.1. </b></font><font face={'Kanit, serif'}>El Inversor se convierte
          en colaborador, en los términos del Contrato, del Proyecto que será
          ejecutado por Farm Energy S.A.S. E.S.P.. El Inversor con la
          celebración del Contrato, declara ser consciente de que le pertenece
          un determinado porcentaje de los resultados, bien sean ganancias o
          pérdidas, de la ejecución y explotación comercial del Proyecto. </font>
      </p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>3.2.</b></font><font face={'Kanit, serif'}>
        Las Partes acuerdan que el beneficio que obtendrá el Inversor será
        indeterminado pero determinable en razón a su porcentaje de
        participación en el Proyecto y el resultado del ejercicio de este.
        En todo caso, la cuantía máxima de las perdidas imputables al
        Inversor estará limitada al monto de su aportación. </font>
      </p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>3.3.
      </b></font><font face={'Kanit, serif'}>En el caso en que intervengan
        simultáneamente varios Inversores con una aportación económica que
        supere la totalidad de financiación del Proyecto, las aportaciones
        se aceptaran por orden de llegada de la transferencia del monto
        aportado. Los Inversores cuya aportación se recibida una vez
        superado el límite de la totalidad del Proyecto, podrán decidir
        entre solicitar la devolución de su aportación o seleccionar otro
        proyecto exhibido en la Plataforma. </font>
      </p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>3.4.</b></font><font face={'Kanit, serif'}>
        Las Partes declaran que desarrollan y desarrollarán sus inversiones,
        gastos y compromisos con plena conciencia del riesgo de pérdida,
        total o parcial, del valor de su aporte al Proyecto y están
        dispuestas a asumir dicho riesgo. Sin perjuicio de lo anterior, el
        Inversor no podrá influir de forma alguna en la gestión del
        Proyecto por parte de Farm Energy S.A.S. E.S.P.</font></p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>3.5.</b></font><font face={'Kanit, serif'}>
        El Inversor declara asumir riesgo y cualquier eventualidad del aporte
        realizado al Proyecto, eximiendo, por ende, a Farm Energy S.A.S.
        E.S.P. de cualquier responsabilidad por la perdida, total o parcial,
        de dicho aporte. </font>
      </p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>3.6.</b></font><font face={'Kanit, serif'}>
        Las operaciones correspondientes a la ejecución del Proyecto serán
        llevadas a cabo y se darán a conocer ante terceros como actividades
        propias de Farm Energy S.A.S. E.S.P., quien será único responsable
        de las obligaciones que surjan con ocasión al giro ordinario de la
        ejecución del Proyecto.</font></p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>3.7.</b></font><font face={'Kanit, serif'}>
        La falta de pago efectivo del aporte por parte del Inversor se
        entenderá como ausencia de consentimiento del mismo y por tanto como
        una no aceptación del presente acuerdo, no afectando las condiciones
        de las demás Partes del negocio entre ellas. Por tanto, no habrá
        lugar a obligación alguna o indemnización por parte de las Partes
        hacia el Inversor, o de este hacia ellas.</font></p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>3.8.</b></font><font face={'Kanit, serif'}>
        Los detalles especifico del Proyecto se encuentran indicados en la
        Plataforma en </font><font face={'Kanit, serif'}>“PROYECTOS”, “DESCRIPCIÓN”, “DETALLES”, “UBICACIÓN” ETC...</font><font face={'Kanit, serif'}>
          del Proyecto, incluyendo, la Fecha de inicio y la Fecha Prevista, las
          cuales determinan la fecha de inicio del Proyecto y la fecha la cual
          se prevé que el Proyecto empiece a generar resultados.</font></p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>3.9.
      </b></font><font face={'Kanit, serif'}>La Fecha prevista se entenderá
        como un plazo suspensivo de la obligación de Farm Energy S.A.S.
        E.S.P. de repartir utilidades sobre el Proyecto, y la misma podrá
        ser prorrogada por Farm Energy S.A.S. E.S.P. a su sola discreción
        por una sola vez.</font></p>

      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>3.9.</b></font><font face={'Kanit, serif'}>
      </font><font face={'Kanit, serif'}>A Farm Energy S.A.S. E.S.P. se le
        reconocerá, en razón a su labor, manejo y ejecución del Proyecto,
        el porcentaje indicado en la Plataforma para cada proyecto. EL
        porcentaje será aplicado a las sumas percibidas por la explotación
        de la operación del Proyecto o sus derivados.</font></p>

      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>CUARTA.APORTES.
      </b></font><font face={'Kanit, serif'}>El Inversor aportara al
        Proyecto el valor indicado en el </font><font face={'Kanit, serif'}>COMPROBANTE DE SUSCRIPCIÓN Y CONDICIONES ESPECÍFICAS DEL PROYECTO</font><font face={'Kanit, serif'}>,
          ubicado en la página de cada proyecto de </font><font face={'Kanit, serif'}>“Mis
            Inversiones”</font><font face={'Kanit, serif'}>, el cual
              corresponde al recuadro de “Número de paneles”, ubicado en la
              pestaña del proyecto seleccionado por el Inversor, la cual se hace
              efectiva al seleccionar el modo de “comprar”, según el número
              de paneles que haya seleccionado en la Plataforma. </font>
      </p>

      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>QUINTA.OBLIGACIONES
        DE LAS PARTES.</b></font></p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>5.
        Son obligaciones del Inversor:  </b></font>
      </p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>5.1.1.</b></font><font face={'Kanit, serif'}>
        Realizar el aporte en la forma y tiempo indicados por Farm Energy
        S.A.S. E.S.P.</font></p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>5.1.2.</b></font><font face={'Kanit, serif'}>
        Y, las demás previstas en el Contrato y la ley.</font></p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>5.2.
        Son obligaciones de Farm Energy S.A.S. E.S.P.:</b></font></p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>5.2.1.</b></font><font face={'Kanit, serif'}>
        Dar acceso al Inversor a la Plataforma Farm Energy S.A.S. E.S.P.,
        para que este pueda informarse del estado del Proyecto; y administrar
        su cuenta.</font></p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>5.2.2.</b></font><font face={'Kanit, serif'}>
        Ejecutar el Proyecto conforme a los términos, condiciones
        específicas y tiempos depositados en la Plataforma.</font></p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>5.2.3.</b></font><font face={'Kanit, serif'}>
        Llevar la contabilidad del Proyecto de conformidad con la ley 1314 de
        2009.</font></p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>5.2.4.</b></font><font face={'Kanit, serif'}>
        Poner su mejor esfuerzo para lograr el éxito del Proyecto y obtener
        un resultado favorable del mismo, asegurando la efectiva operación
        del Proyecto y poniendo de su conocimiento y capacidad para la
        ejecución de este.</font></p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>5.2.5.</b></font><font face={'Kanit, serif'}>
        Enviar al Inversor un reporte mensual con el resultado del Proyecto.</font></p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>5.2.6.</b></font><font face={'Kanit, serif'}>
        Y, las demás previstas en el Contrato y la ley.</font></p>

      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>SEXTA.UTILIDAD
        Y PÈRDIDAS. 6.1.</b></font><font face={'Kanit, serif'}> Por año
          vencido, desde el momento de la Fecha Prevista y mientras el Contrato
          continúe vigente, se reconocerá al Inversor un porcentaje
          equivalente al porcentaje de participación sobre las sumas percibida
          por la explotación de la operación mercantil del Proyecto,
          posterior al descuento por concepto en porcentajes, i) de
          remuneración o de administración del proyecto por parte de Farm
          Energy S.A.S. E.S.P.; ii) el porcentaje destinado para Operación y
          Mantenimiento del Proyecto; y iii) la suma equivalente a los gatos y
          costos del Proyecto. </font><font face={'Kanit, serif'}>Estos
            porcentajes serán expuestos en la Plataforma, individualmente por
            cada proyecto. Lo anterior, en consecuencia, del costo y tamaño del
            Proyecto.</font><font face={'Kanit, serif'}> </font>
      </p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}>Sin
        prejuicio de lo anterior, una vez las utilidades de los Inversores se
        hayan consolidado en el panel “Saldo Disponible” de la
        Plataforma, los Inversores podrán disponer de sus utilidades y
        realizar el retiro respectivo o aportar a otros proyectos. Si al cabo
        de un año, los Inversores no han dispuesto de sus Utilidades, estas
        serán consignadas en la cuenta bancaria proporcionada por el
        Inversor para este fin.  </font>
      </p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}>Las
        sumas que corresponda a cada Inversor de conformidad con el cálculo
        anterior, se pondrán a disposición de los Inversores en la
        Plataforma en la función “Billetera”, y serán consignadas a la
        cuenta registrada en la Plataforma de cada Inversor, según
        corresponda, cuando este así lo quiera, en el monto que este
        solicite (siempre que sea igual o inferior a la suma que posea en la
        función de “Saldo Disponible” de la Plataforma), al pulsar el
        botón “Retirar” dentro de la funcionalidad “Billetera”. Los
        costos bancarios, de trasferencias bancarias, así como el GMF, serán
        asumidos por el Inversor. El Inversor estará obligado a declarar y
        pagar los impuestos que se causen por su Utilidad.</font></p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>6.2.</b></font><font face={'Kanit, serif'}>
        En caso de que el Proyecto genere pérdidas, estas se asumirán en
        proporción al aporte realizado al Proyecto, no siendo responsable el
        Inversor por ninguna razón por montos que superen dicho aporte.</font></p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>6.3.</b></font><font face={'Kanit, serif'}>
        A través de la Plataforma, el Inversor podrá visualizar y
        administrar el estado de su participación en el Proyecto, así como
        los resultados de utilidad o perdida del mismo. Los valores de
        utilidad y pérdida se actualizarán de forma mensual. El Inversor
        podrá solicitar el retiro o depósito de las ganancias generadas a
        través de la Plataforma en cualquier momento, o por el contario
        reinvertirlo en otros proyectos exhibidos en la Plataforma.  </font>
      </p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>6.4.</b></font><font face={'Kanit, serif'}>
        Las partes acuerdan que el Proyecto no generara utilidades hasta la
        Fecha Prevista de funcionamiento indicada en la Plataforma. </font>
      </p>

      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>SEPTIMA.CAUSALES
        DE DETERMINACIÒN. </b></font><font face={'Kanit, serif'}>El contrato
          de colaboración permanecerá vigente y generará efectos para las
          Partes por el término indicado en la cláusula de Duración, sin
          embargo, podrá darse por determinado anticipadamente por cualquiera
          de las siguientes:</font></p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>7.1</b></font><font face={'Kanit, serif'}>.
        Farm Energy S.A.S. E.S.P. podrá dar por terminado el Contrato por
        cualquiera de las siguientes causales, que deberán notificarse y sin
        que las mismas impliquen el pago de algún tipo de indemnización
        entre las Partes y sin que se requiera resolución judicial: </font>
      </p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>7.1.1.</b></font><font face={'Kanit, serif'}>
        Respecto a cada Inversor, por el incumplimiento de las obligaciones
        del Participé.</font></p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>7.1.2.</b></font><font face={'Kanit, serif'}>
        O, si el Inversor es incluido en la lista </font><font face={'Kanit, serif'}><i>Specially
          Designated Nationals and Blocked Persons</i></font><font face={'Kanit, serif'}>
            de la OFAC – </font><font face={'Kanit, serif'}><i>Office of
              Foreing Assets Control</i></font><font face={'Kanit, serif'}> – del
                Departamento del Tesoro de los Estados Unidos de Norteamérica.  </font>
      </p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>7.1.3.</b></font><font face={'Kanit, serif'}>
        En el supuesto que Farm Energy S.A.S. E.S.P. no recaude la totalidad
        de aportes necesarios para desarrollar el Proyecto antes de la Fecha
        Prevista o su prórroga.</font></p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>7.1.4.</b></font><font face={'Kanit, serif'}>
        Por la imposibilidad de ejecutar el Proyecto con posterioridad a la
        Fecha Efectiva.</font></p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>7.1.5.</b></font><font face={'Kanit, serif'}>
        Y, cualquier otra causal prevista en el Contrato o la ley.</font></p>

      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>OCTAVA.EFECTOS
        DE LA TERMINACIÒN. 8.1.</b></font><font face={'Kanit, serif'}> Ante
          cualquiera de los eventos previstos en la cláusula inmediatamente
          anterior, o el cumplimiento del término previsto en la Cláusula
          Segunda, y bajo el supuesto que el Inversor haya realizado su
          respectivo aporte al Proyecto, Farm Energy S.A.S. E.S.P. estará
          obligado a devolver dicha aportación al Inversor, así como otro
          monto a su favor por concepto de Utilidad del Proyecto.</font></p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>8.2.</b></font><font face={'Kanit, serif'}>
        En caso de que la terminación se dé por la venta del Proyecto en
        los términos del PPA (Power Purchase Agreement) o Contrato de Compra
        de Energía a Largo Plazo, se reconocerá a cada Inversor la Utilidad
        sobre la operación calculada según la regla del numeral 6.3. del
        presente Contrato y el monto por concepto de liquidación del PPA de
        acuerdo al porcentaje de participación de cada Inversor del
        Proyecto. De igual forma, será proporcionada la información en la
        Plataforma de esta operación al Inversor. </font>
      </p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>8.3.</b></font><font face={'Kanit, serif'}>
        En todo caso, los gastos causados por la devolución de cualquier
        valor al Inversor serán sufragados por el Inversor. </font>
      </p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>NOVENA.SOLUCIÒN
        DE DISPUTAS. 9.1.</b></font><font face={'Kanit, serif'}> Cualquier
          disputa, controversia o reclamación entre las Partes que surja de,
          bajo, en conexión con, respecto a, o en relación con el presente
          Contrato, referente, pero sin limitarse, a su existencia, validez,
          interpretación, ejecución, incumplimiento, terminación y/o
          liquidación, debe intentarse resolver amigablemente entre los Partes
          dentro de los noventa (90) días calendario siguiente al día que
          haya surgido la disputa, controversia o reclamación. Este término
          es improrrogable. </font>
      </p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>9.2.</b></font><font face={'Kanit, serif'}>
        Vencido el mencionado termino improrrogable sin haberse alcanzado una
        resolución amigable, la disputa, controversia o reclamación será
        sometida a arbitraje y resuelta finalmente bajo el reglamento de
        arbitraje de la Cámara de Comercio de Bogotá que este vigente al
        momento de la iniciación del proceso arbitral. Dicho esto,
        reglamento regirá el arbitraje, excepto cuando una de sus normas
        este en conflicto con una disposición del derecho aplicable al
        arbitraje que las Partes no puedan derogar, cuyo caso prevalecerá
        una disposición. El número de árbitros será de uno (1). El
        árbitro fallara en derecho con base en la Ley de la Republica de
        Colombia. </font>
      </p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>9.3.</b></font><font face={'Kanit, serif'}>
        Cada una de las Partes irrevocablemente se somete a que la
        jurisdicción de dicho Tribunal de Arbitraje sea Bogotá D.C.,
        Colombia. Cada Parte renuncia irrevocablemente a objetar que el
        arbitramento se lleve a cabo en Bogotá, Colombia y, a objetar que la
        ejecución del laudo proferido por el Tribunal de Arbitramento pueda
        demandarse en cualquier juzgado, tribunal o corte de jurisdicción en
        Bogotá, Colombia. </font>
      </p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>9.4
      </b></font><font fac={'true'}></font>La Parte perdedora sumirá los
        gastos de arbitraje, si no se decide de otra manera por el Tribunal
        de Arbitraje.
      </p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>9.5</b></font><font face={'Kanit, serif'}>
        Esta Cláusula tendrá plenos efectos, ante cualquier causal de
        terminación o expiración del Contrato.</font></p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>DÈCIMA.FUERZA
        MAYOR. </b></font><font face={'Kanit, serif'}>Ninguna de las Partes
          será responsable por el incumplimiento o el cumplimiento tardío de
          las obligaciones establecidas en este Contrato cuando ello se deba a
          un evento de fuerza mayor o caso fortuito, exceptuando las
          obligaciones relacionadas con la confidencialidad y la reserva. </font>
      </p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>DÉCIMA
        PRIMERA.CESIÒN. 11.1.</b></font><font face={'Kanit, serif'}> Ni el
          contrato, ni ningún derecho u obligación que del mismo se derive,
          podrá ser cedido o de cualquier modo trasferido por el Inversor sin
          el consentimiento previo de Farm Energy S.A.S. E.S.P. Las condiciones
          de una eventual cesión y su costo serán objeto de regulación en el
          contrato de cesión. Cualquier cesión o trasferencia llevada a cabo
          de forma contraria a lo a qué dispuesto en este contrato será nula.
        </font>
      </p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>11.2.</b></font><font face={'Kanit, serif'}>
        Cualquier cesionario o de cualquier modo adquiriente autorizado
        asumirá todas los obligaciones o derechos cedidos o trasferidos bajo
        este Contrato por su cedente o cualquier modo tradente. </font>
      </p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>DÈCIMA
        SEGUNDA.CONFIDENCIALIDAD. 12.1.</b></font><font face={'Kanit, serif'}>
          En adelante se llamara Información Confidencial a: a) cualquier
          información financiera, comercial, de negocios, de mercado como:
          marcas, información sobre categorías actuales y/o nuevas
          categorías, estudios y análisis de consumidores, información
          estratégica, oportunidades de negocio, diseño de marcas,
          publicidad, materia prima, volúmenes de ventas, proveedores,
          especificaciones técnicas de productos, formulaciones, procesos de
          fabricación, ingredientes, estudios de consumidores, datos
          estadísticos, información financiera y contable, y en general
          cualquier otra relacionada con las operaciones de negociones
          presentes y futuros de las Partes, sus vinculadas presentes y
          futuras, la cual la parte reveladora no haya autorizado a la parte
          receptora a revelar a terceras personas, bien sea que dicha
          información sea escrita, oral o visual o en medio magnético, o en
          cualquier otras forma y en cualquier otro medio y ya sea marcada o
          identificada como confidencial o no, suministrada o divulgada (ya sea
          antes o después de la Fecha Efectiva de este Contrato) por, o a
          nombre de la parte reveladora, y/o cualquiera de los respectivos
          directores, funcionarios, empleados o representantes; b) Todos los
          análisis, recopilaciones, datos, estudios, memorandos, informes y
          documentos, en cualquier forma y en cualquier medio, elaborados por
          las partes o sus directores, funcionarios, empleados o representantes
          que se deriven de o se relacionen con la información de que trata el
          literal a) anterior, o que contengan o se basen en todo o en parte en
          dicha información: y, c) Cualquier idea, concepto, know-how,
          conocimiento o técnica relacionada con las actividades propias del
          negocio de las Partes, contenida en la información de que trata el
          literal a) anterior, y que permanezcan en la memoria de los empleados
          de las Partes que han tenido accesos a la información Confidencial
          durante la ejecución del Contrato. </font>
      </p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>12.2.</b></font><font face={'Kanit, serif'}>
        Las Partes se obligan a no revelar a terceras personas la Información
        Confidencial que reciban y, en consecuencia, se obligan a mantenerla
        de manera confidencial y privada, y a protegerla para evitar su
        divulgación, ejerciendo el mismo grado de control que utilizará
        para proteger la Información Confidencial de su propiedad.</font></p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>12.3.</b></font><font face={'Kanit, serif'}>
        Las Partes no utilizaran la información confidencial para fines
        comerciales diferentes a la ejecución del Proyecto. </font>
      </p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>12.4.</b></font><font face={'Kanit, serif'}>
        La obligación de no revelar la Información Confidencial y las
        restricciones para la utilización no aplicará respecto de: (i)
        Información Confidencial que sea de dominio público o adquiera tal
        condición o sea publicada por cualquier medio sin ninguna acción de
        la parte receptora en contravención a lo establecido en este
        Contrato; (ii) Información Confidencial que antes de ser revelada
        estuviera en posesión legitima de aluna de las Partes; (iii)
        Información Confidencial que sea legalmente recibida por alguna de
        las Partes de un tercero que tenga derecho para distribuirla, sin
        notificación de ninguna restricción del derecho de revelar
        posteriormente; (iv) Que la Información Confidencial sea adquirida
        por alguna de las Partes a través de personas que no hayan tenido,
        directa o indirectamente, acceso o conocimiento de la Información
        Confidencial; (v) Información Confidencial que se revele con la
        aprobación previa y escrita de la parte reveladora; y (vi) Que la
        revelación y/o divulgación de la Información Confidencial se
        realice en desarrollo o mandando de una ley, decreto o sentencia u
        orden de autoridad competente en ejercicio de sus funciones legales.
        En este casi, la parte receptora de obliga a avisar inmediatamente
        tenga conocimiento de esta obligación a la parte reveladora, para
        que este último pueda tomar las medidas necesarias para proteger su
        Información Confidencial, y de igual manera se compromete a tomar
        medidas pertinentes para atenuar los efectos de tal divulgación. </font>
      </p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>12.5.</b></font><font face={'Kanit, serif'}>
        La entrega de Información Confidencial no concede a las Partes
        autorización, permiso o derechos de autor o de cualquier otro
        derecho de propiedad industrial o intelectual y seguirá siendo
        propiedad exclusiva de cada Parte, y será devuelva, junto con todas
        las copias que de ella se hubiera hecho, dentro de los tres (3) días
        hábiles siguientes a la solicitud que en este sentido haga la parte
        reveladora.</font></p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>12.6.
      </b></font><font face={'Kanit, serif'}>Las Partes se obligan a
        mantener la confidencialidad de la información durante toda la
        duración de este Contrato y tres (3) año más de la fecha de su
        terminación. En todo caso las Partes destruirán toda la Información
        Confidencial de la cual tuvieran copia o registro, pasados treinta
        (30) días desde la fecha de terminación del Contrato. </font>
      </p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>DÈCIMA
        TERCERA. LEY APLICABLE Y JURISDICCIÒN: 13.1. </b></font><font face={'Kanit, serif'}>El
          presente Contrato se regirá en todo por las leyes de la Republica de
          Colombia, sin consideración a las normas de conflicto de leyes o a
          principios que den efecto a las leyes de otra jurisdicción o al
          lugar donde este se ejecute por cada una de las Partes. Por ello
          cualquier acción derivada del mismo de ejercerá y decidirá de
          acuerdo con las leyes de la República de Colombia. </font>
      </p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>13.2.</b></font><font face={'Kanit, serif'}>
        Cada una de las Partes irrevocablemente se somete a la jurisdicción
        de la República de Colombia. </font>
      </p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>13.3.</b></font><font face={'Kanit, serif'}>
        Esta cláusula subsistirá a la terminación del Contrato. </font>
      </p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>DÈCIMA
        CUARTA. CONTENIDO DEL CONTRATO. </b></font><font face={'Kanit, serif'}>Este
          Contrato, junto con sus anexos, contiene el completo acuerdo entre
          las Partes respecto al objeto del mismo. En consecuencia, sustituye y
          anula todos los anteriores acuerdos, negociaciones, compromisos,
          restricciones, declaraciones o garantías entre las Partes, y
          entendimientos con respecto al objeto de este, ya sean orales o
          escritos. </font>
      </p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>DÈCIMA
        QUINTA. ENMIENDAS Y ANEXOS. </b></font><font face={'Kanit, serif'}>Las
          modificaciones u otrosíes o cualquier otra modificación del
          Contrato o cualesquiera de sus anexos entrarán en vigor solo después
          de que sean aceptadas por cada una de las Partes y harán parte
          integral del Contrato, para lo cual Farm Energy S.A.S. E.S.P.
          informará a cada uno de los Inversores el texto de la modificación
          a través de la Plataforma y cada uno de los Inversores deberá
          manifestar su aceptación pulsando el botón </font><font face={'Kanit, serif'}>“Acepto”</font><font face={'Kanit, serif'}>.
            Si se presenta un conflicto entre la disposición del Contrato y lo
            estipulado en una disposición de una modificación, los términos de
            esta última prevalecerán. Si se presenta u conflicto entre la
            disposición del Contrato y una de un anexo, los términos de la
            primera prevalecerán. Si se presenta un conflicto entre una
            disposición del Contrato y una de un anexo enmendado, los términos
            de este último prevalecerán. </font>
      </p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>DÈCIMA
        SÈPTIMA. CONTRATANTES INDEPENDIENTES. </b></font><font face={'Kanit, serif'}>Las
          Partes reconocen, acuerdan y declaran que la relación establecida en
          virtud del presente Contrato se circunscribe únicamente al objeto
          descrito en el mismo y por ello son contratantes independientes que
          actúan por su propia cuenta, con absoluta autonomía y ninguna
          estará sometida a la otra bajo subordinación laboral o a relación
          societaria alguna.</font></p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>DÈCIMA
        OCTAVA. NOTIFICACIONES. 18.1. </b></font><font face={'Kanit, serif'}>Toda
          notificación que en virtud del presente Contrato deba efectuar Farm
          Energy S.A.S. E.S.P. a cada uno de los Inversores se hará a través
          de la Plataforma a la cuenta de usuario de cada uno o al correo
          electrónico suministrado por el Inversor, y se considerara
          debidamente efectuada el siguiente día hábil a su fecha de envío,
          si así se comprueba. </font>
      </p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}>Toda
        notificación que en virtud del presente Contrato deba efectuar el
        Inversor a Farm Energy S.A.S. E.S.P.  se hará mediante comunicación
        escrita dirigida a la dirección de correo electrónico o a la
        dirección física registrada en este contrato. </font>
      </p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>18.2.</b></font><font face={'Kanit, serif'}>
        Las direcciones que Farm Energy S.A.S. E.S.P. registra son: </font>
      </p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>18.2.1.
        Direcciones:</b></font><font face={'Kanit, serif'}> Carrera 9 No.
          59-43 Torre A Apto 610, Bogotá D.C., Colombia. </font><font face={'Kanit, serif'}><b>Teléfono:
          </b></font><font face={'Kanit, serif'}>3155000797, </font><font face={'Kanit, serif'}><b>Correo:
            leonardo.talero@energy.com</b></font><font face={'Kanit, serif'}>. </font><font face={'Kanit, serif'}><b>Persona
              de contacto:</b></font><font face={'Kanit, serif'}> David Camilo
                Corredor Correa. </font>
      </p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>18.3.</b></font><font face={'Kanit, serif'}>
        En aquellos casos en que la comunicación incluya un término para la
        realización de algún acto, el mismo empezara a correr desde el día
        hábil siguiente a aquel en se entienda surtida la notificación. </font>
      </p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>DÈCIMA.NOVENA.
        RENUNCIA. 19.1.</b></font><font face={'Kanit, serif'}> Si en
          cualquier momento una de las Partes renuncia, de forma parcial o
          total, a cualquier termino o disposición de este Contrato, la
          renuncia solo será efectiva para el caso y el propósito específico
          para el cual fue dada, y para cada Parte, sin establecerse un
          precedente para cualquier otro caso o propósito con respecto a estos
          termino(s) disposición(es) o cualquier otro termino o disposición y
          no impedirá su futura aplicación ni tampoco que todos los demás
          términos o disposiciones de este Contrato continúen en pleno vigor
          y efecto.</font></p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>19.2.</b></font><font face={'Kanit, serif'}>
        Si cualquiera de las Partes no ejerce o retrasa el ejercicio de
        cualquier derecho en virtud del Contrato, esa parte se reserva el
        derecho de hacer cumplir el término o disposición en un momento
        posterior. </font>
      </p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>19.3.</b></font><font face={'Kanit, serif'}>
        En cualquier caso, ninguna renuncia de cualquier termino o
        disposición de este Contrato será válida, a menos que sea mediante
        escrito firmado por la Parte contra la cual dicha renuncia se alega. </font>
      </p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>VIGÈSIMA.NULIDADES.
      </b></font><font face={'Kanit, serif'}>Las Partes acuerdan que la
        declaración de nulidad, ineficacia, o cualquier otra parecida, que
        declare un juez, autoridad competente o la Ley sobre alguna o varias
        de las cláusulas del presente Contrato, siempre que sean de la
        esencia del mismo, solo afectan esa o esas disposiciones y no afectan
        la validez o vician las demás que continuarán generando efectos
        entre las Partes.</font></p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}>Así
        mismo los vicios que afectan la validez del Contrato respecto a una
        de las Partes, uno de los Inversores, no afectara la validez del
        mismo respecto a las otras, que continuarán obligadas en lo que les
        corresponde en los términos del presente Contrato y sus anexos o
        complementarios. </font>
      </p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>VIGÈSIMA
        PRIMERA. GASTOS E IMPUESTOS. </b></font><font face={'Kanit, serif'}>Sin
          perjuicios de lo dispuesto en otras disposiciones de este Contrato,
          los gastos notariales, impositivos y, en general, de cualquier
          naturaleza que cause este Contrato estrían a cargo de Farm Energy
          S.A.S. E.S.P.</font></p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>VIGÈSIMA
        SEGUNDA.PREVENCIÒN Y AUTOGESTIÒN DEL RIESGO DE LAVADO DE ACTIVOS
        Y/O FINANCIACION DEL TERRORISMO. </b></font><font face={'Kanit, serif'}>Las
          Partes se obligan a dar cumplimiento a todas las normas vigentes
          sobre prevención y autogestión del riesgo de lavados de activos y/o
          financiación del terrorismo que le sean aplicables, en especial a la
          Circular Externa No. 100-000005 de 2014 de la Superintendencia de
          Sociedades y/o  cualquier norma que la modifique, complemente o
          sustituya en el evento en que sea una entidad vigilada por la
          Superintendencia de Sociedades que cumpla con los topes definidos en
          la misma circular para implementar un sistema de autocontrol y
          gestión del riesgo de lavados de activos. Si el Inversor no es una
          entidad vigilada por la Superintendencia de Sociedades, deberá
          contar con procesos y procedimientos que permitan prevenir el riesgo
          de lavado de activos y financiación del terrorismo. En cualquiera de
          estos dos eventos, las Partes se obligan a tomar las medidas que
          razonablemente consideren sean necesarias para tener un adecuado
          conocimiento de todas aquellas personas con las cuales tengas
          relaciones comerciales, civiles p laborales, a efecto de vitae verse
          involucrado directa o indirectamente en cualquier actividad de lavado
          de activos y/o financiación del terrorismo. Si perjuicio de los
          dispuesto en el Contrato sobre su terminación anticipada, el
          incumplimiento de esta obligación, así como la inclusión del
          Inversor en cualquiera de las listas nacionales o internacionales que
          se señalan Circular Externa No. 100-000005 de 2014 de la
          Superintendencia de Sociedades y/o cualquier norma que la modifique,
          complemente o sustituya, así como la iniciación de cualquier
          proceso judicial o administrativo en relación con lavado de activos
          y financiación del terrorismo, facultara al Gestor para dar por
          terminado unilateral e inmediatamente el presente Contrato y exigir
          al Inversor el resarcimiento de todos los daños que dicho
          incumplimiento genere.   </font>
      </p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>VIGÈSIMA
        TERCERA.TRATAMIENTO DE DATOS PERSONALES. </b></font>
      </p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}>El
        Inversor autoriza el tratamiento de sus Datos Personales a Farm
        Energy S.A.S. E.S.P. es decir, realizar operaciones sobre los mismos
        como recolección, almacenamiento, uso, circulación, trasferencia o
        trasmisión; y para que permita su tratamiento a quienes sean sus
        representantes o con quienes celebre contratos de transferencia o
        trasmisión de datos, ubicación dentro y fuera del territorio
        nacional. Declaro que conozco los fines para las cuales serán
        tratados mis Datos Personales o los de la persona que represento,
        así: (i) Recibir cotizaciones de productos o servicios de Farm
        Energy S.A.S. E.S.P., de acuerdo con la información que entregue a
        Farm Energy S.A.S. E.S.P. al momento de esta autorización y
        posteriormente; (ii) Ser contactado por un representante o empleado
        de Farm Energy S.A.S. E.S.P.; (iii) Recibir oferta de productos o
        servicios de Farm Energy S.A.S. E.S.P., realización de encuestas y
        otros fines comerciales o publicitarios a través de mensajes de
        texto, correo electrónico, SMS, entre otros; (iv) Fines
        estadísticos, de consultado, gremiales. </font>
      </p>

      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>IMPORTANTE:</b></font><font face={'Kanit, serif'}>
        El Inversor autoriza el tratamiento de los datos sensibles, en
        especial, los relativos a nombre, número de identificación personal
        y financieros y entiende que las preguntas que se hagan sobre estos
        datos, tienen carácter facultativo. Como titular de la información,
        el Inversor conoce que le asisten los derechos previstos en la Ley,
        en especial, conocer, actualizar, rectificar y solicitar la supresión
        de sus datos. El Inversor manifiesta que los datos que ha
        suministrado a Farm Energy S.A.S. E.S.P. son ciertos, que la
        información que ha entregado es veraz y verificable y autoriza su
        verificación ante cualquier persona natural o jurídica, pública o
        privada. Esta autorización aplica siempre que subsistan los fines
        para los cuales serán tratados sus datos y por un término máximo
        de 3 años trascurridos desde la terminación del Contrato por
        cualquier causa. El responsable del tratamiento de los datos es Farm
        Energy S.A.S. E.S.P., ubicada en la Carrera 9 No. 59-43 Torre A Apto
        610 de Bogotá D.C.. Se informa que el Inversor puede consultar las
        políticas de tratamiento de datos personales en  la plataforma de
        Farm Energy S.A.S. E.S.P.. Con el registro de los datos personales en
        la Plataforma, el Inversor acepta esta cláusula y los términos y
        condiciones de la Política de Tratamiento de Datos Personales de
        Farm Energy S.A.S. E.S.P.. </font>
      </p>

      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>VIGÈSIMA
        CUARTA.ANEXOS.</b></font></p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>23.1.</b></font><font face={'Kanit, serif'}>
        Hacen parte integral del Contrato los siguientes anexos:</font></p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>23.1.1.</b></font><font face={'Kanit, serif'}>
        La información registrada por cada Inversor dentro de la Plataforma.
      </font>
      </p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>23.1.2.</b></font><font face={'Kanit, serif'}>
        La información sobre el Proyecto que se exhiba en la Plataforma. </font>
      </p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>23.1.3.</b></font><font face={'Kanit, serif'}>
        Anexo A – Términos y Condiciones de Uso de la Plataforma. </font>
      </p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>23.1.4</b></font><font face={'Kanit, serif'}>.
        Los datos mencionados en el presente Contrato como anexos o
        complementarios al mismo.</font></p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>23.1.5.</b></font><font face={'Kanit, serif'}>
        Cualquier otro documento o información relevante que se exhiba en la
        Plataforma. </font>
      </p>

      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}><b>VIGÈSIMA
        QUINTA. PERFECCIONAMIENTO.</b></font></p>
      <p align={'justify'} style={{ marginBottom: '0.11in' }}><font face={'Kanit, serif'}>Para
        todos los efectos del presente Contrato el consentimiento se formará
        y por tanto se perfeccionará para cada uno de los Inversores en el
        momento en que habiéndose registrado en la Plataforma, i) haya
        leído, entendido y aceptado el Contrato y sus complementos y anexos
        pulsando el botón </font><font face={'Kanit, serif'}>“Aceptar”</font><font face={'Kanit, serif'}>,
          ii) haya seleccionado el número de </font><font face={'Kanit, serif'}>paneles
            digitales</font><font face={'Kanit, serif'}> a adquirir, el cual
              representará, dependiendo el número total del proyecto, el
              porcentaje de participación, y iii) haya iniciado la ejecución del
              contrato de colaboración, trasfiriendo el dinero a Farm Energy
              S.A.S. E.S.P., por medio de las herramientas que tienen designada la
              Plataforma. </font>
      </p>
      <p style={{ marginBottom: '0.11in' }}> <font face={'Kanit, serif'}>En
        constancia de todo lo anterior, se firma digitalmente de la siguiente
        forma:</font></p>

      {/* <p style={{marginBottom: '0.11in', lineHeight: '105%'}}><font face={'Kanit, serif'}>_______________________________________________</font></p> */}
    </>;
};

export default PaymentContract;
