import React, { useState } from 'react';
import {
   GoogleMap, LoadScript, Autocomplete,
   Marker, InfoWindow
} from '@react-google-maps/api';
import Geocode from 'react-geocode';
import Maps from '../../../../general/maps/Map.js';
import styles from '../description.module.css';

const inputStyle = {
    boxSizing: 'border-box',
    border: '1px solid transparent',
    width: '240px',
    height: '32px',
    padding: '0 12px',
    borderRadius: '3px',
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
    fontSize: '14px',
    outline: 'none',
    textOverflow: 'ellipses',
    position: 'absolute',
    left: '50%',
    marginLeft: '-120px',
};
// eslint-disable-next-line react/prop-types
const Location = ({ project = {}, dispatch, projectId = '', summary }) => {
    const { projectData = {} } = project;
    const { location } = projectData;
    const googleApiKey = process.env.REACT_APP_GOOGLE_MAPS || '';

    const libraries = ['places'];
    const [autoComplete, setAutoComplete] = useState(null);
    const [position, setPosition] = useState({
      lat: location?.lat || 4.71098,
      lng: location?.lng || -74.072092,
    });
    const [address, setAddress] = useState(location?.address || 'Bogotá/Colombia');
    const [infoWindowState, setInfoWindowState] = useState(true);

    const onPlaceChanged = () => {
      const lat = autoComplete.getPlace().geometry.location.lat();
      const lng = autoComplete.getPlace().geometry.location.lng();
      const address = autoComplete.getPlace().formatted_address;
      setPosition({ lat, lng });
      setAddress(address);
      dispatch({
        type: 'UPDATE_PROJECT',
        project: {
          projectData: {
            location: { address, lat, lng },
          },
        },
      });
    };

    const onDragEnd = async (e) => {
      const geoData = await Geocode.fromLatLng(e.latLng.lat(), e.latLng.lng());
      const address = geoData.results[0].formatted_address;
      setAddress(address);
      dispatch({
        type: 'UPDATE_PROJECT',
        project: {
          projectData: {
            location: { address, lat: e.latLng.lat(), lng: e.latLng.lng() },
          },
        },
      });
    };

    const containerStyle = {
      width: '400px',
      height: '400px',
      margin: 'auto',
    };

    if (!projectData) return <></>;

    return <div className={styles.location}>
      {summary
      ? <Maps projects={[project]} width={'100%'} height={'45vh'} />
      : <LoadScript googleMapsApiKey={googleApiKey} libraries={libraries}>
        <GoogleMap
              id={'map'}
              mapContainerStyle={containerStyle}
              zoom={15}
              center={position}
              style={{ zIndex: '0' }}
          >
          {infoWindowState ? (
            <InfoWindow position={{ lat: (position.lat), lng: position.lng }}
                        onCloseClick={() => setInfoWindowState(false)}>
              <span style={{ padding: 0, margin: 0 }}>{address}</span>
            </InfoWindow>
          ) : <></>}
          <div style={{ backgroundColor: 'blue' }}>
            <Marker
                  name={'Dolores park'}
                  draggable={true}
                  onDragEnd={onDragEnd}
                  onClick={() => { setInfoWindowState(true); }}
                  position={{ lat: position.lat, lng: position.lng }}
                  />

            <Marker />
          </div>

          <Autocomplete
            onLoad={(e) => setAutoComplete(e)}
            onPlaceChanged={onPlaceChanged}
            types={['geocode']}>
            <input
              type={'text'}
              placeholder={'Ubicación'}
              style={inputStyle}
            />
          </Autocomplete>
        </GoogleMap>
      </LoadScript>
      }
    </div>;
};

export default Location;
