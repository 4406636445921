/* eslint-disable max-depth */
/* eslint-disable max-len */
import React, { createContext, useContext, useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import UserApi from '../api/UserApi';
import { profileReducer } from '../reducer/ProfileReducer';
import { UserDataContext } from '../context/UserContext';
import { firestore } from '../firebase';
import LoadingEffect from '../components/loading/Loading';
// import { mdiWindowShutter } from '@mdi/js';

export const ProfileContext = createContext();

const documentOptions = ['nit', 'rut', 'declaration', 'incomes', 'bank', 'existence', 'legal'];

const userOptions = ['country', 'department', 'city', 'address', 'phone', 'typePerson'];

const ProfileContextProvider = ({ children }) => {
    const { user: userVerification } = useContext(UserDataContext);
    const [profile, dispatch] = useReducer(profileReducer, {});
    const { user = {}, documents = {} } = profile;

    const [transactionId, setTransactionId] = useState('');

    // SNACK MESSAGE
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');

    // SAVE PROFILE DATA
    const [saveProfileData, setSaveProfileData] = useState(false);

    // BIOMETRIC AUTH
    const [biometricUrl, setBiometricUrl] = useState('');

    // exist
    const [exist, setExist] = useState('');

    // payment-event
    const [payment, setPayment] = useState(false);

    // payment-event
    const [userVerified, setUserVerified] = useState(false);

    // Qr charge
    const [statusQr, setStatusQr] = useState('');

    // eslint-disable-next-line consistent-return
    const profileSectionAction = (section) => {
        switch (section) {
            case 0:
                if (validateDocuments(user, section, user.typePerson)) return true;
                else {
                    setSnackMessage('Alguno de los campos ingresados son erroneos.');
                    setOpen(true);
                    return false;
                }
            case 1:
                if (validateDocuments(documents, section, user.typePerson)) return true;
                else {
                    setSnackMessage('Por favor, carge todos los documentos solicitados');
                    setOpen(true);
                    return false;
                }
            default:
                break;
        }
    };

    const validateDocuments = (data, section, type) => {
        let checkFields = 0;
        if (type) {
            const options = !section ? userOptions : documentOptions;

            const target = options.slice(0, options.length - (type === '2' || !section ? 0 : 2)).length;
            for (let i = 0; i < target; i++) if (data[options[i]]) checkFields += 1;
            return target === checkFields;
        }
        return checkFields;
    };

    const userData = async () => {
        const { user, filesPath, uploadedDocuments, wallet } = (await firestore.collection('users').doc(userVerification.email)
            .get()).data() || {};
        dispatch({ type: 'ADD_USER', user: { ...user, uploadedDocuments, wallet } });
        dispatch({ type: 'ADD_DOCUMENT', documents: filesPath });

        if (user) setExist('true');
    };

    useEffect(() => {
        if (payment || saveProfileData) {
            const listener = firestore.collection('transaction').doc(user.nit)
            .collection('request')
            .orderBy('lastUpdate', 'desc')
            .limit(1)
            .onSnapshot((doc) => {
                if (doc.size > 0) {
                    const { matched, eventType, operationId } = doc.docs[0].data();
                    if (operationId === transactionId) {
                        if (eventType !== 5) listener();

                        if (eventType === 1 && matched) {
                            setStatusQr('pass');
                        } else if (eventType === 1 && matched === false) {
                            setStatusQr('noValidate');
                            setTimeout(() => window.location.reload(), 3000);
                        }
                        if (eventType === 5) {
                            setStatusQr('loading');
                            setPayment(false);
                        } else {
                            if (payment) setUserVerified(matched);
                            else {
                                if (matched) {
                                    const formData = new FormData();
                                    Object.keys(documents).forEach(
                                        (key) => formData.append(key, documents[key][0]));
                                    formData.append('user', JSON.stringify({ ...user, email: userVerification.email }));
                                    UserApi.saveUserDocuments(formData).then(() => window.location.reload());
                                }
                            }
                        }
                    }
                }
            });
        }
        // eslint-disable-next-line
    }, [saveProfileData, payment]);

    useEffect(() => {
        userData();
        // eslint-disable-next-line
    }, []);

    const share = {
        statusQr,
        setStatusQr,
        // PROFILE REDUCER
        profile,
        dispatch,
        // SAVE PROFILE DATA EVENT
        setSaveProfileData,
        // CHECK SECTIONS
        profileSectionAction,
        // BIOMETRIC AUTH
        biometricUrl,
        setBiometricUrl,
        // SNACK MESSAGE
        snackMessage,
        setSnackMessage,
        setOpen,
        open,
        exist,
        // payment event
        payment,
        setPayment,
        // check user auth
        setUserVerified,
        userVerified,
        setTransactionId,
    };

    if (!profile?.user?.email) return <LoadingEffect />;
    return <ProfileContext.Provider value={share}>{children}</ProfileContext.Provider>;
};

ProfileContextProvider.propTypes = {
    children: PropTypes.oneOfType(
        [PropTypes.object, PropTypes.arrayOf(PropTypes.element)]).isRequired,
};

export default ProfileContextProvider;
