import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@rmwc/textfield';
import NumberValidator from '../../../components/Utils/NumberValidator';
import CurrencyIcon from '../../../res/Icons/Currency';

const CustomTextField = ({
                            value = '',
                            label = '',
                            type = 'text',
                            icon = '',
                            placeholder = '',
                            trailingIcon = '',
                            className = '',
                            step = 'any',
                            min,
                            disabled = false,
                            textarea = false,
                            required = false,
                            onChange,
                            onKeyPress,
                          }) => {
  return (
    <div className={className}>
      <TextField label={label} textarea={textarea} type={type} step={step}
                 placeholder={placeholder} value={value} min={min}
                 disabled={disabled} trailingIcon={trailingIcon}
                 icon={(icon === 'money')
                  ? {
                    strategy: 'component',
                    icon: (<CurrencyIcon />),
                    }
                  : icon}
                 onChange={onChange} onKeyPress={onKeyPress}
               />
      {(required) && <NumberValidator required={true} value={value}/>}
    </div>);
};

CustomTextField.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  icon: PropTypes.string,
  placeholder: PropTypes.string,
  trailingIcon: PropTypes.string,
  className: PropTypes.string,
  step: PropTypes.string,
  min: PropTypes.number,
  disabled: PropTypes.bool,
  textarea: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
};

export default CustomTextField;
