import React from 'react';

import DrawerWrapper from '../mdc/drawer/DrawerWrapper';
import Toolbar from '../mdc/toolbar/Toolbar';
import SiteBody from '../mdc/SiteBody';

const NotFound = () => {
  const redirectToHome = () => {
    setTimeout(() => {
      window.location.href = '/';
    }, 3000);
  };
  return (
    <>
      <DrawerWrapper/>
      <Toolbar/>
      <SiteBody>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '85vh',
        }}>
          <h1>Not Found</h1>
          <p>The page you are looking for does not exist.</p>
          <p>Going back to the Home</p>
          {redirectToHome()}
        </div>
      </SiteBody>
    </>
  );
};

export default NotFound;
