/* eslint-disable react/prop-types */
import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import UserApi from '../../../api/UserApi';
import { UserDataContext } from '../../../context/UserContext';
import { ProjectContext } from '../../../context/ProjectContext';
import UIResponseWallet from './UIResponseWallet';
import useWalletData from '../../../hooks/useWalletData';
import LoadingEffect from '../../loading/Loading';
import InternalError from './InternalError';

const ResponseWallet = () => {
    const useQuery = () => new URLSearchParams(useLocation().search);
    const {
      initialDataState,
      checkDataState,
      initialProjectState,
      checkProjectState,
    } = useWalletData();
    const [data, setData] = useState(initialDataState);
    const [project, setProject] = useState(initialProjectState);
    const [status, setStatus] = useState('loading');
    const { basicUserInformation = {} } = useContext(UserDataContext);
    const { user = {} } = basicUserInformation;
    const { projects = [] } = useContext(ProjectContext);

    const idTransaction = useQuery().get('id');
    const email = useQuery().get('email');

    useEffect(() => {
    const fetchData = async () => {
      const response = await UserApi.getTransactionByInvoice(idTransaction, email);
      setData(response.data);
      setStatus(response.status);
    };
    fetchData();
    // eslint-disable-next-line
    }, []);

    useEffect(() => {
      const aux = projects.filter(project => project.projectId === data.projectId);
      setProject({
        name: `${aux[0]?.project.projectName || ''}`,
        address: `${aux[0]?.project.address || ''}`,
        admin: `${aux[0]?.project.percentage.administration || ''}`,
        date1: `${aux[0]?.project.beginningDate || ''}`,
        date2: `${aux[0]?.project.beginningDate || ''}`,
      });
    // eslint-disable-next-line
    }, [projects.length > 0 && data.projectId !== '']);

  const pdfData = {
    reference: idTransaction,
    projectName: project?.name,
    userName: user.name,
    nit: user.nit,
    ubication: project?.address,
    amount: `${data?.amount} ${'COP'}`,
    panels: data?.panels,
    interes: '0.01',
    beginingDate: `${project?.date1}`,
    beginingDateOp: `${project?.date1}`,
    status: (status === 200) ? 'pendiente' : 'internal error',
    adminPercentage: `${project?.admin} %`,
  };

  if (status !== 'loading' && status !== 200) return <InternalError/>;
  if (checkDataState(data) && checkProjectState(project)) {
  return <UIResponseWallet idTransaction={idTransaction}
                           data={data} status={status} user={user}
                           project={project}
                           pdfData={pdfData} />;
  } else {
    return <LoadingEffect/>;
  }
};

export default ResponseWallet;
