import { api, escalateError, getResponseData } from './index';

export default class LandinPageAPI {
    static getQuestions(type) {
        return api.get(`/landin/question/${type}`)
            .then(getResponseData)
            .catch(escalateError);
    }

    static deleteQuestion(idQuestion, type) {
        return api.delete(`/landin/question/${type}/${idQuestion}`)
            .then(getResponseData)
            .catch(escalateError);
    }

    static createQuestion(data, type) {
        return api.post(`/landin/question/${type}`, { data })
            .then(getResponseData)
            .catch(escalateError);
    }

    static updatedQuestion(data, idQuestion, type) {
        return api.put(`/landin/question/${type}/${idQuestion}`, { data })
            .then(getResponseData)
            .catch(escalateError);
    }
}
