import React from 'react';
import { Dialog } from '@rmwc/dialog';
import { Button } from '@rmwc/button';
import stylesDialog from './dialog.module.css';
import { mdiCloseThick } from '@mdi/js';
import { IconButton } from '@rmwc/icon-button';
import Icon from '@mdi/react';

// eslint-disable-next-line react/prop-types
const DialogInformation = ({ description, value }) => {
  return (
    <>
      <div
        className={stylesDialog.content_dialog_two_information}
      >
        <small>{description}</small>
        <p>{value}</p>
      </div>
    </>
    );
};

// eslint-disable-next-line react/prop-types
const DialogOptions = ({ description }) => {
  return (
    <div
      className={stylesDialog.content_dialog_one_options}
    >
      <input type={'checkbox'}/>
      <p>{description}</p>
    </div>);
};

// eslint-disable-next-line react/prop-types
export const DialogPayment = ({ openModal, setOpenModal, setOpenModalConfirm }) => {
    return (
      <Dialog
        open={openModal}
        onClose={() => {
            setOpenModal(false);
        }}
      >
        <IconButton
          className={stylesDialog.dialog_exit}
          onClick={() => setOpenModal(false)}
        >
          <Icon path={mdiCloseThick}></Icon>
        </IconButton>
        <div className={stylesDialog.content_dialog_one}>
          <p>Cuánto quieres recargar</p>
          <input />
          <p>¿Cómo quieres recargar tu billetera?</p>
          <DialogOptions description={'Por PSE'}/>
          <DialogOptions description={'Transferencia Bancaria'}/>
          <DialogOptions description={'Nequi'}/>
          <DialogOptions description={'Daviplata'}/>
          <Button
            raised
            className={stylesDialog.dialog_two_button_two}
            onClick={() => {
                setOpenModal(false);
                setOpenModalConfirm(true);
            }}
          > Continuar</Button>
        </div>
      </Dialog>
    );
};

// eslint-disable-next-line react/prop-types
export const DialogPaymentConfirm = ({ openModalConfirm, setOpenModalConfirm, setOpenModal }) => {
    return (
      <Dialog
        open={openModalConfirm}
        onclose={() => {
            setOpenModalConfirm(false);
        }}>
        <IconButton
          className={stylesDialog.dialog_exit}
          onClick={() => setOpenModalConfirm(false)}
        >
          <Icon path={mdiCloseThick}></Icon>
        </IconButton>
        <div className={stylesDialog.content_dialog_two}>
          <p>Confirmacion</p>
          <DialogInformation description={'Monto'} value={'$ 850.000'}/>
          <DialogInformation description={'Costo de la transaccion'} value={'$ 0'}/>
          <DialogInformation description={'Total'} value={'$ 858.500'}/>

          <div className={stylesDialog.information_client}>
            <img
              src={'https://lh3.googleusercontent.com/yUG1c5r8TEHT0OTTwknXVjXfnPigYN_vqGoIk0D2YRjM7FYVAwoCPteewWeY8apORM7M'}
              alt={'pse_logo'} />
            <div className={stylesDialog.information_client_data}>
              <small>Correo Electronico *</small>
              <input/>
            </div>
          </div>

          <div className={stylesDialog.content_dialog_two_buttons}>
            <Button
                className={stylesDialog.dialog_two_button_one}
                onClick={() => {
                    setOpenModal(true);
                    setOpenModalConfirm(false);
                }}
            > Regresar</Button>
            <Button
                className={stylesDialog.dialog_two_button_two}
                raised
                onClick={() => {
                    setOpenModalConfirm(false);
            }}
            > Recargar</Button>
          </div>
        </div>
      </Dialog>
    );
};
