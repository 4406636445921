import { api } from './index';

export default class UserApi {
    static saveUserDocuments(documents, contentType = 'multipart/form-data') {
        return api.post('/user/documents', documents,
            { headers: { 'Content-Type': contentType } });
    }

    static saveBasicUserData(userData) {
        return api.post('/user', userData);
    }

    static walletTransaction(data) {
        return api.post('/user/recharge/', data);
    }

    static getBasicUserData(data) {
        return api.get(`/user?email=${data}`);
    }

    static getTransactionByInvoice(id, email) {
        return api.get(`/user/transaction?invoice=${id}&email=${email}`);
    }

    static getUserTransactions(userId, index, action, responseCode) {
        return api.post(`/user/transactions?email=${userId}`, {
            index,
            action,
            responseCode,
        });
    }

    static getSignedUrl(pathName) {
        const filePath = encodeURIComponent(pathName);
        return api.get(`/user/signedUrl?filePath=${filePath}`);
    }
}
