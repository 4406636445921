/* eslint-disable react/prop-types */
import React from 'react';
import Icon from '@mdi/react';
import { mdiWalletOutline } from '@mdi/js';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogButton
} from '@rmwc/dialog';
import { Link } from 'react-router-dom';

const ValidationWallet = ({ openWallet, setOpenWallet, action }) => {
    return (
      <Dialog open={openWallet}
              onClose={() => {
                setOpenWallet(false);
              }}>
        <DialogTitle>
          Confirma tu transacción
        </DialogTitle>
        <DialogContent style={{ maxWidth: '23rem' }}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Icon path={mdiWalletOutline} size={4}/>
          </div>
          haremos una transferencia del dinero proveniente de
          tu billetera virtual para realizar esta compra
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-around' }}>
          <DialogButton action={'close'}>
            Cancelar
          </DialogButton>
          <Link to={'/recharge'} style={{ textDecoration: 'none' }}>
            <DialogButton onClick={action}
                          unelevated>
              Realizar compra
            </DialogButton>
          </Link>
        </DialogActions>
      </Dialog>
    );
};

export default ValidationWallet;
