/* eslint-disable no-unused-vars */
import React, { useState, useContext } from 'react';
import { Button } from '@rmwc/button';
import { ProjectContext } from '../../context/ProjectContext';
import { UserDataContext } from '../../context/UserContext';

import Card from '../Cards/Cards';
import './project.css';
import { Link } from 'react-router-dom';
import useAnalytics from '../../hooks/useAnalytics';
import { Tab, TabBar } from '@rmwc/tabs';

const options = [
  { label: 'TODOS', value: 0 },
  { label: 'SIEMBRA', value: 1 },
  { label: 'CULTIVO', value: 2 },
  { label: 'COSECHA', value: 3 },
];

// eslint-disable-next-line react/prop-types
const ProjectOption = ({ projects, option, role, loading }) => {
  return (
    <div className={'type-of-project'}>
      {
        // eslint-disable-next-line
        (Array.isArray(projects) ? projects : []).map((project, pos) => {
          if (option === 0) {
            return <Card key={pos} projectData={project} loading={loading}></Card>;
          } else if (Number(project?.project.projectStatus) === option) {
            return <Card key={pos} projectData={project} loading={loading}></Card>;
          }
        })
      }
      {
        (Array.isArray(projects) ? projects : []).length === 0
        && (
          (<div className={'empty__projects__content'}>
            <h4>No hay proyectos activos</h4>
            {
              role === 'admin'
              && (
                <Link to={'/manage-projects'}>
                  Crear proyecto
                </Link>
              )
            }
          </div>)
        )
      }
    </div>
  );
};

const Projects = () => {
  const { projects = [], loading } = useContext(ProjectContext);
  const { basicUserInformation } = useContext(UserDataContext);
  const { role = '' } = basicUserInformation;
  const { trackEvent } = useAnalytics('projects');

  const [option, setOption] = useState(0);
  const [filterProjects, setFilterProjects] = useState([]);
  const [isFarm, setIsFarm] = useState(false);

  return (
    <div className={'project-container'}>
      <h3 style={{ textAlign: 'center' }}>Tienda</h3>
      <TabBar>
        <Tab onInteraction={() => {
          setFilterProjects(projects);
          setIsFarm(false);
        }} label={'Todos'} />
        <Tab onInteraction={() => {
          setFilterProjects(projects.filter((project) => !project.isCrowdfunding));
          setIsFarm(true);
        }} label={'Granjas'} />
        <Tab onInteraction={() => {
          setFilterProjects(projects.filter((project) => project.isCrowdfunding));
          setIsFarm(false);
        }} label={'Financiación'} />
      </TabBar>
      {
        isFarm
        && (<div className={'options'}>
          { options.map((optionData, key) =>
            <Button key={key}
                    className={optionData.value === option ? 'button-activate' : 'button'}
                    label={optionData.label}
                    onClick={() => {
                      trackEvent('click', optionData.label);
                      setOption(key);
                    }}
          />)}
        </div>)
      }
      <ProjectOption projects={filterProjects.length <= 0 ? projects : filterProjects}
                     option={option} role={role}
                     loading={loading} />
    </div>
  );
};

export default Projects;
