import React, { useEffect, useCallback } from 'react';
import DrawerWrapper from '../mdc/drawer/DrawerWrapper';
import Toolbar from '../mdc/toolbar/Toolbar';
import SiteBody from '../mdc/SiteBody';
import Contact from '../contact/contact';
import useAnalytics from '../../hooks/useAnalytics';

const ContactSite = () => {
  const { trackViewPage } = useAnalytics('contacto');
  const setEvent = useCallback(trackViewPage, []);
  useEffect(() => setEvent(), [setEvent]);
  return (<>
    <Toolbar/>
    <DrawerWrapper/>
    <SiteBody>
      <Contact/>
    </SiteBody>
  </>
  );
};

export default ContactSite;
