import React, { useEffect, useCallback } from 'react';
import DrawerWrapper from '../mdc/drawer/DrawerWrapper';
import Toolbar from '../mdc/toolbar/Toolbar';
import SiteBody from '../mdc/SiteBody';
import WithDrawMoney from '../withdrawMoney/withdrawMoney';
import ProfileContext from '../../context/ProfileContext';
import useAnalytics from '../../hooks/useAnalytics';

const WithDrawMoneySite = () => {
  const { trackViewPage } = useAnalytics('withDraw');
  const setEvent = useCallback(trackViewPage, []);
  useEffect(() => setEvent(), [setEvent]);
    return (
      <ProfileContext>
        <Toolbar/>
        <DrawerWrapper/>
        <SiteBody>
          <WithDrawMoney/>
        </SiteBody>
      </ProfileContext>
    );
};

export default WithDrawMoneySite;
