import React from 'react';
import PropTypes from 'prop-types';
import styles from '../payment.module.css';

export const BasicInformation = ({ firstText, SecondText }) => {
    return (
      <>
        <div >
          <div className={styles.basic_information}>
            <p>{firstText}</p>
            <p>{SecondText}</p>
          </div>
        </div>
      </>
    );
};

export const BasicInformationBase = ({
 firstText, SecondText, extra = false,
  bold, percentage = true,
}) => {
    return <>
      <div>
        <div className={`${styles.basic_information_base} 
        ${styles.basic_information}`}>
          {bold
            ? <p className={styles.basic_information_base_bold}>{firstText}</p>
            : <p>{firstText} </p>
        }
          <p className={extra ? styles.payment_extra : ''}>
            {SecondText}&nbsp;
            {percentage && <small>COP</small>}
          </p>
        </div>
      </div>
    </>;
};

BasicInformation.propTypes = {
    firstText: PropTypes.string.isRequired,
    SecondText: PropTypes.string.isRequired,
};

BasicInformationBase.propTypes = {
    firstText: PropTypes.string.isRequired,
    SecondText: PropTypes.string.isRequired,
    extra: PropTypes.string,
    bold: PropTypes.bool,
    percentage: PropTypes.bool,
};
