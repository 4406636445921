import React, { useEffect, useCallback } from 'react';
import DrawerWrapper from '../mdc/drawer/DrawerWrapper';
import Toolbar from '../mdc/toolbar/Toolbar';
import SiteBody from '../mdc/SiteBody';
import ActiveProjects from '../my-projects/MyProjects';
import ProjectContext from '../../context/ProjectContext';
import useAnalytics from '../../hooks/useAnalytics';

const MyProjects = () => {
  const { trackViewPage } = useAnalytics('myprojects');
  const setEvent = useCallback(trackViewPage, []);
  useEffect(() => setEvent(), [setEvent]);
    return <>
      <Toolbar/>
      <DrawerWrapper/>
      <SiteBody>
        <ProjectContext>
          <ActiveProjects/>
        </ProjectContext>
      </SiteBody>
    </>;
};

export default MyProjects;
