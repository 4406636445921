import React, { useContext, useState, useEffect, useMemo } from 'react';
import styles from './wallet.module.css';
import { Button } from '@rmwc/button';
import { Select } from '@rmwc/select';
import { Link } from 'react-router-dom';
import {
  DataTable,
  DataTableContent,
  DataTableHead,
  DataTableRow,
  DataTableHeadCell,
  DataTableBody,
  DataTableCell
} from '@rmwc/data-table';

import Utils from '../../utils/Utils';
import { ProjectContext } from '../../context/ProjectContext';
import { UserDataContext } from '../../context/UserContext';

const defaultProject = { label: 'Todo', value: 0 };

const options = [
  { label: 'Todo', value: -1 },
  { label: 'Recarga', value: 0 },
  { label: 'Compra', value: 1 },
  { label: 'Retiro', value: 10 },
];

const transactionOptions = {
  0: 'Recarga',
  1: 'Compra',
  10: 'Retiro',
};

// eslint-disable-next-line react/prop-types
const WalletTable = ({ transactions = [] }) => {
  return (
    <DataTable className={styles.table}>
      <DataTableContent>
        <DataTableHead>
          <DataTableRow>
            <DataTableHeadCell>Descripción</DataTableHeadCell>
            <DataTableHeadCell>Tipo</DataTableHeadCell>
            <DataTableHeadCell>Fecha</DataTableHeadCell>
            <DataTableHeadCell>Monto</DataTableHeadCell>
          </DataTableRow>
        </DataTableHead>
        <DataTableBody className={styles['table-body']}>
          {transactions.map((transaction, key) => {
            const { date = {}, creationDate = {}, transactionType } = transaction;
            const [dateInfo] = new Date(
              // eslint-disable-next-line no-underscore-dangle
              (date._seconds || creationDate._seconds) * 1000
            ).toISOString()
              .split('T');

            return (
              <DataTableRow className={styles['table-row']} key={key}>
                <DataTableCell>{transaction.description}</DataTableCell>
                <DataTableCell>
                  {transactionOptions[transactionType]}
                </DataTableCell>
                <DataTableCell>{dateInfo}</DataTableCell>
                <DataTableCell>{Utils.formatter.format(transaction.amount)}</DataTableCell>
              </DataTableRow>
            );
          })}
        </DataTableBody>
      </DataTableContent>
    </DataTable>
  );
};

const Wallet = () => {
  const { userTransactions, projectsUser, basicUserInformation, setTransaction, transactionPag }
    = useContext(UserDataContext);
  const { projects = [] } = useContext(ProjectContext);
  const [projectList, setProjectList] = useState([]);
  const [transactionList, setTransactionList] = useState([]);
  const [projSelect, setProjSelect] = useState('0');
  const [typeSelect, setTypeSelect] = useState('-1');
  const [earning, setEarning] = useState(0);
  const [capitalSuscription, setCapitalSuscription] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    setTransactionList(userTransactions.map((transaction) => transaction)
    .filter((transaction) => transaction.responseCode === '1'));
    setProjectList(projectsUser.map((project) => ({
      label: project.project.projectName,
      value: project.projectId,
    })));
  }, [projectsUser, userTransactions]);

  useEffect(() => {
    let transactionCopy = [...userTransactions.filter(
      (transaction) => transaction.responseCode === '1')];
    if (projSelect !== '0') {
      transactionCopy = userTransactions.filter((transaction) =>
        transaction.projectId === projSelect);
    }

    if (typeSelect !== '-1') {
      transactionCopy = transactionCopy.filter(
        (transaction) => transaction.transactionType === typeSelect);
    }
    setTransactionList(transactionCopy);
  }, [projSelect, typeSelect, userTransactions]);

  useMemo(() => {
    if (projectsUser.length <= 0 || projects.length <= 0) return;
    const subTotalRevenue = [];
    const bonification = [];
    const capital = [];
    for (const project of projectsUser) {
      const { earnings } = project;
      const flatArray = earnings.flat(Infinity);
      flatArray.forEach((item) => {
        bonification.push(item.bonification);
        capital.push(item.capitalSuscription);
        subTotalRevenue.push(item.total.reduce((acc, curr) => acc + curr, 0));
      });
    }

    const totalBonification = bonification.reduce((acc, curr) => acc + curr, 0);
    const totalCapital = capital.reduce((acc, curr) => acc + curr, 0);
    const totalRevenue = subTotalRevenue.reduce((acc, curr) => acc + curr, 0);
    setCapitalSuscription(totalCapital);
    setTotalRevenue(totalRevenue);
    setBalance(basicUserInformation.wallet.amount);
    setEarning(totalRevenue - totalBonification - totalCapital);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectsUser, projects, basicUserInformation?.wallet?.amount]);

  return (
    <>
      <div className={styles.container}>
        <h1>Billetera</h1>
        <hr />
        <div className={styles['button-container']}>
          <Link to={'/recharge'} className={styles['recharge-link']}>
            <Button
            className={styles.button}
            label={'Recargar billetera'}
            unelevated
            />
          </Link>
        </div>
        <div className={styles.content}>
          <secction className={styles['content-first']}>
            <div className={styles.balance}>
              <div>
                <p className={styles['row-tittle']}>Saldo disponible</p>
                <p>{`${Utils.formatter.format(balance.toFixed(0))} COP`}</p>
              </div>
              <Link className={styles.button} to={'/projects'}>
                Invertir
              </Link>
            </div>
            <div className={styles.row}>
              <p className={styles['row-tittle']}>Ganancias</p>
              <p>{Utils.formatter.format(earning.toFixed(0))} COP</p>
            </div>
            <div className={styles.row}>
              <p className={styles['row-tittle']}>Ingresos totales</p>
              <p>{Utils.formatter.format(totalRevenue.toFixed(0))} COP</p>
            </div>
            <div className={styles.row}>
              <p className={styles['row-tittle']}>Abono a capital</p>
              <p>{Utils.formatter.format(capitalSuscription.toFixed(0))} COP</p>
            </div>
          </secction>
          <secction className={styles['content-second']}>
            <h3>Últimos movimientos</h3>
            <div className={styles.option}>
              <div className={styles['option-one']}>
                <label>Tipo</label>
                <Select
                  className={styles.select}
                  options={options}
                  value={typeSelect}
                  onChange={({ target }) => setTypeSelect(target.value)}
                  outlined
                  />
              </div>
              <div className={styles['option-two']}>
                <label className={styles.label}>Proyecto</label>
                <Select className={styles.select}
                        value={projSelect}
                        onChange={({ target }) => setProjSelect(target.value)}
                        options={[defaultProject, ...projectList]}
                        outlined/>
              </div>
            </div>
            <WalletTable transactions={transactionList} />
            {
              transactionList.map((transaction, key) => {
                const { date = {}, creationDate = {}, transactionType } = transaction;
              const [dateInfo] = new Date(
                // eslint-disable-next-line no-underscore-dangle
                (date._seconds || creationDate._seconds) * 1000
              ).toISOString()
                .split('T');
              return (<div key={key} className={styles['phone-table']}>
                <div className={styles['first-column']}>
                  <p>Descripcion</p>
                  <p>Tipo</p>
                  <p>Fecha</p>
                  <p>Monto</p>
                </div>
                <div className={styles['second-column']}>
                  <p>{transaction.description}</p>
                  <p>{transactionOptions[transactionType]}</p>
                  <p>{dateInfo}</p>
                  <p className={styles.amount}>{Utils.formatter.format(transaction.amount)}</p>
                </div>
              </div>);
            })}
            <div className={styles['next-buttons']}>
              <Button
                label={'previous'}
                onClick={() => setTransaction('prev')}
                unelevated
                className={styles.button}
              />
              <p className={styles.counter}>{transactionPag}</p>
              <Button
                label={'next'}
                onClick={() => setTransaction('next')}
                unelevated
                className={styles.button}
              />
            </div>
          </secction>
        </div>
      </div>
    </>
  );
};

export default Wallet;
