/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from 'react';
import { TextField } from '@rmwc/textfield';
import { Button } from '@rmwc/button';
import { useParams, useHistory } from 'react-router-dom';

import ProjectApi from '../../../../../api/ProjectApi';
import { UserDataContext } from '../../../../../context/UserContext';

import { ProjectContext } from '../../../../../context/ProjectContext';

import styles from './progress-content.module.css';
import Utils from '../../../../../utils/Utils';
import DateCountdown from 'react-date-countdown-timer';

const ProgressContent = ({ infoDiscount, dataFormProject }) => {
  const { projects = [], panels = 0, setPanels, discount } = useContext(ProjectContext);
  const { projectId = '' } = useParams();

  const [myProjects = [], setMyProjects] = useState([]);

  const { user } = useContext(UserDataContext);
  const discountValue = discount?.sort();

  const [auxProjectData = {}] = projects.filter((project) => project.projectId === projectId);
  const [myProjectsData = {}] = myProjects.filter((project) => project.projectId === projectId);

  // eslint-disable-next-line no-unused-vars
  const { project = {} } = auxProjectData;
  const availablePanels = project.noOfDigitalPanels - (project.numberOfPanels || 0);
  const history = useHistory();

  const maxPanels = Math.ceil(project?.noOfDigitalPanels * 0.44);

  useEffect(() => {
    if (!user) return;
    ProjectApi.getProjectsByUser(user.email).then((resp) => setMyProjects(resp));
  // eslint-disable-next-line
  }, []);

  useEffect(() => {}, [panels]);

  return <>
    <div className={styles['progress-container']}>
      <div className={styles.sticky}>
        <h5>Paneles disponibles: {availablePanels}/{project.noOfDigitalPanels}</h5>
        <hr/>
        <h4 className={styles['porcentual-discount']}>{dataFormProject?.tirFarmer ? (dataFormProject?.tirFarmer * 100).toFixed(2) : 0}%</h4>
        <p className={styles['anual-renta']}>Rentabilidad estimada anual</p>
        <hr/>
        {
          !project.isCrowdfunding
          && (<>
            <h4>precio por panel en preventa</h4>
            <p className={styles['porcentual-discount_p']}>
              <span>-{parseFloat(infoDiscount.percentageDiscount) || 0}%</span>
              &nbsp;&nbsp;{Utils.formatterEmpty.format(Math.ceil(infoDiscount.discount))}&nbsp;COP
            </p>
            <p className={styles['prevent-discount']}>Descuentos en preventa</p>
            <p className={styles['discount-range']}>
              Desde:   <span>{discountValue[0] || 0 }%</span>&nbsp;
              Hasta:  <span>{discountValue[1] || 0}%</span></p>
            { Date.parse(new Date()) < Date.parse(new Date(project?.beginningDateHarvest))
            && <h4 className={styles['discount-date']}>Oferta valida hasta
              <span>
                <DateCountdown
                dateTo={new Date(project?.beginningDateHarvest).toISOString()}
                locales={['Año', 'Mes', 'Día', 'h', 'm', 's']}
                locales_plural={['Años', 'Mes', 'Días', 'h', 'm', 's']}
                />
              </span>
            </h4>
            }
            <hr/>
          </>)
        }
        <div className={styles['panel-flex']}>
          <p>Número de paneles</p>
          <TextField
            disabled={availablePanels <= 0} type={'number'}
            className={styles['text-field']} outlined value={panels}
            onChange={e =>
              (e.target.value <= maxPanels)
              ? setPanels(parseInt(e.target.value) || 0)
              : panels
            } min={0}
            onKeyPress={Utils.preventTextInNumberInput}
          />
        </div>
        <p className={styles['buy-range']}>
          Puedes comprar: Mínimo 5 - Máximo {(maxPanels || (project.totalCost - project.raisedMoney)).toFixed(0)}
        </p>
        <h4 className={styles['interactive-panel']}>
          { `${Utils.formatterEmpty.format(Math.round(parseFloat(dataFormProject?.farmerInvestment || project.panelCost)))} COP` }
        </h4>
        <Button raised label={'participar'}
                className={styles.button}
                style={{ display: myProjectsData.projectId === auxProjectData.projectId ? 'None' : '' }}
                onClick={() => history.push(`/payment/${projectId}?panels=${panels}`)}/>
      </div>
    </div>
  </>;
};

export default ProgressContent;
