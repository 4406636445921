/* eslint-disable max-len */
import React from 'react';
import './Load.css';

const Load = () => {
    return (
      <div style={{ width: '24px', height: '24px' }} className={'rotate'}>
        <svg style={{ width: '24px', height: '24px' }} viewBox={'0 0 24 24'}>
          <path fill={'currentColor'} d={'M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z'} />
        </svg>
      </div>
    );
};

export default Load;
