const { irr } = require('node-irr');

const calculateValuesGraphic = (obj) => {
  const {
    potenciaInstalada: power = 0, radiacionSolarMensual: solarRadiation = {},
    eficienciaSistema: sysEffic = 0, precioVentaEnergia: enerSalPric = 0,
    porcentajeAsignaciondeDinero: monAlloPerc = {},
    incrementoTarifaAnual: annFeeInc = 0,
    tasaInflacionAnual: annInfRat = 0,
    cantidadPanelesDisponibles: numPanAval = 0,
    cantidadPanelesParcela: numPlotPan = 0,
    costoProyecto: projectCost = 0,
    precioPanel: panelPrice = 0,
    bonificacionCantidadPaneles: bonNumPanls = {},
    descuentoPreventa: presDiscount = {},
    inicioSiembra: sowingStart = '',
    amortization = {},
  } = obj;

  // step 1
  const solarRadiationValues = Object.values(solarRadiation);
  const producedEnergy = solarRadiationValues.map((element, idx) => {
    const value = (power * Number(element)
      * new Date(new Date().getFullYear(), (idx + 1), 0).getDate()) * sysEffic;
    return value;
  });

  // step 2
  const saleEnergyProduced = producedEnergy.map((element) => element * enerSalPric);

  // step 3
  const moneyAllocationValues = Object.values(monAlloPerc);
  const moneyCollected = [];
  for (let i = 0; i < saleEnergyProduced.length; i++) {
    const obj = [
      saleEnergyProduced[i] * moneyAllocationValues[0] / 100,
      saleEnergyProduced[i] * moneyAllocationValues[1] / 100,
      saleEnergyProduced[i] * moneyAllocationValues[2] / 100,
    ];
    moneyCollected.push(obj);
  }

  const moneyCollectedTotal = [];
  for (let i = 0; i < moneyCollected.length; i++) {
    moneyCollectedTotal[0] = (moneyCollectedTotal[0] || 0) + moneyCollected[i][0];
    moneyCollectedTotal[1] = (moneyCollectedTotal[1] || 0) + moneyCollected[i][1];
    moneyCollectedTotal[2] = (moneyCollectedTotal[2] || 0) + moneyCollected[i][2];
  }

  // step 4
  const totalColumn = (n, isCheck = false) => {
    const value = isCheck
      ? Object.values(n).reduce((acc, act) => acc + act, 0)
      : n.reduce((acc, act) => acc + act, 0);
    return value;
  };

  const annualProjection = [];
  for (let i = 0; i < 20; i++) {
    if (!i) {
      annualProjection.push({
        farmers: moneyCollectedTotal[0],
        maintFund: moneyCollectedTotal[1],
        admin: moneyCollectedTotal[2],
        total: totalColumn(moneyCollectedTotal),
      });
      continue;
    }
    const info = {
      farmers: annualProjection[i - 1].farmers * (1 + (annFeeInc)),
      maintFund: annualProjection[i - 1].maintFund * (1 + (annFeeInc)),
      admin: annualProjection[i - 1].admin * (1 + (annFeeInc)),
    };
    annualProjection.push({
      ...info,
      total: totalColumn(info, true),
    });
  }

  // step 5
  const netPresentValue = annualProjection.map((element, idx) => {
    const farmers = (element.farmers) / Math.pow((1 + (annInfRat / 100)), (idx + 1));
    const maintFund = (element.maintFund) / Math.pow((1 + (annInfRat / 100)), (idx + 1));
    const admin = (element.admin) / Math.pow((1 + (annInfRat / 100)), (idx + 1));
    const obj = {
      farmers,
      maintFund,
      admin,
    };
    return {
      ...obj,
      total: totalColumn(obj, true),
    };
  });

  const calsVANfarm = netPresentValue.map(x => x.farmers);
  const calsVANmainF = netPresentValue.map(x => x.maintFund);
  const calsVANadmin = netPresentValue.map(x => x.admin);
  const calsVANtot = netPresentValue.map(x => x.total);
  const calcVAN = [{
    farmers: totalColumn([-projectCost, ...calsVANfarm]),
    maintFund: totalColumn(calsVANmainF),
    admin: totalColumn(calsVANadmin),
    total: totalColumn([-projectCost, ...calsVANtot]),
  }];

  // step 6
  const netPresentValueFarmers = netPresentValue.map(element => element.farmers);
  const netPresentValueTotal = netPresentValue.map(element => element.total);
  const calcTIR = {
    farmers: irr([-projectCost, ...netPresentValueFarmers]),
    total: irr([-projectCost, ...netPresentValueTotal]),
  };

  // step 7
  const totalPanelParticipation = ((numPlotPan) / numPanAval);
  let discountCurrentAmount = 0;
  const dataBonusPanls = Object.values(bonNumPanls);
  const dataBonusPanlsKey = Object.keys(bonNumPanls);
  for (let i = 0; i < dataBonusPanls.length; i++) {
    const ranges = dataBonusPanlsKey[i].split('-');
    if (totalPanelParticipation >= (ranges[0] / 100)
      && totalPanelParticipation <= (ranges[1] / 100)) {
      discountCurrentAmount = dataBonusPanls[i];
      break;
    }
  }

  const weeksBetween = (date1, date2) => {
    let diff = (date2.getTime() - date1.getTime()) / 1000;
    diff /= (60 * 60 * 24 * 7);
    return Math.abs(Math.round(diff));
  };

  const value = weeksBetween(new Date(), new Date(sowingStart));
  const valuesPreDiscount = Object.values(presDiscount);
  const validateNumber = !value ? 0 : value - 1;
  const valueCurrentPresaleDiscount = value > valuesPreDiscount.length
    ? valuesPreDiscount[value.length - 1]
    : valuesPreDiscount[validateNumber];

  const investmentSimulator = {
    currentPresaleDiscount: valueCurrentPresaleDiscount,
    totalPanelParticipation,
    discountCurrentAmount,
  };

  const farmerInvestment = numPlotPan * panelPrice
    * (1 - investmentSimulator.currentPresaleDiscount / 100);

  const dataSimulator = [];
  const amortizationValues = Object.values(amortization);
  for (let i = 0; i < annualProjection.length; i++) {
    const obj = {
      cashFlow: annualProjection[i].farmers * investmentSimulator.totalPanelParticipation
        * (1 + Number(investmentSimulator.discountCurrentAmount / 100)),
      amortization: Number(amortizationValues[i]) / 100,
    };
    const obj2 = {
      ...obj,
      bonus: obj.cashFlow * (investmentSimulator.discountCurrentAmount / 100),
    };
    const obj3 = {
      ...obj2,
      capitalPayment: obj.amortization * obj.cashFlow - obj2.bonus,
    };
    dataSimulator.push({
      ...obj3,
      netIncome: obj.cashFlow - obj2.bonus - obj3.capitalPayment,
    });
  }

  const netRealValue = dataSimulator.map(element => element.netIncome);
  const totalCapitalPayment = totalColumn(dataSimulator.map(x => x.capitalPayment));
  const totalNetIncome = totalColumn(dataSimulator.map(x => x.netIncome));
  const totalBonus = totalColumn(dataSimulator.map(x => x.bonus));
  const totalCashFlow = totalColumn(dataSimulator.map(x => x.cashFlow));

  const simulatorInfo = {
    vanFarmer: -farmerInvestment + totalColumn(netRealValue),
    tirFarmer: irr([-farmerInvestment, ...netRealValue]),
  };

  return {
    dataSimulator, // informacion simulador
    TIR: calcTIR,
    tirFarmer: simulatorInfo.tirFarmer,
    estimatedEnergyProduced: producedEnergy,
    saleEnergyProduced,
    currentPresaleDiscount: valueCurrentPresaleDiscount,
    totalPanelParticipation,
    salesPercentageAllocation: {
      moneyCollected,
      moneyCollectedTotal,
    },
    annualProjection,
    VAN: calcVAN,
    discountCurrentAmount,
    totalCapitalPayment,
    totalNetIncome,
    totalBonus,
    totalCashFlow,
    farmerInvestment,
  };
};

export default calculateValuesGraphic;
