import React, { useState, useEffect } from 'react';
import styles from '../description.module.css';
import Icon from '@mdi/react';
import PropTypes from 'prop-types';
import { LinearProgress } from '@rmwc/linear-progress';
import { mdiImageArea } from '@mdi/js';
import Logo from '../../../../../res/images/logo1.png';

const DescriptionSection = ({ project = {}, projectId = '' }) => {
    const { mainPicture = [], projectData = {}, project: auxProjectData = {} } = project;
    const projectStatusOptions = ['Siembra', 'Cultivo', 'Cosecha'];

    const [imgData = {}] = mainPicture;
    const [progress, setProgress] = useState(0);
    const [isCrowdfunding, setIsCrowdfunding] = useState(false);

    useEffect(() => {
      const { project: selectedProject, raisedMoney } = project;
      const {
        totalCost = 0, raisedMoney: internalRaised = 0,
        isCrowdfunding,
      } = selectedProject || {};
      const totalPercentage = ((raisedMoney || internalRaised) * 100) / (totalCost);
      setProgress(totalPercentage.toFixed(2));
      setIsCrowdfunding(isCrowdfunding);
    }, [project]);

      return (
        <div className={styles['description-section']}>
          <h3>{auxProjectData.projectName}</h3>
          <div className={styles['basic-description']}>
            <h4>Estado actual: {projectStatusOptions[auxProjectData.projectStatus - 1]}</h4>
            { progress >= 0
              && <LinearProgress className={styles['progress-bar']}
                                 progress={progress / 100}
                                 buffer={1}/>}
            <p>{progress}% financiado</p>
          </div>
          {(mainPicture.length > 0)
          ? <img alt={'desPicture'}
                 style={{ width: '100%', maxHeight: '100%', objectFit: 'cover' }}
                 src={(projectId && !imgData?.name)
                  ? mainPicture[0]
                  : URL.createObjectURL(mainPicture[0])}/>
          : !isCrowdfunding
            ? <Icon path={mdiImageArea} color={'gray'} size={'20rem'}/>
            : <img src={Logo} alt={'crowdfunding'} />
          }
          <p style={{ textAlign: 'justify' }}>
            {(projectData?.text || auxProjectData?.text
          || `Sé parte de la primera pola solar de Colombia.
          La microcervecería Pola del Pub se une a la
          transformación energética y tú puedes ser dueño de una parte del proyecto de energía solar
          de esta, la primera cervecería colombiana en producir con energía limpia.`)
          }
          </p>
          <hr/>
        </div>
      );
};

DescriptionSection.propTypes = {
    project: PropTypes.object.isRequired,
    projectId: PropTypes.string,
};

export default DescriptionSection;
