import { api } from './index';

export default class UserApi {
    static sendBasicRequest(data) {
       return api.post('/ipsidy/qr', data);
    }

    static get2FAuthentication({ email }, newVerification) {
        return api.get(`/ipsidy/generateOtp?email=${email}&again=${newVerification}`)
        .then((resp) => resp.data);
    }

    static validate2FAuthentication({ token, email, nit }) {
        return api.get(`/ipsidy/verify2fa?email=${email}&nit=${nit}`,
            { headers: { Authorization: token } })
        .then((resp) => resp.data);
    }
}
