/* eslint-disable react/prop-types */
import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@rmwc/button';
import { Card } from '@rmwc/card';
import { SimpleDialog } from '@rmwc/dialog';
import { MenuItem, SimpleMenuSurface } from '@rmwc/menu';
import Utils from '../../utils/Utils';
import Logo from '../../res/images/logo1.png';
import {
  mdiCogOutline,
  mdiPencil,
  mdiSolarPower,
  mdiTrashCanOutline,
  mdiCalendarBlankOutline,
  mdiCurrencyUsd,
  mdiAccountCashOutline
} from '@mdi/js';
import Icon from '@mdi/react';
import { UserDataContext } from '../../context/UserContext';

import ProjectApi from '../../api/ProjectApi';
import CrowdfundingApi from '../../api/CrowdfundingApi';

import styles from './card.module.css';
import { useHistory } from 'react-router-dom';

const CardShimmer = () => (
  <Card className={styles['card-container']}>
    <div className={styles.loading}></div>
    <div className={styles['loading-tittle']}></div>
    <>
      <div className={styles['loading-tittle-bar']}></div>
      <div className={styles['loading-tittle-bar']}></div>
    </>
    <div className={styles['percentage-bar']}></div>
    <hr/>
    <>
      <div className={styles['loading-annual-rent']}></div>
      <div className={styles['loading-annual-label']}></div>
    </>
    <hr/>
    <div className={styles.text3Cards}>
      <>
        <div className={styles['loading-status']}></div>
        <div className={styles['loading-status']}></div>
        <div className={styles['loading-status']}></div>
      </>
    </div>
    <div className={styles['loading-price']}></div>
    <Button className={styles.button} label={'Invertir'}
            disabled />
  </Card>
);

const Cards = ({ projectData = {}, isMyProject, loading = true }) => {
  const { project = {}, mainPicture = [], projectId, raisedMoney } = projectData;
  const [openDialog, setOpenDialog] = useState(false);
  const history = useHistory();

  const { basicUserInformation } = useContext(UserDataContext);
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
  });

  const getRaisedMoney = (project) => {
    const { totalCost = 1, raisedMoney: internalRaised } = project;
    const totalPercentage = ((raisedMoney || internalRaised) * 100) / (totalCost);
    return totalPercentage.toFixed(2);
  };

  const beginningDate = new Date(project.beginningDate).toLocaleDateString('US');
    return <>
      {
        loading
        ? <CardShimmer />
        : (<>
          <Card className={styles['card-container']}>
            <img
              className={styles.img}
              alt={'imagen de proyecto energy farm'}
              src={mainPicture[0] || Logo}/>
            { basicUserInformation?.role === 'admin'
              ? <div className={styles.settings}>
                <SimpleMenuSurface handle={<Icon path={mdiCogOutline}
                                                 size={'3rem'} color={'green'}/>}>
                  <MenuItem style={{ margin: 'auto' }}>
                    <Icon path={mdiPencil} style={{ margin: 'auto' }}
                          size={'2rem'} color={'green'}
                          onClick={() => {
                            const route = project?.isCrowdfunding
                              ? 'crowdfunding-projects'
                              : 'manage-projects';
                            history.push(`/${route}/${projectId}`);
                                }}/>
                  </MenuItem>
                  {
                    !project?.isCrowdfunding
                    && (<MenuItem style={{ margin: 'auto' }}>
                      <Icon path={mdiSolarPower} style={{ margin: 'auto' }}
                            size={'2rem'} color={'orange'}
                            onClick={() => history.push(`/projects-records/${projectId}`)}/>
                    </MenuItem>)
                  }
                  <MenuItem style={{ margin: 'auto' }}>
                    <Icon style={{ margin: 'auto' }} path={mdiTrashCanOutline}
                          size={'2rem'} color={'red'} onClick={() => setOpenDialog(true)}/>
                  </MenuItem>
                </SimpleMenuSurface>
              </div>
            : <></>}
            <div className={styles.top__content}>
              <h1 style={{ fontSize: 'calc(1em + 0.7vw)' }}>{project.projectName}</h1>
              <p>Estado actual: <span className={styles.status}>Financiación</span></p>
            </div>
            <div className={styles.secondCards}>
              <p className={styles.p} style={{ width: `${getRaisedMoney(project)}%` }}></p>
              <p className={styles.p} style={{ width: `${100 - getRaisedMoney(project)}%` }}></p>
            </div>
            <p className={styles['raised-money']}>{getRaisedMoney(project)} % financiado</p>

            <hr/>
            <div className={styles.top__content}>
              <h2 style={{ fontSize: 'calc(1em + 0.7vw)', margin: '0.7rem' }}>
                <p>{parseFloat(project.annualRest * 100).toFixed(2)}%</p>
              </h2>
              <p className={styles.profitability}>Rentabilidad estimada anual</p>
            </div>
            <hr/>
            <div className={styles.text3Cards}>
              <div>
                <p>
                  <Icon className={styles.icon} path={mdiCalendarBlankOutline} size={1}/>
                  <span>Inicio programado:</span> {`${beginningDate}`}
                </p>
                <p>
                  <Icon className={styles.icon} path={mdiCurrencyUsd} size={1}/>
                  <span>Inversión total: </span> {`${formatter.format(project.totalCost)} COP`}
                </p>
                { (projectData.amount)
                  && <p>
                    <Icon className={styles.icon} path={mdiAccountCashOutline} size={1}/>
                    <span>Mi inversión: </span>
                    {`${formatter.format(projectData?.amount || 0)} COP`}
                  </p>
                }
              </div>
            </div>
            <h2 style={{ marginTop: '1rem' }}>
              Precio por {!project.isCrowdfunding ? 'panel' : 'unidad'}:
            </h2>
            <p className={styles['panel-cost']}>
              {`${Utils.formatterEmpty.format(project.panelCost)} COP`}
            </p>
            <Button className={styles.button} label={'Invertir'}
                    onClick={() => history.push(`/description/${projectId}`)}
                    style={{ display: isMyProject ? 'none' : '' }} raised/>
          </Card>
          <SimpleDialog
          style={{ textAlign: 'center' }}
          title={'¿Está seguro de eliminar el proyecto?'}
          body={`Recuerde que el proyecto ${project.projectName} sera eliminado del sistema`}
          open={openDialog}
          onClose={async (evt) => {
              if (evt.detail.action === 'accept') {
                if (project.isCrowdfunding) {
                  await CrowdfundingApi.deleteCrowdfundingProjectByProjectId(projectId);
                } else {
                  await ProjectApi.deleteProjectByProjectId(projectId);
                }
              }
              setOpenDialog(false);
              window.location.reload();
            }}
          />
        </>)
      }
    </>;
};

Cards.propTypes = {
  projectData: PropTypes.object,
  isMyProject: PropTypes.bool,
};

export default Cards;
