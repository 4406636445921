import React from 'react';
import Toolbar from '../mdc/toolbar/Toolbar';
import SiteBody from '../mdc/SiteBody';
import DrawerWrapper from '../mdc/drawer/DrawerWrapper';
import ProjectDescription from '../projects/Project-Description/Project-Description';
import ProjectContext from '../../context/ProjectContext';

const Description = () => {
        return <>
          <Toolbar/>
          <DrawerWrapper/>
          <SiteBody>
            <ProjectContext>
              <ProjectDescription/>
            </ProjectContext>
          </SiteBody>
        </>;
};

export default Description;
