/* eslint-disable react/prop-types */
import React from 'react';
import styles from '../manage-project.module.css';
import { TextField } from '@rmwc/textfield';
import NumberValidator from '../../../../Utils/NumberValidator';

const DiscountField = ({
  disabled, label, type, textarea,
  required, dispatchType, placeholder,
  dispatchParam, data = {}, dispatch,
}) => {
  const projectData = {};
  return <div className={styles.custom_text_field}>
    <TextField label={label} textarea={textarea} type={(type === 'date' ? 'date' : '')}
               placeholder={placeholder} value={data[`${dispatchParam}`]}
               disabled={disabled}
               trailingIcon={'percent'}
               onChange={(e) => {
                 data[`${dispatchParam}`] = (e.target.value);
                 dispatch({ type: dispatchType, project: { projectData } });
               }}/>
    {(required) ? <NumberValidator required={true} value={data[`${dispatchParam}`]}/> : <></>}
  </div>;
};

const Bonification = ({ data, createProjectDispatch }) => {
  return (
    <div className={styles['section-container']}>
      <div style={{ display: 'flex', justifyContent: 'center', columnGap: '1rem' }}>
        <div style={{ width: '100%' }}>
          <DiscountField label={'0-4%'} dispatchType={'UPDATE_PROJECT'}
                         dispatchParam={'0-4'} data={data} value={'como'}
                         dispatch={createProjectDispatch} placeholder={''}/>
        </div>
        <div style={{ width: '100%' }}>
          <DiscountField label={'4-8%'} dispatchType={'UPDATE_PROJECT'}
                         dispatchParam={'4-8'} data={data}
                         dispatch={createProjectDispatch} placeholder={''}/>
        </div>
        <div style={{ width: '100%' }}>
          <DiscountField label={'8-12%'} dispatchType={'UPDATE_PROJECT'}
                         dispatchParam={'8-12'} data={data}
                         dispatch={createProjectDispatch} placeholder={''}/>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', columnGap: '1rem' }}>
        <div style={{ width: '100%' }}>
          <DiscountField label={'12-16%'} dispatchType={'UPDATE_PROJECT'}
                         dispatchParam={'12-16'} data={data} value={''}
                         dispatch={createProjectDispatch} placeholder={''}/>
        </div>
        <div style={{ width: '100%' }}>
          <DiscountField label={'16-20%'} dispatchType={'UPDATE_PROJECT'}
                         dispatchParam={'16-20'} data={data}
                         dispatch={createProjectDispatch} placeholder={''}/>
        </div>
        <div style={{ width: '100%' }}>
          <DiscountField label={'20-24%'} dispatchType={'UPDATE_PROJECT'}
                         dispatchParam={'20-24'} data={data}
                         dispatch={createProjectDispatch} placeholder={''}/>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', columnGap: '1rem' }}>
        <div style={{ width: '100%' }}>
          <DiscountField label={'24-28%'} dispatchType={'UPDATE_PROJECT'}
                         dispatchParam={'24-28'} data={data} value={''}
                         dispatch={createProjectDispatch} placeholder={''}/>
        </div>
        <div style={{ width: '100%' }}>
          <DiscountField label={'28-32%'} dispatchType={'UPDATE_PROJECT'}
                         dispatchParam={'28-32'} data={data}
                         dispatch={createProjectDispatch} placeholder={''}/>
        </div>
        <div style={{ width: '100%' }}>
          <DiscountField label={'32-36%'} dispatchType={'UPDATE_PROJECT'}
                         dispatchParam={'32-36'} data={data}
                         dispatch={createProjectDispatch} placeholder={''}/>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', columnGap: '1rem' }}>
        <div style={{ width: '100%' }}>
          <DiscountField label={'36-40%'} dispatchType={'UPDATE_PROJECT'}
                         dispatchParam={'36-40'} data={data} value={''}
                         dispatch={createProjectDispatch} placeholder={''}/>
        </div>
        <div style={{ width: '100%' }}>
          <DiscountField label={'40-44%'} dispatchType={'UPDATE_PROJECT'}
                         dispatchParam={'40-44'} data={data}
                         dispatch={createProjectDispatch} placeholder={''}/>
        </div>
      </div>
    </div>
  );
};

export default Bonification;
