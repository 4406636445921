import React from 'react';
import PropTypes from 'prop-types';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { Icon } from 'leaflet';

const icon = new Icon({
  iconUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d1/Google_Maps_pin.svg/1200px-Google_Maps_pin.svg.png',
  iconSize: [25, 30],
});

const Maps = ({ projects, width, height }) => {
  return (
    <MapContainer center={[4.60971, -74.08175]} zoom={4} scrollWheelZoom={true}
                  style={{
                    width: width || '90%',
                    height: height || '50vh',
                    margin: 'auto',
                    zIndex: '0',
                  }}>
      <TileLayer
      attribution={'&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'}
      url={'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'}
    />
      {(projects || []).map((projectData = {}, pos) => {
      const { project = {} } = projectData;
      return (
        <Marker key={pos} position={[project?.location?.lat, project?.location?.lng]} icon={icon}>
          <Popup>
            {project?.address || project?.location?.address}
          </Popup>
        </Marker>
      );
    })}
    </MapContainer>
  );
};

Maps.propTypes = {
  projects: PropTypes.array,
  width: PropTypes.string,
  height: PropTypes.string,
};
export default Maps;
