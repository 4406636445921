import React, { useState } from 'react';
import {
  DataTable, DataTableContent, DataTableHead,
  DataTableHeadCell, DataTableBody, DataTableRow,
  DataTableCell
} from '@rmwc/data-table';
import PropTypes from 'prop-types';
import styles from './ReadingsTable.module.css';

const labelsData = {
  activeProduced: 'Energía activa Producida',
  activeImported: 'Energía activa Importada',
  activeExported: 'Energía activa Exportada',
  activeSold: 'Energía activa Vendida',
  reactiveImported: 'Energía reactiva Importada',
  reactiveExported: 'Energía reactiva Exportada',
  reactiveSold: 'Energía reactiva Vendida',
  activeCost: 'Costo de energía activa',
  reactiveCost: 'Costo de energía reactiva',
};

const ReadingsTable = ({ labels, projectData = {}, onClick = () => {} }) => {
  const [selectedRow, setSelectedRow] = useState(null);
  return (
    <div>
      <h1>Registros</h1>
      <DataTable>
        <DataTableContent>
          <DataTableHead>
            <DataTableRow>
              <DataTableHeadCell>Métrica</DataTableHeadCell>
              {
                labels
                && labels.map((label, index) => {
                  if (index === 4) return null;
                  return (
                    <DataTableHeadCell key={index} sort={null}
                                       className={index === selectedRow ? styles.selected : ''}
                                       onSortChange={() => {
                                        setSelectedRow(index);
                                        onClick(index);
                                       }}>
                      <div className={styles.header__table}>
                        <p>Semana {index + 1}</p>
                        <span>
                          {`${(new Date(label[0])).toLocaleDateString()} -
                          ${(new Date(label[1])).toLocaleDateString()}`}
                        </span>
                      </div>
                    </DataTableHeadCell>);
                })
              }
            </DataTableRow>
          </DataTableHead>
          <DataTableBody>
            {
              projectData
              ? (Object.entries(projectData) || []).map(([label, value], key) => (
                <DataTableRow key={key}>
                  {
                    (label !== 'lastUpdate' && label !== 'dates'
                    && label !== 'year' && label !== 'registryId')
                    && <DataTableCell>{labelsData[label]}</DataTableCell>
                  }
                  {
                    (label !== 'lastUpdate' && label !== 'dates' && label !== 'month'
                    && label !== 'year' && label !== 'registryId')
                    && (
                      value.map((item, index) => (
                        <DataTableCell key={index} alignMiddle>{item || '-'}</DataTableCell>
                      ))
                    )
                  }
                </DataTableRow>))
              : (<DataTableRow alignMiddle> Sin resultados </DataTableRow>)
            }
          </DataTableBody>
        </DataTableContent>
      </DataTable>
    </div>
  );
};

ReadingsTable.propTypes = {
  projectData: PropTypes.object.isRequired,
  labels: PropTypes.arrayOf(PropTypes.string),
  onClick: PropTypes.func,
};

export default ReadingsTable;
