import React, { useContext } from 'react';
import Card from '../../../Cards/Cards';
import Slider from 'react-slick';
import { ProjectContext } from '../../../../context/ProjectContext';
import styles from './projectSlice.module.css';

// eslint-disable-next-line react/prop-types
const ProjectSlice = () => {
  const settings = [
    {
      breakpoint: 1366,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 1.5,
        slidesToScroll: 1,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 540,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ];
  const { projects = [], loading } = useContext(ProjectContext);

  const numberOfSlides = projects.length >= 4 ? 4 : projects.length;
  const numberOfProjects = projects.length >= 4 ? 8 : projects.length;

  const extractProjects = projects.slice(0, numberOfProjects);
  return (
    <div className={styles['slider-container']}>
      <Slider
        slidesToShow={numberOfSlides}
        dots
        responsive={settings}
        centerMode={true}
        centerPadding={'0px'}
        infinite={true}
        focusOnSelect={true}
        className={styles.slider}
      >
        {(Array.isArray(extractProjects) ? extractProjects : []).map((project, pos) => (
          <Card key={pos} projectData={project} loading={loading} />
         ))}
      </Slider>
    </div>
  );
};

export default ProjectSlice;
