import React, { createContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { auth } from '../firebase';
import PropTypes from 'prop-types';
import UserApi from '../api/UserApi';
import ProjectApi from '../api/ProjectApi';

export const UserDataContext = createContext('user-data');

const UserData = ({ children }) => {
  const history = useHistory();

  const [user, setUser] = useState(auth ? auth.currentUser || null : null);
  // const [showChild, setShowChild] = useState(false);

  // Get basic user information
  const [basicUserInformation, setBasicUserInformation] = useState({});

  // Get all transactions by user
  const [userTransactions, setUserTransaction] = useState([]);

  // transaction flag to activate and allow to the system retrieve transactions by user
  const [getTransaction, setTransaction] = useState('');

  const [projectsUser, setProjectsUser] = useState([]);

  // transaction page
  const [transactionPag, setTransactionPag] = useState(0);

  const share = {
    basicUserInformation,
    userTransactions,
    setTransaction,
    transactionPag,
    setTransactionPag,
    projectsUser,
    user,
  };

  auth.onAuthStateChanged(setUser);

  useEffect(() => {
    if (auth?.currentUser) {
      auth.onAuthStateChanged(function (user) {
        setUser(user);
        // setShowChild(!!(user));
        if (!user) history.push('/');
      });
    }
    // eslint-disable-next-line
  }, [auth?.currentUser]);

  useEffect(() => {
    if (auth?.currentUser) {
      Promise.all([
        UserApi.getBasicUserData(user.email),
        ProjectApi.getProjectsByUser(user.email),
      ])
        .then(([userInfo, projectsData]) => {
          setBasicUserInformation(userInfo.data);
          setProjectsUser(projectsData.map(project => project));
        });
    }

    // eslint-disable-next-line
  }, [auth?.currentUser]);

  useEffect(() => {
    if (auth?.currentUser) {
      const lastElement = getTransaction === 'prev'
        ? [userTransactions[0]]
        : userTransactions.slice(-1) || [];
      const [index = {}] = lastElement;

      Promise.all([UserApi.getUserTransactions(user.email, (index?.date) || '', getTransaction)])
        .then(([response]) => {
          const pag = getTransaction === 'prev' ? (transactionPag - 1) : (transactionPag + 1);
          if (pag && response.data.length > 0) {
            setTransactionPag(pag);
            setUserTransaction(response.data);
          }
        });
    }
    // eslint-disable-next-line
  }, [getTransaction, auth?.currentUser]);

  return <UserDataContext.Provider value={share}>{children}</UserDataContext.Provider>;
};

UserData.propTypes = {
  children: PropTypes.oneOfType(
    [PropTypes.object, PropTypes.arrayOf(PropTypes.element)]).isRequired,
};

export default UserData;
