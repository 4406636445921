import React, { useContext, useState } from 'react';
import styles from './recharge.module.css';
import { TextField } from '@rmwc/textfield';
import { Button } from '@rmwc/button';
import epaycoLogo from '../../res/images/epayco.png';
import LastRecharge from '../LastRecharge/LastRecharge';
import PaymentGateWay from '../paymentGateway/PaymentGateway';
import { UserDataContext } from '../../context/UserContext';
import useAnalytics from '../../hooks/useAnalytics';
// import { Radio } from '@rmwc/radio';
import Utils from '../../utils/Utils';

// eslint-disable-next-line react/prop-types
const Sections = ({ value = '', setValue, user, renderPay }) => {
  const [confirm, setConfirm] = useState(0);
  const [paymentCost] = useState(0);
  const { trackEvent } = useAnalytics('recharge');
  // eslint-disable-next-line react/prop-types
  const { email } = user;
  const totalPayment = () => {
    const clearValue = value.replace('$', '')
      .split('.')
      .join('');
    return parseInt(paymentCost) + parseInt(clearValue);
  };

  const mockRecharga = {
    total: parseInt(totalPayment()),
    projectName: 'Recharge',
    projectId: 'personal',
    email,
    transactionType: '0',
  };

  const isDisabled = () => {
    const clearValue = value.replace('$', '')
      .split('.')
      .join('');

    return parseInt(clearValue) === 0;
  };

  switch (confirm) {
    case 0:
      return (
        <>
          <h1 className={styles['recharge-tittle']}> Recargas </h1>
          <hr/>
          <div className={styles['recharge-quantity']}>
            <h3>¿Cúanto quieres recargar?</h3>
            <TextField className={styles['input-recharge']}
                       type={'text'}
                       onKeyPress={Utils.preventTextInNumberInput}
                       value={value}
                       min={'0'}
                       onChange={(e) => {
                          const clearFormat = e.target.value
                            .replace('$', '')
                            .split('.')
                            .join('');
                          setValue(Utils.formatter.format(clearFormat));
                       }}
                       outlined/>
            {/* <h5>¿Cómo quieres recargar tu billetera?</h5>
            <div className={styles['radios-form']}>
              <Radio className={styles.radios} name={'recharge-type'}>Por PSE</Radio>
              <Radio className={styles.radios}
              name={'recharge-type'}>Transferencia Bancaria</Radio>
              <Radio className={styles.radios} name={'recharge-type'}>Nequi</Radio>
              <Radio className={styles.radios} name={'recharge-type'}>Daviplata</Radio>
            </div> */}
            <Button label={'Continuar'}
                    className={styles['button-quantity-recharge']}
                    disabled={isDisabled()}
                    onClick={() => {
                      trackEvent('click', 'continue');
                      setConfirm(1);
                    }}
                    unelevated />
          </div>
        </>
      );
    case 1:
      return (
        <>
          <h1 className={styles['recharge-tittle']}> Recargas </h1>
          <hr/>
          <div className={styles['recharge-quantity']}>
            <h3 className={styles['recharge-confirm']}>Confirmación</h3>
            <h3 className={styles['confirm-sum']}>
              Monto: <span> {value} COP </span>
            </h3>
            <h3 className={styles['confirm-sum']}>
              Costo de la transacción:
              <span> {Utils.formatter.format(paymentCost)} COP</span>
            </h3>
            <hr style={{ display: 'none' }}/>
            <h3 className={styles['confirm-sum']}>
              Total:
              <span>{Utils.formatter.format(totalPayment())} COP</span>
            </h3>
            <div className={styles['confirm-email']}>
              <img src={epaycoLogo} className={styles['img-quantity']} alt={'epayco logo'}/>
              <div className={styles['confirm-form-control']}>
                <label>Correo electrónico <span style={{ color: 'red' }}>*</span></label>
                <TextField className={styles['input-quantity']} outlined value={email}/>
              </div>
            </div>
            <div className={styles['button-end-container']}>
              <Button label={'regresar'}
                      styles={{ background: 'white' }}
                      className={styles['button-quantity']}
                      onClick={() => {
                        trackEvent('click', 'back');
                        setConfirm(0);
                      }}
                      dense />
              <div onClick={() => trackEvent('click', 'epayco')}>
                <PaymentGateWay paymentData={mockRecharga}/>
              </div>
            </div>
          </div>
        </>
      );
      case 2:
        return (
          <>
          </>
        );
      default:
        return (
          <></>
        );
  }
};
const Recharge = () => {
  const [value, setValue] = useState('0');
  const { user = {} } = useContext(UserDataContext);
  return (
    <div className={styles['recharge-container']}>
      <Sections user={user}
                value={value} setValue={setValue} />
      <div className={styles['last-recharge-container']}>
        <LastRecharge/>
      </div>
    </div>
  );
};

export default Recharge;
