import React from 'react';
import Toolbar from '../mdc/toolbar/Toolbar';
import SiteBody from '../mdc/SiteBody';
import DrawerWrapper from '../mdc/drawer/DrawerWrapper';
import ManageProjectsComponent from '../../components/projects/manage-projects/ManageProjects';
import ProjectContext from '../../context/ProjectContext';

const ManageProjects = () => {
    return <>
      <Toolbar/>
      <DrawerWrapper/>
      <SiteBody>
        <ProjectContext>
          <ManageProjectsComponent/>
        </ProjectContext>
      </SiteBody>
    </>;
};

export default ManageProjects;
