/* eslint-disable react/prop-types */
import React, { useEffect, useContext, useState } from 'react';
// import Table from './table/table';
import styles from './my-projects.module.css';
import { UserDataContext } from '../../context/UserContext';
import Cards from '../Cards/Cards';
import ProjectApi from '../../api/ProjectApi';
import { Link } from 'react-router-dom';

const MyProjects = () => {
  const [myProjects = [], setMyProjects] = useState({});
  const [loading, setLoading] = useState(true);
  const { user } = useContext(UserDataContext);

  useEffect(() => {
    ProjectApi.getProjectsByUser(user.email).then((resp) => setMyProjects(resp));
    setLoading(false);
  // eslint-disable-next-line
  }, []);

    return <>
      <div className={styles['own-projects-content']}>
        <h3>
          Mis Proyectos Activos
        </h3>

        {
          (Array.isArray(myProjects) ? myProjects : []).length !== 0
          ? <div className={styles['own-projects-content-grid']}>
            {
              (Array.isArray(myProjects) ? myProjects : []).map((projectData, pos) => (
                <Cards key={pos} projectData={projectData}
                       isMyProject={true} loading={loading}/>
            ))
            }
          </div>
          : (<div className={styles.empty__projects__content}>
            <h4>No tienes proyectos activos</h4>
            <Link className={styles.button} to={'/projects'}>
              Invertir
            </Link>
          </div>)
        }
      </div>
    </>;
};

export default MyProjects;
