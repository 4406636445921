import React, { useContext } from 'react';
import { TextField } from '@rmwc/textfield';
import { ProfileContext } from '../../../context/ProfileContext';
import { UserDataContext } from '../../../context/UserContext';
import styles from './personal.module.css';
import { Select } from '@rmwc/select';

const options = [
  {
    value: 1,
    label: 'Persona Natural',
  },
  {
    value: 2,
    label: 'Persona Jurídica',
  },
];

const PersonalData = () => {
    const { profile, dispatch } = useContext(ProfileContext);
    const { user: userVerification = {} } = useContext(UserDataContext);
    const Error = () => {
      return <span className={styles['first-span']}>
        El campo esta vacío
      </span>;
    };
    const { user = {} } = profile;
    return <>
      <div className={styles.profile}>
        <div className={styles['first-container']}>
          <h2 style={{ color: 'black' }}>Bienvenido <br/>
            <strong>
              {userVerification.displayName}
            </strong> <br/> cuéntanos más de ti</h2>
          <img alt={'BPSmart Logo'} src={userVerification.photoURL} className={styles.img}/>
          <p className={styles['text-welcome']}>
            Estos datos son necesarios para nuestro registro contable,
            para algunos temas de facturación y por supuesto para estar
            en contacto contigo.</p>
          <p className={styles['text-welcome']}>
            No te preocupes, luego desde tu perfil podrás cambiarla
            tantas veces como quieras</p>
        </div>
        <div className={styles['profile-buttons']} style={{ padding: '0.5rem' }}>
          <TextField outlined required label={'Pais de residencia:'}
                     value={user.country}
                     className={styles['document-input']}
                     onChange={(e) => dispatch({
                     type: 'ADD_USER',
                     user: { country: e.target.value.toString() },
          })}>
            {(user.country || '').trim().length === 0 ? <Error/> : <></>}
          </TextField>
          <TextField outlined required label={'Departementos de residencia:'}
                     className={styles['document-input']}
                     value={user.department}
                     onChange={(e) => dispatch({
                     type: 'ADD_USER',
                     user: { department: e.target.value.toString() },
          })}>
            {(user.department || '').trim().length === 0 ? <Error/> : <></>}
          </TextField>
          <TextField outlined required label={'Ciudad de residencia:'}
                     value={user.city}
                     className={styles['document-input']}
                     onChange={(e) => dispatch({
                     type: 'ADD_USER',
                     user: { city: e.target.value.toString() },
          })}>
            {(user.city || '').trim().length === 0 ? <Error/> : <></>}
          </TextField>
          <TextField outlined required label={'Direccion de residencia:'}
                     value={user.address}
                     className={styles['document-input']}
                     onChange={(e) => dispatch({
                     type: 'ADD_USER',
                     user: { address: e.target.value.toString() },
          })}>
            {(user.address || '').trim().length === 0 ? <Error/> : <></>}
          </TextField>
          <TextField outlined required label={'Telefono:'} type={'number'}
                     value={user.phone}
                     className={styles['document-input']}
                     onChange={(e) => dispatch({
                     type: 'ADD_USER',
                     user: { phone: e.target.value.toString() },
          })}>
            {(user.phone || '').trim().length === 0 ? <Error/> : <></>}
          </TextField>
          <TextField outlined required label={'Documento de identificación:'}
                     value={user.nit}
                     className={styles['document-input']}
                     onChange={(e) => dispatch({
                     type: 'ADD_USER',
                     user: { nit: e.target.value.toString() },
          })}>
            {(user.nit || '').trim().length === 0 ? <Error/> : <></>}
          </TextField>
          <Select required label={'Tipo de persona:'}
                  options={options}
                  className={styles['document-input']}
                  invalid={!user.typePerson}
                  value={user.typePerson}
                  onChange={(e) => dispatch({
                      type: 'ADD_USER',
                      user: { typePerson: e.target.value.toString() },
                    })
                  }
          />
        </div>
      </div></>;
};

export default PersonalData;
