import { TextField } from '@rmwc/textfield';
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { mdiDelete, mdiPencil } from '@mdi/js';
import { Checkbox } from '@rmwc/checkbox';
import Icon from '@mdi/react';
import { List, CollapsibleList, SimpleListItem } from '@rmwc/list';
import { Button } from '@rmwc/button';
import { Snackbar } from '@rmwc/snackbar';
import { LandinContext } from '../../context/LandinContext';
import LandinPageAPI from '../../api/LandinPageApi';
import style from './landinPage.module.css';

const QuestionDataForm = ({ setting, data }) => {
    const { endPoint, label, update } = setting;
    const [dataInput, Dispatch] = data;
    const { open, setOpen, snackMessage, setSnackMessage } = useContext(LandinContext);
    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');
    const [isUpdated, setIsUpdated] = useState(true);
    const [idQuestionSave, setIdQuestionSave] = useState('');
    const [isPartner, setIsPartner] = useState(false);

    const questionsData = dataInput?.questions;

    const handleButtonAdd = () => {
      if (question.length <= 0 && answer.length <= 0) {
        setSnackMessage('Por favor ingrese una pregunta y una respuesta');
        setOpen(true);
        return;
      }

      LandinPageAPI.createQuestion({ answer, question, isPartner }, endPoint)
      .then(resp => {
        const info = {
          idQuestion: resp.id,
          info: {
            answer,
            question,
            isPartner,
          },
        };
        Dispatch({ type: 'ADD_QUESTION', info });
        setSnackMessage('Una pregunta ha sido agregada');
      })
      .catch(() => setSnackMessage('Error al agregar la pregunta'))
      .finally(() => {
        setOpen(true);
        setAnswer('');
        setQuestion('');
        setIsPartner(false);
      });
    };

    const handleButtonDelete = (idQuestion) => {
      LandinPageAPI.deleteQuestion(idQuestion, endPoint)
      .then(resp => {
        Dispatch({ type: 'DELETE_QUESTION', idQuestion });
        setSnackMessage('Una pregunta ha sido eliminada');
      })
      .catch(() => setSnackMessage('Error al eliminar la pregunta'))
      .finally(() => {
        setOpen(true);
        setAnswer('');
        setQuestion('');
      });
    };

    const handleButtonUpdate = (idQuestion) => {
      const [dataFilter] = questionsData.filter((e) => e.idQuestion === idQuestion);
      setAnswer(dataFilter?.info?.answer);
      setQuestion(dataFilter?.info?.question);
      setIsPartner(dataFilter?.info?.isPartner);
      setIsUpdated(false);
      setIdQuestionSave(idQuestion);
    };

    const updatedData = () => {
      LandinPageAPI
        .updatedQuestion({ answer, question, isPartner }, idQuestionSave, endPoint)
        .then(resp => {
            Dispatch({
              type: 'UPDATE_QUESTION',
              dataUpdate: { idQuestionSave, answer, question },
            });
            setSnackMessage('Una pregunta ha sido actualizada');
          })
        .catch(() => setSnackMessage('Error al actualizar la pregunta'))
        .finally(() => {
          setOpen(true);
          setAnswer('');
          setQuestion('');
          setIsPartner(false);
          setIdQuestionSave('');
          setIsUpdated(true);
        });
    };

    return (
      <div className={style['container-question']}>
        <div>
          <small>
            {
            isUpdated
            ? `Agregar ${update}`
            : 'Actualizar Pregunta'
          }</small>
          <TextField outlined label={'Pregunta'}
                     value={question}

                     onChange={(e) => setQuestion(e.target.value)}
                     style={{ marginBottom: '1rem' }}
          />
          <TextField
            textarea
            outlined
            label={'Respuesta'}
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
            rows={8}
            characterCount
          />
          <Checkbox
            label={'Pregunta para socios'}
            checked={isPartner}
            onChange={(evt) => setIsPartner(!!evt.currentTarget.checked)}
            />
          <Button
            style={{ marginTop: '1rem' }}
            unelevated
            onClick={() => (isUpdated ? handleButtonAdd() : updatedData())}
          >
            {
            isUpdated
            ? `Agregar ${update}`
            : `Actualizar ${label}`
            }
          </Button>
        </div>

        <div>
          <small className={style.questions}>Preguntas Actuales</small>
          <List>
            {
              questionsData?.length > 0
              ? questionsData.map(({ info, idQuestion }) => (

                <CollapsibleList
                  key={idQuestion}
                  handle={
                    <div style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}>
                      <SimpleListItem
                        metaIcon={'info'}
                        text={info.question}
                        style={{ width: '80%' }}
                      />
                      <Icon path={mdiDelete} size={1}
                            style={{ width: '12.5%', background: '#d2f9hf', marginRight: '.5rem' }}
                            onClick={() => { handleButtonDelete(idQuestion); }}/>
                      <Icon path={mdiPencil} size={1}
                            style={{ width: '12.5%', background: '#d2f9hf' }}
                            onClick={() => { handleButtonUpdate(idQuestion); }}/>
                    </div>
                }
                  style={{ background: '#f0f8ff' }}
                >
                  <SimpleListItem text={info.answer} style={{ whiteSpace: 'nowrap' }}/>
                </CollapsibleList>
              ))
              : <div style={{ display: 'flex', justifyContent: 'center' }}>
                <p>No hay {label} agregadas</p>
              </div>
            }
          </List>
        </div>
        <Snackbar
          open={open}
          onClose={() => setOpen(false)}
          message={snackMessage}
          dismissesOnAction
        />
      </div>
    );
};

QuestionDataForm.propTypes = {
    data: PropTypes.object,
    setting: PropTypes.object,
};

export default QuestionDataForm;
