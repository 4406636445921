/* eslint-disable react/prop-types */
import React from 'react';
import CustomTextField from '../../../../UI/CustomTextField/CustomTextField';
import styles from '../manage-project.module.css';

const GeneralSection = ({ projectData, createProjectDispatch }) => {
  const handleChange = (value, param) => {
    const DISPATCH_PARAM = 'UPDATE_PROJECT';
    const data = {};
    data[param] = value;
    createProjectDispatch({ type: DISPATCH_PARAM, project: { projectData: data } });
  };
  return (
    <div className={styles['section-container']}>
      <div style={{ display: 'flex', columnGap: '1rem', justifyContent: 'center' }}>
        <CustomTextField label={'Descuento desde'} className={styles.custom_text_field}
                         min={0} onChange={(e) => handleChange(
                            `${parseFloat(e.target.value) / 100}`,
                            'minDiscount')}
                         trailingIcon={'percent'} type={'number'}
                         value={(parseFloat(projectData.minDiscount) * 100).toFixed(2)}/>
        <CustomTextField label={'Descuento hasta'} className={styles.custom_text_field}
                         min={0} onChange={(e) => handleChange(
                          `${parseFloat(e.target.value) / 100}`,
                          'maxDiscount')}
                         trailingIcon={'percent'} type={'number'}
                         value={(parseFloat(projectData.maxDiscount) * 100).toFixed(2)}/>
      </div>
      <div style={{ display: 'flex', columnGap: '1rem', justifyContent: 'center' }}>
        <CustomTextField label={'Costo del Proyecto  (COP)'} className={styles.custom_text_field}
                         min={0} onChange={(e) => handleChange(e.target.value, 'totalCost')}
                         required icon={'money'} value={projectData.totalCost}/>
        <CustomTextField label={'Dinero recaudado (COP)'} className={styles.custom_text_field}
                         min={0} onChange={(e) => handleChange(e.target.value, 'raisedMoney')}
                         required icon={'money'} value={projectData.raisedMoney} />
      </div>
      <div style={{ columnGap: '1rem' }}>
        <CustomTextField label={'Tarifa de venta de energía'} className={styles.custom_text_field}
                         min={0} onChange={(e) => handleChange(e.target.value, 'energyCost')}
                         type={'number'}
                         icon={'money'}
                         value={projectData.energyCost}/>
        <CustomTextField label={'Renta anual estimada'} className={styles.custom_text_field}
                         min={0} onChange={(e) => handleChange(
                            `${parseFloat(e.target.value) / 100}`,
                            'annualRest')} type={'number'}
                         trailingIcon={'percent'}
                         value={(projectData.annualRest * 100).toFixed(2)}/>
      </div>
      <div style={{ display: 'flex', columnGap: '1rem' }}>
        <div style={{ width: '100%' }}>
          <CustomTextField label={'Contrato a (Años)'} className={styles.custom_text_field}
                           min={0} onChange={(e) => handleChange(e.target.value, 'contract')}
                           required icon={'calendar'} value={projectData.contract}
                           placeholder={'1 Año'}/>
        </div>
      </div>
      <div style={{ display: 'flex', columnGap: '1rem' }}>
        <div style={{ width: '100%' }}>
          <CustomTextField label={'Eficiencía del sistema'} className={styles.custom_text_field}
                           min={0} onChange={(e) => handleChange(
                            `${parseFloat(e.target.value) / 100}`,
                            'systemEfience')}
                           required trailingIcon={'percent'}
                           value={`${(parseFloat(projectData.systemEfience) * 100).toFixed(2)}`}
                           placeholder={'1 Año'} />
        </div>
      </div>
      <div style={{ columnGap: '1rem' }}>
        <div style={{ width: '100%' }}>
          <CustomTextField label={'Incremento anual de venta de energía'}
                           className={styles.custom_text_field}
                           min={0} onChange={(e) => handleChange(
                            `${parseFloat(e.target.value) / 100}`,
                            'annualIncrement')} type={'number'}
                           trailingIcon={'percent'}
                           value={(projectData.annualIncrement * 100).toFixed(2)}
                           placeholder={'2'}/>
        </div>
        <div style={{ width: '100%' }}>
          <CustomTextField label={'Porcentaje inversionistas'} className={styles.custom_text_field}
                           min={0} onChange={(e) => handleChange(
                            `${parseFloat(e.target.value) / 100}`,
                            'inversorsValue')} type={'number'}
                           trailingIcon={'percent'}
                           value={(projectData.inversorsValue * 100).toFixed(2)}
                           placeholder={'3.5'}/>
        </div>
      </div>
      <div style={{ columnGap: '1rem' }}>
        <div style={{ width: '100%' }}>
          <CustomTextField label={'Mantenimiento'} className={styles.custom_text_field}
                           min={0} onChange={(e) => handleChange(
                            `${parseFloat(e.target.value) / 100}`,
                            'maintance')} type={'number'}
                           trailingIcon={'percent'}
                           value={(projectData.maintance * 100).toFixed(2)}
                           placeholder={'20'}/>
        </div>
        <div style={{ width: '100%' }}>
          <CustomTextField label={'Rentabilidad'} className={styles.custom_text_field}
                           min={0} onChange={(e) => handleChange(
                              `${parseFloat(e.target.value) / 100}`,
                              'rentability')}
                           trailingIcon={'percent'} placeholder={'11'} type={'number'}
                           value={(projectData.rentability * 100).toFixed(2)} />
        </div>
      </div>
    </div>
  );
};

export default GeneralSection;
