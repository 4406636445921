/* eslint-disable react/prop-types */
import React from 'react';
import styles from '../manage-project.module.css';
import CustomTextField from '../../../../UI/CustomTextField/CustomTextField';
import Utils from '../../../../../utils/Utils';

const DescriptionSection = ({ projectData, createProjectDispatch }) => {
  const handleChange = (value, param) => {
    const DISPATCH_PARAM = 'UPDATE_PROJECT';
    const data = {};
    data[param] = value;
    createProjectDispatch({ type: DISPATCH_PARAM, project: { projectData: data } });
  };

    return (
      <div className={styles['section-container']}>
        <CustomTextField className={styles.custom_text_field}
                         label={'Nombre del proyecto'} required value={projectData.projectName}
                         onChange={(e) => handleChange(e.target.value, 'projectName')}/>
        <CustomTextField label={'Tamaño de la granja (Metros Cuadrados)'}
                         className={styles.custom_text_field}
                         onChange={(e) => handleChange(e.target.value, 'farmsize')}
                         value={projectData.farmsize}
                         required type={'number'}
                         onKeyPress={Utils.preventTextInNumberInput}/>
        <CustomTextField label={'Descripcion'} textarea
                         className={styles.custom_text_field}
                         onChange={(e) => handleChange(e.target.value, 'text')}
                         value={projectData.text}/>
        <div style={{ display: 'flex', columnGap: '1rem', justifyContent: 'center' }}>
          <CustomTextField label={'N° de paneles digitales'} type={'number'}
                           className={styles.custom_text_field}
                           onChange={(e) => handleChange(e.target.value, 'noOfDigitalPanels')}
                           required value={projectData.noOfDigitalPanels} min={0}
                           onKeyPress={Utils.preventTextInNumberInput}/>
          <CustomTextField label={'N° de paneles fisicos'} type={'number'}
                           className={styles.custom_text_field}
                           onChange={(e) => handleChange(e.target.value, 'noOfPhysicalPanels')}
                           onKeyPress={Utils.preventTextInNumberInput}
                           required min={0} value={projectData.noOfPhysicalPanels}/>
        </div>
        <CustomTextField label={'Precio del panel'} dispatchType={'UPDATE_PROJECT'}
                         onChange={(e) => handleChange(e.target.value, 'panelCost')}
                         value={projectData.panelCost} icon={'money'}
                         className={styles.custom_text_field}
                         required min={0} type={'number'}/>
        <CustomTextField label={'Max.Porcentaje paneles usuario'}
                         onChange={(e) =>
                            handleChange(
                              `${(parseInt(e.target.value) / 100)}`,
                              'UserPercentagePanels')}
                         value={`${projectData.UserPercentagePanels * 100}`}
                         icon={'percent'}
                         className={styles.custom_text_field}
                         required min={0} type={'number'} step={'any'} />
        <CustomTextField label={'Max.Paneles usuario'}
                         className={styles.custom_text_field}
                         value={projectData.UserMaxPanels} disabled/>
        <div style={{ display: 'flex', columnGap: '1rem', justifyContent: 'center' }}>
          <div style={{ width: '100%' }}>
            <CustomTextField label={'Potencía individual por panel (kW)'}
                             className={styles.custom_text_field}
                             onChange={(e) => handleChange(e.target.value, 'power')}
                             value={projectData.power} placeholder={'20'}
                             required min={0} type={'number'}/>
            <CustomTextField label={'Potencía instalada (kW)'}
                             value={projectData.powerInstalled}
                             disabled placeholder={'300w'} className={styles.custom_text_field}/>
          </div>
        </div>
      </div>
    );
};

export default DescriptionSection;
