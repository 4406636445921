import React, { useState } from 'react';
import style from './landinPage.module.css';
import {
    TabBar, Tab
  } from '@rmwc/tabs';
import Question from './questions/Question';
import AboutUs from './aboutUs/AboutUs';

const LandinPageHome = () => {
    const [section, setSection] = useState(0);

  return (
    <div className={style.container}>
      <div className={style['container-information']}>
        <TabBar>
          <Tab
            onClick={() => setSection(0)}
            label={'Preguntas Frecuentes'}/>
          <Tab
            onClick={() => setSection(1)}
            label={'¿Cómo funciona?'}/>
        </TabBar>
        <div>
          {
            section === 0
              ? <Question/>
            : section === 1
            ? <AboutUs/>
            : <></>
          }
        </div>
      </div>
    </div>
  );
};

export default LandinPageHome;
