/* eslint-disable max-len */
import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Maps from './maps/Map.js';
import { Bar } from 'react-chartjs-2';
import { Button } from '@rmwc/button';
import { Checkbox } from '@rmwc/checkbox';
import { TextField } from '@rmwc/textfield';
import {
  DataTable, DataTableContent, DataTableHead,
  DataTableBody, DataTableRow, DataTableHeadCell, DataTableCell
} from '@rmwc/data-table';
import { ProjectContext } from './../../context/ProjectContext';
import { ProfileContext } from '../../context/ProfileContext';
import DialogHistory from './dialogHistory/DialogHistory.js';
import useChartData from '../../hooks/useChartData.js';
import Utils from '../../utils/Utils';
import styles from './general.module.css';
import useAnalytics from '../../hooks/useAnalytics';
import Logo from '../../res/images/logo1.png';

const projectStatusOptions = ['Siembra', 'Cultivo', 'Cosecha'];

const options = {
  scales: { yAxes: [{ ticks: { beginAtZero: true } }] },
};

const General = () => {
  const { projectsByUser = [] } = useContext(ProjectContext);
  const { trackEvent } = useAnalytics('general');
  const {
    getRenuevePerProject,
    btnPeriod,
    totalRevenue,
    monthlyIncome,
    share,
    costEffectiveness,
    chartData,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    setProjectsUser,
    setBtnPeriod,
  } = useChartData({ actualMonth: Utils.getActualDate().month, defaultProjects: projectsByUser });

  const [month] = useState(Utils.getActualDate().month);
  const [checked, setChecked] = useState({});
  const [allProjectsSelected, setAllProjectsSelected] = useState(true);
  const [selectProject, setSelectProject] = useState('');

  const { profile = {} } = useContext(ProfileContext);
  const { user = {} } = profile;
  const { wallet = {} } = user;

  const percentage = (value) => (`${value.toFixed(2)}%`);
  const getPanelsPerProject = (data) => {
    const { sharePercentage = 0, project } = data;
    const { noOfDigitalPanels = 0 } = project;
    const totalPanels = Number(Math.round(noOfDigitalPanels)) * Number(sharePercentage / 100);
    return Math.round(totalPanels);
  };

  useEffect(() => {
    if (projectsByUser.length <= 0) return;
    let selectProj = {};
    setProjectsUser(projectsByUser);
    projectsByUser.forEach((project) => (selectProj = { ...selectProj, [project.projectId]: true }));
    setChecked(selectProj);
    // eslint-disable-next-line
  }, [projectsByUser]);

  useEffect(() => {
    const arrayChecked = Object.entries(checked);
    const arraySelected = arrayChecked.filter((item) => item[1]);
    const projSelected = arraySelected.map(([id]) => (projectsByUser.find((project) => project.projectId === id)));
    setAllProjectsSelected(arraySelected.length === projectsByUser.length);
    setProjectsUser(projSelected);
    // eslint-disable-next-line
  }, [checked]);

  useEffect(() => {
    if (!allProjectsSelected) {
      const arrayChecked = Object.entries(checked);
      let selectProj = {};
      arrayChecked.forEach(([id]) => (selectProj = { ...selectProj, [id]: false }));
      setChecked(selectProj);
      return;
    }

    setProjectsUser(projectsByUser);
    // eslint-disable-next-line
  }, [allProjectsSelected]);

    return <>
      <div className={styles['chip-container']}>
        <div className={styles['chip-information']}>
          <h5>{`${Utils.formatter.format(wallet.amount.toFixed(0))}`}</h5>
          <p>Saldo Disponible</p>
        </div>
        <div className={styles['chip-information']}>
          <h5>{Utils.formatter.format(totalRevenue)}</h5>
          <p>Ingresos Totales</p>
        </div>
        <div className={styles['chip-information']}>
          <h5>{Utils.formatter.format(monthlyIncome.toFixed(0))}</h5>
          <p>Ingresos del mes: {`${startDate === endDate ? startDate : month}`}</p>
        </div>
        <Link className={styles['chip-information']} to={'/withDraw'}>
          <h5>{`${Utils.formatter.format(wallet.pending.toFixed(0))}`}</h5>
          <p>Pendiente de giro</p>
        </Link>
        <div className={styles['chip-information']}>
          <h5>{`${share.toFixed(2)} %`}</h5>
          <p>Participación Actual</p>
        </div>
        <div className={styles['chip-information']}>
          <h5>{costEffectiveness} %</h5>
          <p>Rentabilidad Actual</p>
        </div>
      </div>
      <div className={styles['general-information-container']}>
        <div className={styles['card-container']}>
          <div className={styles['chart-container']}>
            <h5>Gráfica de Generación</h5>
            <div className={styles['chart-container__datepickers']}>
              <TextField label={'Desde'} type={'month'}
                         max={Utils.getActualDate().month} style={{ flexGrow: '1' }}
                         value={startDate} onChange={(e) => setStartDate(e.target.value)} />
              <TextField label={'Hasta'} type={'month'} min={startDate}
                         max={Utils.getActualDate().month} style={{ flexGrow: '1' }}
                         value={endDate} onChange={(e) => setEndDate(e.target.value)} />
            </div>
            <div className={styles['chart-container__buttons']}>
              <Button label={'Semana'} className={styles['chart-btn']}
                      raised={btnPeriod === 'week'} onClick={() => {
                        trackEvent('click', 'week');
                        setBtnPeriod('week');
                      }}/>
              <Button label={'Mes'} className={styles['chart-btn']}
                      raised={btnPeriod === 'month'} onClick={() => {
                        trackEvent('click', 'month');
                        setBtnPeriod('month');
                      }}/>
              <Button label={'Año'} className={styles['chart-btn']}
                      raised={btnPeriod === 'year'} onClick={() => {
                        trackEvent('click', 'year');
                        setBtnPeriod('year');
                      }} />
            </div>
          </div>
          { chartData
            ? <Bar width={'100%'} height={'60%'} data={chartData} options={options} />
            : <p style={{ margin: 'auto', color: 'lightslategray' }}>Todavia no hay datos para mostar</p>
          }
        </div>
        <div className={styles['card-container']}>
          <h5>Distribución de tus proyectos</h5>
          <Maps projects={projectsByUser} height={'360px'} width={'100%'} margin={'0'}/>
        </div>
      </div>

      <div className={styles['card-container']} style={{ margin: '2rem .5em' }}>
        <div className={styles['project-title-container']}>
          <h3>Mis Proyectos Activos</h3>
          <Link to={'/projects'}>Invertir</Link>
        </div>
        <DataTable style={{ width: '100%', textAlign: 'center' }}>
          <DataTableContent style={{ margin: 'auto', textAlign: 'center' }}>
            <DataTableHead style={{ margin: 'auto', textAlign: 'center' }}>
              <DataTableRow style={{ margin: 'auto', textAlign: 'center' }}>
                <DataTableHeadCell hasFormControl>
                  <Checkbox checked={allProjectsSelected} onChange={(e) =>
                    setAllProjectsSelected(e.currentTarget.checked)
                  }/>
                </DataTableHeadCell>
                <DataTableHeadCell colSpan={5} style={{ paddingLeft: '26px' }}>Nombre</DataTableHeadCell>
                <DataTableHeadCell colSpan={2} style={{ textAlign: 'center' }}>Inicio de operación </DataTableHeadCell>
                <DataTableHeadCell colSpan={1} style={{ textAlign: 'center' }}>Participación actual</DataTableHeadCell>
                <DataTableHeadCell colSpan={1} style={{ textAlign: 'center' }}>Cantidad de paneles</DataTableHeadCell>
                <DataTableHeadCell colSpan={1} style={{ textAlign: 'center' }}>Ingresos totales</DataTableHeadCell>
              </DataTableRow>
            </DataTableHead>
            <DataTableBody style={{ margin: 'auto', textAlign: 'center' }}>
              {(projectsByUser).map((data, i) => (
                <DataTableRow key={i} style={{ marginTop: '5rem' }} >
                  <DataTableCell hasFormControl>
                    <Checkbox
                      checked={checked[data.projectId] || allProjectsSelected}
                      onChange={(evt) => {
                        checked[data.projectId] = evt.currentTarget.checked;
                        setChecked({ ...checked });
                      }}
                    />
                  </DataTableCell>
                  <DataTableCell colSpan={5}>
                    <div style={{ display: 'flex', columnGap: '1rem', padding: '0.5rem' }}>
                      <img alt={'project_picture'}src={data?.mainPicture[0] || Logo} width={'150px'} height={'80px'} style={{ borderRadius: '0.5rem' }}/>
                      <div style={{ lineHeight: '2rem', textAlign: 'left' }}>
                        <b>{data?.project?.projectName}</b><br/>
                        {projectStatusOptions[data?.project?.projectStatus]}
                      </div>
                    </div>
                  </DataTableCell>
                  <DataTableCell colSpan={2}>{data?.project?.beginningDate}</DataTableCell>
                  <DataTableCell colSpan={1}>{percentage(data?.sharePercentage || 0)}</DataTableCell>
                  <DataTableCell colSpan={1}>
                    <Button label={getPanelsPerProject(data)} onClick={() => setSelectProject(data.projectId)}/>
                    <DialogHistory
                      history={data.transactions || []}
                      onClose={() => setSelectProject('')}
                      projectId={data.projectId}
                      isOpen={selectProject === data.projectId}/>
                  </DataTableCell>
                  <DataTableCell colSpan={1}>{ Utils.formatter.format(getRenuevePerProject(data)) }</DataTableCell>
                </DataTableRow>
              ))}
            </DataTableBody>
          </DataTableContent>
        </DataTable>
      </div>
    </>;
};

export default General;
