/* eslint-disable max-lines */
/* eslint-disable multiline-ternary */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TableAdminValues } from './TableAdminValues';
import { IconButton } from '@rmwc/icon-button';
import { Tab, TabBar } from '@rmwc/tabs';
import { mdiCloseThick } from '@mdi/js';
import Icon from '@mdi/react';
import styles2 from './TableAdminValues.module.css';
import styles from './Graphic.module.css';
import { Bar, Doughnut } from 'react-chartjs-2';
import Utils from '../../../../../utils/Utils';

const months = ['ENERO', 'FEBRERO', 'MARZO', 'ABRIL', 'MAYO',
                'JUNIO', 'JULIO', 'AGOSTO', 'SEPTIEMBRE',
                'OCTUBRE', 'NOVIEMBRE', 'DICIEMBRE'];

const asignation = ['', 'GRANJEROS', 'FONDO MANTENIMIENTO', 'ADMINISTRACIÓN'];

const sections = [{ label: 'Energia Producida', value: 0 },
                  { label: 'Proyección de Venta de Energía', value: 1 },
                  { label: 'Porcentaje de Ventas', value: 2 },
                  { label: 'Proyección Anual de Ventas', value: 3 },
                  { label: 'Valor actual Neto Van del proyecto', value: 4 }];

const years = Array(20).fill(0)
                .map((e, idx) => (`AÑO ${idx + 1}`));

const options = (isMoney = true) => ({
    responsive: true,
    display: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          callback(value) {
            return isMoney
            ? value <= 1 ? '' : `${Utils.formatterEmpty.format(value)} COP`
            : value <= 1 ? '' : `${Utils.formatterEmpty.format(value)} kWh/mes`
            ;
          },
        },
      },
    },
});

const total = (total) => {
    return {
      total: total[0] + total[1] + total[2],
    };
  };

  const updatedOut = (data, total) => {
    const elements = [];
    for (let i = 0; i < data.length; i++) {
      elements.push({
        farmers: data[i][0],
        maintFund: data[i][1],
        admin: data[i][2],
      });
    }
    elements.push({
      farmers: total[0],
      maintFund: total[1],
      admin: total[2],
    });
    return elements;
  };

const TableAdminSection = ({ open, setOpen, data }) => {
    const [section, setSection] = useState(0);
    const dataChart = (labels, infoData, options) => {
      return {
        labels,
        datasets: infoData,
      };
    };
    return (
      <div
        className={`${styles.custom_modal_table} 
        ${open ? styles.modal_onclick : styles.modal_offclick}`}
      >
        <div className={styles.custom_modal_content}>
          <IconButton
              className={styles2.icon_button}
              onClick={() => {
                setOpen(false);
               }}
              >
            <Icon path={mdiCloseThick}></Icon>
          </IconButton>
          <div style={{ marginBottom: 'calc(8vh)' }}>
            <h4>Variables de salida Administrador</h4>
            <div className={styles2.container_button}>
              <TabBar>
                {
                  sections.map((section, idx) => (
                    <Tab
                    onClick={() => setSection(section.value)}
                    key={idx}>
                      {section.label}
                    </Tab>
                  ))
                }
              </TabBar>
            </div>
            {
              section === 0
                ? <div className={styles2.container_section}>
                  <div className={styles2.container_section_graphi} >
                    <Bar className={styles['graphic-end']}
                         data={dataChart(months, [{
                          label: 'Estimación de la Energia Producida',
                          backgroundColor: '#2ad2c5',
                          data: data?.estimatedEnergyProduced,
                        }])}
                         options={options(false)} size={32} />
                  </div>
                  <div>
                    <TableAdminValues data={data?.estimatedEnergyProduced} base={months}
                                      unit={'kWh/mes'}
                                      title={'Estimación de la Energia Producida'}/>
                  </div>
                </div>
              : section === 1
                ? <div className={styles2.container_section}>
                  <div className={styles2.container_section_graphi} >
                    <Bar className={styles['graphic-end']}
                         data={dataChart(months, [{
                          label: 'Proyección de Venta de Energía',
                          backgroundColor: '#2ad2c5',
                          data: data?.saleEnergyProduced,
                        }])}
                         options={options(false)} size={32} />
                  </div>
                  <div>
                    <TableAdminValues data={data?.saleEnergyProduced} base={months} unit={'kWh/mes'}
                                      title={'Proyección de Venta de Energía'}/>
                  </div>
                </div>
              : section === 2
                ? <div className={styles2.container_section}>
                  <div className={styles2.container_section_table}>

                    <TableAdminValues
                    data={updatedOut(data?.salesPercentageAllocation.moneyCollected,
                                    data?.salesPercentageAllocation.moneyCollectedTotal
                                    )}
                    isElement={total(data?.salesPercentageAllocation.moneyCollectedTotal)}
                    base={[...months, 'TOTALES', 'TOTAL']}
                    tableHeadCell={asignation}
                    title={'Asignación Mensual Dinero Cobrado'} />
                  </div>
                </div>

              : section === 3
                ? <div className={styles2.container_section}>
                  <div className={styles2.container_section_table}>
                    <TableAdminValues data={data?.annualProjection} base={years}
                                      tableHeadCell={[...asignation, 'TOTALES']}
                                      title={'Proyección Anual de Ventas'} />
                  </div>
                </div>
              : section === 4
                ? <div className={styles2.container_section}>
                  <div className={`${styles2.container_section_graphi} ${styles2.grapDoughnut}`}>
                    <Doughnut
                      data={dataChart(['Granjeros', 'Fondo Mantenimiento',
                                      'Administracion'], [{
                                          data: data?.VAN[0]
                                           ? Object.values(data?.VAN[0]).slice(0, 3) : [],
                                           backgroundColor: [
                                            'rgba(255, 99, 132, 0.2)',
                                            'rgba(54, 162, 235, 0.2)',
                                            'rgba(255, 206, 86, 0.2)',
                                            'rgba(75, 192, 192, 0.2)',
                                            'rgba(153, 102, 255, 0.2)',
                                            'rgba(255, 159, 64, 0.2)',
                                          ],
                                          borderColor: [
                                            'rgba(255, 99, 132, 1)',
                                            'rgba(54, 162, 235, 1)',
                                            'rgba(255, 206, 86, 1)',
                                            'rgba(75, 192, 192, 1)',
                                            'rgba(153, 102, 255, 1)',
                                            'rgba(255, 159, 64, 1)',
                                          ],
                                          borderWidth: 1,
                                        },
                                      ])}
                    />
                  </div>
                  <div className={styles2.container_section_table}>
                    <TableAdminValues data={data?.VAN} base={['VAN']}
                                      tableHeadCell={[...asignation, 'TOTAL']}
                                      title={'Valor Actual Neto VAN del Proyecto'} />
                  </div>
                </div>
              : <></>
            }
          </div>
        </div>
      </div>
    );
};

TableAdminSection.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    data: PropTypes.object,
  };

export default TableAdminSection;
