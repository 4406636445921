/* eslint-disable react/prop-types */
import React from 'react';
import styles from '../manage-project.module.css';
import { TextField } from '@rmwc/textfield';
import NumberValidator from '../../../../Utils/NumberValidator';

const AmortizationField = ({
    disabled, label, type, textarea,
    required, dispatchType, placeholder,
    dispatchParam, data = {}, dispatch,
}) => {
    const projectData = {};
    return <div className={styles.custom_text_field}>
      <TextField label={label} textarea={textarea} type={(type === 'date' ? 'date' : '')}
                 placeholder={placeholder} value={data[`${dispatchParam}`]}
                 disabled={disabled}
                 trailingIcon={'percent'}
                 onChange={(e) => {
                   data[`${dispatchParam}`] = (e.target.value);
                   dispatch({ type: dispatchType, project: { projectData } });
                 }}/>
      {(required) ? <NumberValidator required={true} value={data[`${dispatchParam}`]}/> : <></>}
    </div>;
};

const Amortization = ({ amortization, createProjectDispatch }) => {
    return (
      <div className={styles['section-container']}>
        <div style={{ display: 'flex', justifyContent: 'center', columnGap: '1rem' }}>
          <div style={{ width: '100%' }}>
            <AmortizationField label={'Inflación actual'} dispatchType={'UPDATE_PROJECT'}
                               dispatchParam={'ipc'} data={amortization} value={'como'}
                               dispatch={createProjectDispatch} placeholder={''}/>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', columnGap: '1rem' }}>
          <div style={{ width: '100%' }}>
            <AmortizationField label={'año 1'} dispatchType={'UPDATE_PROJECT'}
                               dispatchParam={'1'} data={amortization} value={'como'}
                               dispatch={createProjectDispatch} placeholder={''}/>
          </div>
          <div style={{ width: '100%' }}>
            <AmortizationField label={'año 2'} dispatchType={'UPDATE_PROJECT'}
                               dispatchParam={'2'} data={amortization}
                               dispatch={createProjectDispatch} placeholder={''}/>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', columnGap: '1rem' }}>
          <div style={{ width: '100%' }}>
            <AmortizationField label={'año 3'} dispatchType={'UPDATE_PROJECT'}
                               dispatchParam={'3'} data={amortization}
                               dispatch={createProjectDispatch} placeholder={''}/>
          </div>
          <div style={{ width: '100%' }}>
            <AmortizationField label={'año 4'} dispatchType={'UPDATE_PROJECT'}
                               dispatchParam={'4'} data={amortization}
                               dispatch={createProjectDispatch} placeholder={''}/>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', columnGap: '1rem' }}>
          <div style={{ width: '100%' }}>
            <AmortizationField label={'año 5'} dispatchType={'UPDATE_PROJECT'}
                               dispatchParam={'5'} data={amortization}
                               dispatch={createProjectDispatch} placeholder={''}/>
          </div>
          <div style={{ width: '100%' }}>
            <AmortizationField label={'año 6'} dispatchType={'UPDATE_PROJECT'}
                               dispatchParam={'6'} data={amortization}
                               dispatch={createProjectDispatch} placeholder={''}/>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', columnGap: '1rem' }}>
          <div style={{ width: '100%' }}>
            <AmortizationField label={'año 7'} dispatchType={'UPDATE_PROJECT'}
                               dispatchParam={'7'} data={amortization}
                               dispatch={createProjectDispatch} placeholder={''}/>
          </div>
          <div style={{ width: '100%' }}>
            <AmortizationField label={'año 8'} dispatchType={'UPDATE_PROJECT'}
                               dispatchParam={'8'} data={amortization}
                               dispatch={createProjectDispatch} placeholder={''}/>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', columnGap: '1rem' }}>
          <div style={{ width: '100%' }}>
            <AmortizationField label={'año 9'} dispatchType={'UPDATE_PROJECT'}
                               dispatchParam={'9'} data={amortization}
                               dispatch={createProjectDispatch} placeholder={''}/>
          </div>
          <div style={{ width: '100%' }}>
            <AmortizationField label={'año 10'} dispatchType={'UPDATE_PROJECT'}
                               dispatchParam={'10'} data={amortization}
                               dispatch={createProjectDispatch} placeholder={''}/>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', columnGap: '1rem' }}>
          <div style={{ width: '100%' }}>
            <AmortizationField label={'año 11'} dispatchType={'UPDATE_PROJECT'}
                               dispatchParam={'11'} data={amortization}
                               dispatch={createProjectDispatch} placeholder={''}/>
          </div>
          <div style={{ width: '100%' }}>
            <AmortizationField label={'año 12'} dispatchType={'UPDATE_PROJECT'}
                               dispatchParam={'12'} data={amortization}
                               dispatch={createProjectDispatch} placeholder={''}/>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', columnGap: '1rem' }}>
          <div style={{ width: '100%' }}>
            <AmortizationField label={'año 13'} dispatchType={'UPDATE_PROJECT'}
                               dispatchParam={'13'} data={amortization}
                               dispatch={createProjectDispatch} placeholder={''}/>
          </div>
          <div style={{ width: '100%' }}>
            <AmortizationField label={'año 14'} dispatchType={'UPDATE_PROJECT'}
                               dispatchParam={'14'} data={amortization}
                               dispatch={createProjectDispatch} placeholder={''}/>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', columnGap: '1rem' }}>
          <div style={{ width: '100%' }}>
            <AmortizationField label={'año 15'} dispatchType={'UPDATE_PROJECT'}
                               dispatchParam={'15'} data={amortization}
                               dispatch={createProjectDispatch} placeholder={''}/>
          </div>
          <div style={{ width: '100%' }}>
            <AmortizationField label={'año 16'} dispatchType={'UPDATE_PROJECT'}
                               dispatchParam={'16'} data={amortization}
                               dispatch={createProjectDispatch} placeholder={''}/>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', columnGap: '1rem' }}>
          <div style={{ width: '100%' }}>
            <AmortizationField label={'año 17'} dispatchType={'UPDATE_PROJECT'}
                               dispatchParam={'17'} data={amortization}
                               dispatch={createProjectDispatch} placeholder={''}/>
          </div>
          <div style={{ width: '100%' }}>
            <AmortizationField label={'año 18'} dispatchType={'UPDATE_PROJECT'}
                               dispatchParam={'18'} data={amortization}
                               dispatch={createProjectDispatch} placeholder={''}/>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', columnGap: '1rem' }}>
          <div style={{ width: '100%' }}>
            <AmortizationField label={'año 19'} dispatchType={'UPDATE_PROJECT'}
                               dispatchParam={'19'} data={amortization}
                               dispatch={createProjectDispatch} placeholder={''}/>
          </div>
          <div style={{ width: '100%' }}>
            <AmortizationField label={'año 20'} dispatchType={'UPDATE_PROJECT'}
                               dispatchParam={'20'} data={amortization}
                               dispatch={createProjectDispatch} placeholder={''}/>
          </div>
        </div>
      </div>
    );
};

export default Amortization;
