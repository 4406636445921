/* eslint-disable react/prop-types */
import React from 'react';
import styles from './payment-response.module.css';
import { Link } from 'react-router-dom';
import { PDFDownloadLink } from '@react-pdf/renderer';
import SuscriptionTablePdf from '../payment-pdf/suscriptiontable';
import logo from '../../../res/images/logoDark.png';
import { Button } from '@rmwc/button';
import Icon from '@mdi/react';
import { mdiHome, mdiDownload } from '@mdi/js';
import {
  DataTable, DataTableCell, DataTableRow, DataTableBody
} from '@rmwc/data-table';
import Utils from '../../../utils/Utils';

const RowData = ({ label, text }) => {
    return (
      <DataTableRow className={styles['table-row']}>
        <DataTableCell className={styles.text}>{label}</DataTableCell>
        <DataTableCell className={styles.text}>{text}</DataTableCell>
      </DataTableRow>
      );
};

const UIResponseWallet = ({ idTransaction, data, status, user, project, pdfData }) => {
    return (
      <>
        <div className={styles.container}>
          <header>
            <div>
              <div>
                <img className={styles.img}
                     width={'100%'}
                     alt={'energy-logo'}
                     src={logo}/>
              </div>
            </div>
          </header>
          <div>
            <div>
              <div>
                <h4 style={{ color: '#006F97' }}> Respuesta de la Transacción </h4>
                <hr/>
              </div>
              <div className={styles['tables-container']}>
                <div>
                  <DataTable>
                    <DataTableRow className={styles['table-tittle']}>
                      <DataTableCell className={styles.text}>
                        Respuesta de la transacción
                      </DataTableCell>
                    </DataTableRow>
                    <DataTableBody className={styles['table-body']}>
                      <RowData label={'Referencia'} text={idTransaction} />
                      <RowData label={'Fecha'} text={new Date().toLocaleDateString()} />
                      <RowData label={'Respuesta'}
                               text={(status === 200) ? 'Correcta' : 'Internal error'} />
                      <RowData label={'Motivo'} text={'Compra de un proyecto'} />
                      <RowData label={'Total'}
                               text={`${Utils.formatter.format(data?.amount)} COP`} />
                    </DataTableBody>
                  </DataTable>
                </div>
                <div>
                  <DataTable>
                    <DataTableRow className={styles['table-tittle']}>
                      <DataTableCell className={styles.text}>
                        Comprobante de suscripción
                      </DataTableCell>
                    </DataTableRow>
                    <DataTableBody className={styles['table-body']}>
                      <RowData label={'Nombre del inversor:'} text={user.name}/>
                      <RowData label={'c.c. /Nit:'} text={user.nit}/>
                      <RowData label={'Nombre del proyecto:'} text={project.name}/>
                      <RowData label={'Ubicación del proyecto'} text={project.address}/>
                      <RowData label={'Valor de la inversión:'}
                               text={`${Utils.formatter.format(data?.amount)} COP`}/>
                      <RowData label={'N° de paneles digitales comprados:'} text={data?.panels}/>
                      <RowData label={'Interés Estimado E.A. :'} text={'10.3%'}/>
                      <RowData label={'Inicio de etapa de construcción:'}
                               text={`${project.date1}`}/>
                      <RowData label={'Inicio de etapa de operación:'} text={`${project.date2}`}/>
                      <RowData label={'Porcentaje de la administración de FARM ENERGY'}
                               text={`${project.admin}`}/>
                      <RowData label={'Estado de la transacción:'}
                               text={(status === 200) ? 'Pendiente' : 'Internal error'}/>
                    </DataTableBody>
                  </DataTable>
                </div>
              </div>
              <div className={styles['button-container']}>
                <Link to={'/'} style={{ textDecoration: 'none' }}>
                  <Button unelevated
                          className={styles.button}
                          style={{ marginTop: '0.5rem' }}>
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                      <Icon path={mdiHome} size={1}/>
                      volver al inicio
                    </span>
                  </Button>
                </Link>
                <PDFDownloadLink
                  document={
                    <SuscriptionTablePdf
                      data={pdfData}
                      recharge={data.x_description === 'Recharge'}
                      />
                  }
                  fileName={'suscripcion_comprobante'}
                  style={{ textDecoration: 'none' }}
                  >
                  <Button
                    className={styles.button}
                    style={{ marginTop: '0.5rem' }}
                    unelevated
                  >
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                      <Icon path={mdiDownload} size={1} style={{ marginRigth: '0.5rem' }}/>
                      Descargar comprobante
                    </span>
                  </Button>
                </PDFDownloadLink>
              </div>
            </div>
          </div>
        </div>
      </>);
};

export default UIResponseWallet;
