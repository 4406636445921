import React, { useContext, useState, useEffect } from 'react';
import ProjectTab from '../project-tab/project-tab';

import { Button } from '@rmwc/button';
import { Snackbar, SnackbarAction } from '@rmwc/snackbar';
import { useHistory, useParams } from 'react-router-dom';

import { ProjectContext } from '../../../../context/ProjectContext';
import { UserDataContext } from '../../../../context/UserContext';

import DescriptionSection from './sections/description';
import SolarRangeSection from './sections/solardate';
import ProgresSection from './sections/progress';
import GeneralSection from './sections/general';
import GallerySection from './sections/gallery';
import LocationSection from './sections/location';
import AmortizationSection from './sections/amortization';
import Percentage from './sections/percentage';
import Discounts from './sections/discounts';
import Bonification from './sections/bonification';

import ProjectApi from '../../../../api/ProjectApi';

import styles from './manage-project.module.css';

import LoadingEffect from '../../../loading/Loading';

const checkEmptyParams = (projectData) => {
  let allFieldsFilled = false;

  Object.keys(projectData).forEach((data, pos) => {
    if (!projectData[data]) allFieldsFilled = true;
  });

  return allFieldsFilled;
};

// eslint-disable-next-line
const Project = () => {
  const { createProject = {}, createProjectDispatch, projects = [] } = useContext(ProjectContext);
  const { basicUserInformation, user } = useContext(UserDataContext);
  const history = useHistory();

  const [snackMessage, setSnackMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [clean, setClean] = useState(false);
  const { project = {} } = createProject;
  const { projectData = {}, mainPicture = [], auxPicture = [] } = project;
  const { projectId = '' } = useParams();
  const parsePhysicalPanels = parseInt(projectData.noOfPhysicalPanels);
  const parseDigitalPanels = parseInt(projectData.noOfDigitalPanels);

  projectData.powerInstalled = parsePhysicalPanels * Number(projectData.power);
  // eslint-disable-next-line
  projectData.UserMaxPanels = (parseDigitalPanels * parseFloat(projectData.UserPercentagePanels));

  if (!projectData.solar) projectData.solar = {};
  if (!projectData.amortization) projectData.amortization = {};
  if (!projectData.percentage) projectData.percentage = {};
  if (!projectData.discount) projectData.discount = {};
  if (!projectData.bonification) projectData.bonification = {};

  const {
    percentage = {}, solar = {}, amortization = {}, discount = {}, bonification = {},
  } = projectData;

  const [section, setSection] = useState(0);
  useEffect(() => {
    if (projectId && projects.length > 0 && !createProject?.project?.projectData?.address.length) {
      const [auxProjectData = {}] = projects.filter((project) => project.projectId === projectId);

      if (Object.keys(auxProjectData).length > 0) {
        const projectData = { ...auxProjectData, projectData: auxProjectData.project };
        delete projectData.project;
        createProjectDispatch({ type: 'UPDATE_PROJECT', project: projectData });
      } else history.push('/');
    }
      // eslint-disable-next-line
  }, [!projects.length]);

  const saveData = async () => {
    const { mainPicture = [], auxPicture = [], projectData } = project;
    if (!checkEmptyParams(projectData) && auxPicture.length > 0 && mainPicture.length > 0) {
      setClean(true);
      const formData = new FormData();

      formData.append('projectData', JSON.stringify({ ...project.projectData, user: user.email }));
      if (mainPicture.length > 0)formData.append('mainPicture', project.mainPicture[0]);

      (auxPicture || []).forEach((element, pos) =>
        formData.append(`auxPicture_${element.id}`, element.file[0])
      );

      await ProjectApi.saveProject(formData, projectId);
      setOpen(true);
      setSnackMessage('Your Content Has Been Saved');

      setTimeout(() => {
        createProjectDispatch({ type: 'CLEAN_UPDATED_PROJECT', project: {} });
        window.location.replace('/');
        setClean(false);
      }, 3000);
    } else {
      setOpen(true);
      setSnackMessage('Check All The Data Provided');
    }
  };

  if (
    (projectId && projects.length > 0 && createProject?.project?.available)
    || (!projectId && basicUserInformation?.role !== 'user')
  ) {
  return <>
    <div className={styles.container} style={{ pointerEvents: clean ? 'none' : '' }}>
      <div className={styles.content}>
        <h2>Crear/Edita proyecto</h2>
        <hr/>
        <div className={styles.buttonsGrid}>
          <Button className={styles['button-edit']} label={'descripción'}
                  onClick={() => setSection(0)} unelevated/>
          <Button className={styles['button-edit']} label={'general'}
                  onClick={() => setSection(1)} unelevated/>
          <Button className={styles['button-edit']} label={'galería'}
                  onClick={() => setSection(2)} unelevated/>
          <Button className={styles['button-edit']} label={'localización'}
                  onClick={() => setSection(3)} unelevated/>
          <Button className={styles['button-edit']} label={'progreso'}
                  onClick={() => setSection(4)} unelevated/>
          <Button className={styles['button-edit']} label={'rango solar'}
                  onClick={() => setSection(5)} unelevated/>
          <Button className={styles['button-edit']} label={'amortización'}
                  onClick={() => setSection(6)} unelevated/>
          <Button className={styles['button-edit']} label={'porcentajes'}
                  onClick={() => setSection(7)} unelevated/>
          <Button className={styles['button-edit']} label={'descuentos'}
                  onClick={() => setSection(8)} unelevated/>
          <Button className={styles['button-edit']} label={'bonificación'}
                  onClick={() => setSection(9)} unelevated/>
        </div>
        <div className={styles.card}>
          <div style={{ width: '100%' }}>
            <div className={styles.form}>
              { (section === 0)
                  ? <DescriptionSection projectData={projectData}
                                        createProjectDispatch={createProjectDispatch}/>
                : (section === 1)
                  ? <GeneralSection projectData={projectData}
                                    createProjectDispatch={createProjectDispatch}/>
                : (section === 2)
                  ? <GallerySection mainPicture={mainPicture}
                                    createProjectDispatch={createProjectDispatch}
                                    clean={clean}
                                    auxPicture={auxPicture}/>
                : (section === 3)
                  ? <LocationSection projectData={projectData}
                                     createProjectDispatch={createProjectDispatch}/>
                : (section === 4)
                  ? <ProgresSection projectData={projectData}
                                    createProjectDispatch={createProjectDispatch}/>
                : (section === 5)
                  ? <SolarRangeSection solar={solar}
                                       createProjectDispatch={createProjectDispatch}/>
                : (section === 6)
                  ? <AmortizationSection amortization={amortization}
                                         createProjectDispatch={createProjectDispatch}/>
                : (section === 7)
                  ? <Percentage percentage={percentage}
                                createProjectDispatch={createProjectDispatch}/>
                : (section === 8)
                  ? <Discounts data={discount}
                               createProjectDispatch={createProjectDispatch}/>
                : (section === 9)
                  ? <Bonification data={bonification}
                                  createProjectDispatch={createProjectDispatch}/>
                : <></>
              }
            </div>
          </div>
        </div>

        <Button raised style={{
                marginTop: '1rem',
                textAlign: 'center',
                backgroundColor: '#006f97 ',
                }}
                onClick={() => saveData()}>Guardar Información</Button>

      </div>
      <ProjectTab projectData={project}/>
    </div>
    {clean ? <LoadingEffect/> : <></>}
    <Snackbar
      open={open}
      onClose={() => setOpen(false)}
      message={snackMessage}
      dismissesOnAction
      action={ <SnackbarAction label={'Dismiss'}/> }/>
  </>;
  } else if (!projectId && basicUserInformation?.role === 'user') history.push('/');
  else return <LoadingEffect/>;
};

export default Project;
