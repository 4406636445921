import React from 'react';
import styles from './contact.module.css';
import { TextField } from '@rmwc/textfield';
import { Button } from '@rmwc/button';

const Contact = () => {
    return (<div className={styles.container}>
      <h1>contáctanos</h1>
      <hr/>
      <div className={styles.form}>
        <label className={styles.label}>Correo</label>
        <TextField className={styles.email} outlined/>
        <label className={styles.label}>Mensaje</label>
        <TextField className={styles.mensage} rows={5} textarea outlined/>
        <Button className={styles.button} label={'enviar'} unelevated />
      </div>
    </div>);
};
export default Contact;
