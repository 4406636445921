import { api, escalateError, getResponseData } from './index';

export default class CashoutApi {
  static sendCashout(data) {
    return api.post('/cashout', data)
      .then(getResponseData)
      .catch(escalateError);
  }

  static getCashoutRequests() {
    return api.get('/cashout/pending')
      .then(getResponseData)
      .catch(escalateError);
  }

  static updateCashoutRequest(id, data) {
    return api.put(`/cashout/${id}`, data)
      .then(getResponseData)
      .catch(escalateError);
  }
}
