const months = ['january', 'february', 'march', 'april', 'may', 'june',
        'july', 'august', 'september', 'october', 'november', 'december'];
const typePerson = ['farmer', 'maintance', 'administration'];
const bonusOrder = ['0-4', '4-8', '8-12', '12-16', '16-20', '20-24', '24-28',
        '28-32', '32-36', '36-40', '40-44'];

const keys = ['power', 'solar', 'systemEfience', 'panelCost',
    'energyCost', 'percentage', 'annualIncrement',
    'amortization', 'noOfDigitalPanels', 'totalCost',
    'discount', 'beginningDateSeed', 'bonification'];

const sortData = (obj, typeArr) => {
    const ordered = Object.keys(obj)
                          .sort((a, b) => (typeArr.indexOf(a) - typeArr.indexOf(b)))
                          .reduce((objOut, key) => {
                                objOut[key] = obj[key];
                                return objOut;
                            }, {});
    return ordered;
};

const validateDocuments = (data, typeArr) => {
    if (!data) return null;
    let checkFields = 0;
    const missingData = [];
    for (let i = 0; i < typeArr.length; i++) {
        if (data[typeArr[i]]) checkFields += 1;
        else missingData.push(typeArr[i]);
    }
    const isEqual = typeArr.length === checkFields;
    return {
        isEqual,
        missingData,
    };
};

export const convertDataSimulator = (obj) => {
    const { isEqual = false, missingData } = validateDocuments(obj, keys) || {};

    if (!isEqual) {
        return {
            status: isEqual,
            missingData,
            info: {},
        };
    } else {
        const {
            solar, systemEfience, panelCost, powerInstalled,
            energyCost, percentage, annualIncrement,
            amortization, noOfDigitalPanels, totalCost,
            discount, beginningDateSeed, bonification,
        } = obj;

        const ArrDiscount = new Array(Object.keys(discount).length)
                                .fill(0)
                                .map((x, idx) => (`week${idx + 1}`));

        return {
            status: isEqual,
            info: {
                potenciaInstalada: Number(powerInstalled),
                radiacionSolarMensual: sortData(solar, months),
                eficienciaSistema: Number(systemEfience),
                precioPanel: Number(panelCost),
                precioVentaEnergia: Number(energyCost),
                porcentajeAsignaciondeDinero: sortData(percentage, typePerson),
                incrementoTarifaAnual: Number(annualIncrement),
                tasaInflacionAnual: Number(amortization?.ipc),
                cantidadPanelesDisponibles: Number(noOfDigitalPanels),
                costoProyecto: Number(totalCost),
                descuentoPreventa: sortData(discount, ArrDiscount),
                inicioSiembra: beginningDateSeed,
                amortization,
                bonificacionCantidadPaneles: sortData(bonification, bonusOrder),
            },
        };
    }
};
