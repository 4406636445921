import firebase from 'firebase';
import 'firebase/auth';
import firebaseConfig from './firebase-config.json';

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
export const firestore = firebase.firestore();
export const storage = firebase.storage();
