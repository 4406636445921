import React, { useState } from 'react';
import { Button } from '@rmwc/button';
import PropTypes from 'prop-types';

const BpsAuth = ({ qr, isVerifiedAccount }) => {
  const [scanQr, setScanQr] = useState(true);
  return (<>
    <h2>Configura Google Authenticator</h2>
    <ol>
      <li>Descarga la aplicacion de BpsAuth</li>
      <li>Escanea el código QR</li>
    </ol>
    { (!qr)
          ? <p>Cargando QR</p>
          : qr && (<object data={qr}>
            <embed src={qr}></embed>
          </object>)
        }
    <Button label={'¿No puedes escanear el codigo?'}
            onClick={() => setScanQr(!scanQr)} />
  </>);
};

BpsAuth.propTypes = {
  qr: PropTypes.string,
  isVerifiedAccount: PropTypes.bool,
};

export default BpsAuth;
