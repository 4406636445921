/* eslint-disable no-underscore-dangle */
import React from 'react';
import {
  DataTable, DataTableBody, DataTableCell,
  DataTableContent, DataTableHead, DataTableHeadCell,
  DataTableRow
} from '@rmwc/data-table';
import useCashout from '../../hooks/useCashout';
import Utils from '../../utils/Utils';

import styles from './Cashout.module.css';
import { Button } from '@rmwc/button';
import Load from '../../res/Icons/Load';

const Cashout = () => {
  const { cashoutRequest, handleCashout, setRefresh, loading } = useCashout();
  const rtf = new Intl.RelativeTimeFormat('es');

  const getMinutes = (seconds) => {
    const date = new Date();
    const createDate = new Date(seconds * 1000);
    const diff = Math.abs(date.getMinutes() - createDate.getMinutes());
    return diff;
  };
  return (<div className={styles.container}>
    <div className={styles['title-cashout']}>
      <h3>Solicitudes de retiro pendientes</h3>
      <Button raised onClick={() => setRefresh(true)} >
        Recargar
      </Button>
    </div>
    <DataTable>
      <DataTableContent>
        <DataTableHead>
          <DataTableRow>
            <DataTableHeadCell alignMiddle>Usuario</DataTableHeadCell>
            <DataTableHeadCell alignMiddle>Banco</DataTableHeadCell>
            <DataTableHeadCell alignMiddle>Celular</DataTableHeadCell>
            <DataTableHeadCell alignMiddle>Disponible</DataTableHeadCell>
            <DataTableHeadCell alignMiddle>Retiro</DataTableHeadCell>
            <DataTableHeadCell alignMiddle>Costo de transacción</DataTableHeadCell>
            <DataTableHeadCell alignMiddle>Realizado</DataTableHeadCell>
            <DataTableHeadCell alignMiddle>Acción</DataTableHeadCell>
          </DataTableRow>
        </DataTableHead>
        <DataTableBody>
          {
            (cashoutRequest || []).map((cashout) => (
              <DataTableRow key={cashout.transactionId}>
                <DataTableCell>{cashout.user}</DataTableCell>
                <DataTableCell>{cashout.bank.toUpperCase()}</DataTableCell>
                <DataTableCell>{cashout.phoneNumber}</DataTableCell>
                <DataTableCell>{Utils.formatter.format(cashout.balance)}</DataTableCell>
                <DataTableCell>{Utils.formatter.format(cashout.amount)}</DataTableCell>
                <DataTableCell>{Utils.formatter.format(cashout.transactionCost)}</DataTableCell>
                <DataTableCell>
                  {rtf.format((-1 * getMinutes(cashout.date._seconds)), 'minutes')}
                </DataTableCell>
                <DataTableCell>
                  <Button
                    onClick={() => handleCashout(cashout.transactionId, '1')}
                    disabled={loading}>
                    {loading ? <Load /> : 'Aceptar'}
                  </Button>
                  <Button
                    onClick={() => handleCashout(cashout.transactionId, '2')}
                    disabled={loading}>
                    {loading ? <Load /> : 'Rechazar'}
                  </Button>
                </DataTableCell>
              </DataTableRow>))
          }
        </DataTableBody>
      </DataTableContent>
    </DataTable>
  </div>);
};

export default Cashout;
