export const profileReducer = (state, action) => {
    switch (action.type) {
        case 'ADD_USER':
            return { ...state, user: { ...state.user, ...action.user } };
        case 'ADD_DOCUMENT':
            return { ...state, documents: { ...state.documents, ...action.documents } };
        case 'REMOVE_DOCUMENT':
            return state;
        default:
            return state;
    }
};
