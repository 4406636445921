/* eslint-disable no-underscore-dangle */
import React from 'react';
import styles from '../description.module.css';
import Icon from '@mdi/react';
import { mdiCheckBold, mdiCheckboxBlankCircleOutline } from '@mdi/js';
import PropTypes from 'prop-types';
import { Tooltip } from '@rmwc/tooltip';

// eslint-disable-next-line react/prop-types
const StepConditional = ({ step, status, steps = [] }) => {
  if (step === status + 1 && step !== steps.length) {
    return <Icon path={mdiCheckboxBlankCircleOutline} size={1} />;
  } else if (status >= step) return <Icon path={mdiCheckBold} size={1} />;
  else return <></>;
};

// eslint-disable-next-line react/prop-types
const Stepper = ({ status = 0 }) => {
  const steps = [0, 1, 2, 3];

  return (
    <div className={styles['time-line']}>
      {steps.map((step) => {
        return (
          <>
            {step > 0 ? <p className={styles.linea}></p> : <></>}
            <p className={styles.cuadrado}>
              <StepConditional step={step} status={status} steps={steps}/>
            </p>
          </>
        );
      })}
    </div>
  );
};
// eslint-disable-next-line react/prop-types
const AdvancesText = ({ base, text, content }) => {
  return (
    <>
      <Tooltip content={content}>
        <h5>
          {base}
          <span>{text}</span>
        </h5>
      </Tooltip>
    </>
  );
};

const dateFormater = (date) => new Date(date).toLocaleDateString('US');

const ProjectProgress = ({ project, projectId, nextTittle }) => {
  const launchDate = dateFormater((project.creationDate?._seconds || 0) * 1000);
  const beginningDate = dateFormater(project?.project?.beginningDate);
  const beginningDateSeed = project.isCrowdfunding
    ? '--'
    : dateFormater(project?.project?.beginningDateSeed);
  const beginningDateHarvest = project.isCrowdfunding
    ? '--'
    : dateFormater(project?.project?.beginningDateHarvest);
  return (
    <div className={styles.advances}>
      <h3>Avance del proyecto</h3>
      <div className={styles.flex}>
        <Stepper status={project?.project?.projectStatus}/>
        <div className={styles['advances-text']}>
          <AdvancesText base={'Lanzamiento: '} text={launchDate}
                        content={'informacion lanzamiento'}/>
          <AdvancesText base={'Siembra: '} text={beginningDate}
                        content={'informacion siembra'}/>
          <AdvancesText base={'Cultivo: '} text={beginningDateSeed}
                        content={'informacion cultivo'}/>
          <AdvancesText base={'Cosecha: '} text={beginningDateHarvest}
                        content={'informacion cosecha'}/>
        </div>
      </div>
      {(nextTittle) ? <h3>Ubicacion</h3> : '' }
    </div>
  );
};

ProjectProgress.propTypes = {
  project: PropTypes.object.isRequired,
  projectId: PropTypes.string,
  nextTittle: PropTypes.bool,
};

export default ProjectProgress;
