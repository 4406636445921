import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from '@rmwc/button';
import { Select } from '@rmwc/select';
import { TextField } from '@rmwc/textfield';
import { Snackbar, SnackbarAction } from '@rmwc/snackbar';
import CustomTextField from '../../UI/CustomTextField/CustomTextField';
import ReadingsTable from './ReadingsTable';
import LoadingEffect from '../../loading/Loading';
import { ProjectContext } from '../../../context/ProjectContext';
import { UserDataContext } from '../../../context/UserContext';
import ProjectApi from '../../../api/ProjectApi';
import useProjectRecords from '../../../hooks/useProjectRecords';
import styles from '../manage-projects/manage-project/manage-project.module.css';

const ReadingsProject = () => {
  const { projects = [] } = useContext(ProjectContext);
  const {
    setProjectId, projectId, setPeriod,
    records, error, loading, period, weeksMonth,
  } = useProjectRecords();
  const { basicUserInformation } = useContext(UserDataContext);
  const { projectId: projectIdFromUrl } = useParams();
  const [snackMessage, setSnackMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [registryId, setRegistryId] = useState('');
  const [projectList, setProjectList] = useState([]);
  const [columnClicked, setColumnClicked] = useState('0');
  const [activeProduced, setActiveProduced] = useState('0');
  const [activeImported, setActiveImported] = useState('0');
  const [activeExported, setActiveExported] = useState('0');
  const [reactiveImported, setReactiveImported] = useState('0');
  const [reactiveExported, setReactiveExported] = useState('0');
  const [activeSold, setActiveSold] = useState('0');
  const [reactiveSold, setReactiveSold] = useState('0');
  const [reactiveCost, setReactiveCost] = useState('0');
  const [activeCost, setActiveCost] = useState('0');

  useEffect(() => {
    const date = new Date();
    const month = (date.getMonth() + 1).toString();
    setPeriod(`${date.getFullYear()}-${month.length <= 1 ? `0${month}` : month}`);
    setProjectId(projectIdFromUrl);
    setProjectList((projects.map(project => ({
      value: project.projectId,
      label: project.project.projectName,
    }))));
    // eslint-disable-next-line
  }, [projects, projectIdFromUrl]);

  useEffect(() => {
    setActiveSold(Number(activeProduced) + Number(activeImported) - Number(activeExported));
    setReactiveSold(Number(reactiveExported) - Number(reactiveImported));
  }, [reactiveExported, reactiveImported, activeProduced, activeImported, activeExported]);

  useEffect(() => {
    if (!records || Object.keys(records).length === 0) {
      setSnackMessage('No hay registros para mostrar');
      setOpen(true);
      return;
    }
    setActiveProduced(records.activeProduced[columnClicked]);
    setActiveImported(records.activeImported[columnClicked]);
    setActiveExported(records.activeExported[columnClicked]);
    setReactiveImported(records.reactiveImported[columnClicked]);
    setReactiveExported(records.reactiveExported[columnClicked]);
    setReactiveCost(records.reactiveCost[columnClicked]);
    setActiveCost(records.activeCost[columnClicked]);
  }, [records, columnClicked]);

  useEffect(() => {
    if (!error) return;
    setSnackMessage(error);
    setOpen(true);
  }, [error]);

  const saveData = () => {
    const { user } = basicUserInformation;
    if (!activeProduced || !activeImported
      || !activeExported || !activeCost
      || !reactiveImported || !reactiveExported
      || !reactiveCost) {
      setSnackMessage('Por favor, diligencia el formulario');
      setOpen(true);
      return;
    }

    records.activeProduced[columnClicked] = activeProduced;
    records.activeImported[columnClicked] = activeImported;
    records.activeExported[columnClicked] = activeExported;
    records.activeCost[columnClicked] = activeCost;
    records.activeSold[columnClicked] = activeSold;
    records.reactiveImported[columnClicked] = reactiveImported;
    records.reactiveExported[columnClicked] = reactiveExported;
    records.reactiveCost[columnClicked] = reactiveCost;
    records.reactiveSold[columnClicked] = reactiveSold;

    ProjectApi.createProjectRegistry({
      registryId: records?.registryId || registryId || '',
      projectId,
      period,
      registries: records,
      email: user.email,
    })
    .then((response) => {
      const { data } = response;
      setRegistryId(data);
      setSnackMessage('Registros guardados correctamente');
    })
    .catch((error) => setSnackMessage(error.message))
    .finally(() => setOpen(true));
  };

  return (<>
    <div className={styles.container} style={{ display: loading && 'none' }}>
      <div className={styles.content}>
        <h2>Lecturas del proyecto</h2>
        <hr/>
        <div className={styles.card}>
          <div style={{ width: '100%' }}>
            <div className={styles['grid-layout']}>
              <Select label={'Proyecto'} options={projectList}
                      value={projectId} onChange={(e) => setProjectId(e.target.value)}/>
              <TextField label={'Fecha'} type={'month'}
                         value={period} onChange={(e) => setPeriod(e.target.value)} />
            </div>
            <div className={styles.form}>
              <div className={styles['section-container']}>
                <div className={styles['grid-layout']}>
                  <CustomTextField className={styles.custom_text_field} type={'number'}
                                   label={'Energía activa Producida'}
                                   value={activeProduced} min={0} required
                                   onChange={(e) => setActiveProduced(e.target.value)}/>
                  <CustomTextField label={'Energía activa importada'}
                                   className={styles.custom_text_field}
                                   onChange={(e) => setActiveImported(e.target.value)}
                                   value={activeImported} required
                                   type={'number'} min={0}/>
                  <CustomTextField label={'Energía activa exportada'} type={'number'}
                                   className={styles.custom_text_field}
                                   min={0} required
                                   onChange={(e) => setActiveExported(e.target.value)}
                                   value={activeExported}/>
                  <CustomTextField label={'Energía activa vendida'} type={'number'}
                                   className={styles.custom_text_field} disabled
                                   onChange={(e) => setActiveSold(e.target.value)}
                                   min={0} value={activeSold} />
                  <CustomTextField label={'Energía reactiva importada'} type={'number'}
                                   className={styles.custom_text_field} required
                                   onChange={(e) => setReactiveImported(e.target.value)}
                                   min={0} value={reactiveImported}/>
                  <CustomTextField label={'Energía reactiva exportada'}
                                   dispatchType={'UPDATE_PROJECT'}
                                   onChange={(e) => setReactiveExported(e.target.value)}
                                   value={reactiveExported} required
                                   className={styles.custom_text_field}
                                   min={0} type={'number'}/>
                  <CustomTextField label={'Energía reactiva vendida'}
                                   onChange={(e) => setReactiveSold(e.target.value)}
                                   value={reactiveSold} disabled
                                   className={styles.custom_text_field}
                                   min={0} type={'number'}/>
                </div>
                <CustomTextField label={'Costo de la energía activa'} type={'number'}
                                 icon={'money'} className={styles.custom_text_field}
                                 onChange={(e) => setActiveCost(e.target.value)}
                                 value={activeCost} min={0} required/>
                <CustomTextField label={'Costo de energía reactiva'} required
                                 icon={'money'} className={styles.custom_text_field}
                                 onChange={(e) => setReactiveCost(e.target.value)}
                                 value={reactiveCost} placeholder={'20'}
                                 min={0} type={'number'}/>
              </div>
            </div>
          </div>
        </div>
        <Button raised style={{
                marginTop: '1rem',
                textAlign: 'center',
                backgroundColor: '#006f97 ',
                }}
                disabled={!projectId || !period}
                onClick={() => saveData()}>Guardar Información</Button>

      </div>
      <ReadingsTable labels={weeksMonth} projectData={records}
                     onClick={(e) => setColumnClicked(e)}/>
    </div>
    {loading && <LoadingEffect />}
    <Snackbar
      open={open}
      onClose={() => setOpen(false)}
      message={snackMessage}
      dismissesOnAction
      action={ <SnackbarAction label={'Dismiss'}/> }/>
  </>);
};

export default ReadingsProject;
