import React, { useContext } from 'react';
import { Drawer, DrawerContent, DrawerHeader } from '@rmwc/drawer';
import { List, ListItem, ListItemGraphic, ListItemText, CollapsibleList } from '@rmwc/list';
import useAnalytics from '../../../hooks/useAnalytics';
import { IconButton } from '@rmwc/icon-button';
import DrawerContext from './DrawerContext';
import MDIcon from '../MDIcon';
import Divider from '../Divider';
import { Link } from 'react-router-dom';
import styles from './drawer.module.css';
import
{
  mdiWalletOutline,
  mdiReload,
  mdiCurrencyUsd,
  mdiWeb,
  mdiMagnify
} from '@mdi/js';
import Icon from '@mdi/react';

import { UserDataContext } from '../../../context/UserContext';

const DrawerWrapper = () => {
  const { drawerOpen, setDrawerOpen } = useContext(DrawerContext);
  const { basicUserInformation } = useContext(UserDataContext);
  const { trackEvent } = useAnalytics('sideBar');

  return (
    <Drawer modal open={drawerOpen} onClose={() => setDrawerOpen(false)}
            className={styles.sidebar}>
      <DrawerHeader style={{ paddingLeft: '.4rem' }}>
        <IconButton onClick={() => setDrawerOpen(false)} style={{ marginTop: '.4rem' }}>
          <MDIcon icon={'close'}/>
        </IconButton>
      </DrawerHeader>
      <Divider/>
      <DrawerContent>
        <List className={styles['sidebar-list']}>
          <Link to={'/'} onClick={() => trackEvent('click', 'inicio')}>
            <ListItem>
              <ListItemGraphic>
                <MDIcon icon={'home-outline'}/>
              </ListItemGraphic>
              <ListItemText>Inicio</ListItemText>
            </ListItem>
          </Link>
          <Link to={'/profile'} onClick={() => trackEvent('click', 'perfil')}>
            <ListItem>
              <ListItemGraphic>
                <MDIcon icon={'account-outline'}/>
              </ListItemGraphic>
              <ListItemText>Perfil</ListItemText>
            </ListItem>
          </Link>
          <Link to={'/wallet'} onClick={() => trackEvent('click', 'billetera')}>
            <ListItem>
              <ListItemGraphic>
                <Icon className={styles['list-icon']} path={mdiWalletOutline} size={1}/>
              </ListItemGraphic>
              <ListItemText>Billetera</ListItemText>
            </ListItem>
          </Link>
          <Link to={'/projects'} onClick={() => trackEvent('click', 'buscar proyectos')}>
            <ListItem>
              <ListItemGraphic>
                <Icon className={styles['list-icon']} path={mdiMagnify} size={1}/>
              </ListItemGraphic>
              <ListItemText>Buscar proyectos</ListItemText>
            </ListItem>
          </Link>
          <Link to={'/myProjects'} onClick={() => trackEvent('click', 'mis inversiones')}>
            <ListItem>
              <ListItemGraphic>
                <MDIcon icon={'book-outline'}/>
              </ListItemGraphic>
              <ListItemText>Mis Inversiones</ListItemText>
            </ListItem>
          </Link>
          <Link to={'/recharge'} onClick={() => trackEvent('click', 'mis recargas')}>
            <ListItem>
              <ListItemGraphic>
                <Icon className={styles['list-icon']} path={mdiReload} size={1}/>
              </ListItemGraphic>
              <ListItemText>Mis recargas</ListItemText>
            </ListItem>
          </Link>
          <Link to={'/withDraw'} onClick={() => trackEvent('click', 'retirar saldo')}>
            <ListItem>
              <ListItemGraphic>
                <Icon className={styles['list-icon']} path={mdiCurrencyUsd} size={1}/>
              </ListItemGraphic>
              <ListItemText>Retirar saldo</ListItemText>
            </ListItem>
          </Link>
          {basicUserInformation?.role !== 'user'
          ? <CollapsibleList
              handle={
                <ListItem>
                  <ListItemGraphic>
                    <MDIcon icon={'book-outline'}/>
                  </ListItemGraphic>
                  <ListItemText>Gestión de Proyectos</ListItemText>
                </ListItem>
              }>
            <Link to={'/manage-projects'}>
              <ListItem>
                <ListItemGraphic>
                  <MDIcon icon={'book-outline'}/>
                </ListItemGraphic>
                <ListItemText>Gestionar Proyecto</ListItemText>
              </ListItem>
            </Link>
            <Link to={'/crowdfunding-projects'}>
              <ListItem>
                <ListItemGraphic>
                  <MDIcon icon={'folder-plus'}/>
                </ListItemGraphic>
                <ListItemText>Proyecto de financiación</ListItemText>
              </ListItem>
            </Link>
          </CollapsibleList>
          : <></>}
          {basicUserInformation?.role !== 'user'
          ? <Link to={'/cashout'}>
            <ListItem>
              <ListItemGraphic>
                <MDIcon icon={'cash'}/>
              </ListItemGraphic>
              <ListItemText>Gestionar retiros</ListItemText>
            </ListItem>
          </Link>
          : <></>}
          {basicUserInformation?.role !== 'user'
          ? <Link to={'/landinPage'}>
            <ListItem>
              <ListItemGraphic>
                <Icon className={styles['list-icon']} path={mdiWeb} size={1}/>
              </ListItemGraphic>
              <ListItemText>Landing Page</ListItemText>
            </ListItem>
          </Link>
          : <></>}
          <Divider/>
        </List>
      </DrawerContent>
    </Drawer>
  );
};

export default DrawerWrapper;
