import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './LastRecharge.module.css';
import {
    DataTable,
    DataTableContent,
    DataTableHead,
    DataTableRow,
    DataTableHeadCell,
    DataTableBody,
    DataTableCell
} from '@rmwc/data-table';
import { TextField } from '@rmwc/textfield';
import { UserDataContext } from '../../context/UserContext';
import Utils from '../../utils/Utils';

const LastRecharge = ({ isCashout = false }) => {
  const responses = {
    1: 'Aceptada',
    2: 'Rechazada',
    3: 'Pendiente',
    4: 'Fallida',
    6: 'Reversada',
    11: 'Cancelada',
  };
  const { userTransactions } = useContext(UserDataContext);
  const [firstDate, setFirstDate] = useState('');
  const [lastDate, setLastDate] = useState('');
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    setTransactions(userTransactions.filter((transaction) =>
    transaction.projectId === 'personal'));
  }, [userTransactions]);

  useEffect(() => {
    let transactionCopy = userTransactions.filter((transaction) =>
      isCashout
        ? (transaction.transactionType === '10' && transaction.projectId === 'personal')
        : (transaction.transactionType === '1' && transaction.projectId === 'personal'));
      if (firstDate) {
        transactionCopy = transactionCopy.filter((transaction) => {
          const [date] = new Date(
            // eslint-disable-next-line no-underscore-dangle
            transaction.date._seconds * 1000
          ).toISOString()
            .split('T');
          return date >= firstDate && date <= lastDate;
        });
      }
    setTransactions(transactionCopy);
    // eslint-disable-next-line
  }, [firstDate, lastDate, userTransactions]);

  return (
    <>
      <h3>Últimos movimientos</h3>
      <div className={styles['select-container']}>
        <div className={styles['select-one']}>
          <TextField fullwidth label={'Desde'}
                     type={'date'}
                     value={firstDate}
                     onChange={(e) => setFirstDate(e.target.value)}/>
        </div>
        <div className={styles['select-two']}>
          <TextField fullwidth label={'Hasta'}
                     type={'date'}
                     value={lastDate}
                     disabled={!firstDate}
                     onChange={(e) => setLastDate(e.target.value)}/>
        </div>
      </div>
      <div className={styles['desktop-table']}>
        <div className={styles['data-table-container']}>
          <DataTable className={styles['data-table']}>
            <DataTableContent>
              <DataTableHead className={styles['data-table-head']}>
                <DataTableRow>
                  <DataTableHeadCell alignStart>Estado</DataTableHeadCell>
                  <DataTableHeadCell alignStart>Fecha</DataTableHeadCell>
                  <DataTableHeadCell alignStart>Monto</DataTableHeadCell>
                </DataTableRow>
              </DataTableHead>
              <DataTableBody className={styles['data-table-body']}>
                {
                  (transactions.length > 0)
                  && transactions.map((transaction, key) => {
                    const [date] = new Date(
                      // eslint-disable-next-line no-underscore-dangle
                      transaction.date._seconds * 1000
                    ).toISOString()
                      .split('T');
                    return (<DataTableRow key={key}>
                      <DataTableCell alignStart>
                        {responses[transaction.responseCode]}
                      </DataTableCell>
                      <DataTableCell className={styles['data-table-grey']} alignStart>
                        {date}
                      </DataTableCell>
                      <DataTableCell className={styles['data-table-grey']} alignStart>
                        {Utils.formatter.format(transaction.amount)} COP
                      </DataTableCell>
                    </DataTableRow>);
                  })
                }
              </DataTableBody>
            </DataTableContent>
          </DataTable>
        </div>
      </div>
      {
        (transactions.length > 0)
        && transactions.map((transaction, key) => {
          const [date] = new Date(
            // eslint-disable-next-line no-underscore-dangle
            transaction.date._seconds * 1000
          ).toISOString()
            .split('T');
          return (<div key={key} className={styles['phone-table']}>
            <div className={styles['first-column']}>
              <p>Estado</p>
              <p>Fecha</p>
              <p>Monto</p>
            </div>
            <div className={styles['second-column']}>
              <p>{responses[transaction.responseCode]}</p>
              <p>{date}</p>
              <p className={styles.amount}> {Utils.formatter.format(transaction.amount)} COP</p>
            </div>
          </div>);
        })
      }
    </>
  );
};

LastRecharge.propTypes = {
  isCashout: PropTypes.bool,
};

export default LastRecharge;
