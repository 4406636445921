import React from 'react';
import Toolbar from '../mdc/toolbar/Toolbar';
import SiteBody from '../mdc/SiteBody';
import DrawerWrapper from '../mdc/drawer/DrawerWrapper';
import Cashout from '../manageCashout/Cashout';

const ManageCashout = () => {
  return (<>
    <Toolbar/>
    <DrawerWrapper/>
    <SiteBody>
      <Cashout/>
    </SiteBody>
  </>);
};

export default ManageCashout;
