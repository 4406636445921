import { useEffect, useState } from 'react';
import CashoutApi from '../api/CashoutApi';

const useCashout = () => {
  const [cashoutRequest, setCashoutRequest] = useState('');
  const [message, setMessage] = useState('');
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    CashoutApi.getCashoutRequests()
    .then(({ data }) => {
      const { cashouts = [] } = data;
      setCashoutRequest(cashouts);
    })
    .catch((error) => setMessage(error.message))
    .finally(() => {
      setLoading(false);
      setRefresh(false);
    });
    // eslint-disable-next-line
  }, [refresh]);

  const handleCashout = async (id, status) => {
    setLoading(true);
    try {
      CashoutApi.updateCashoutRequest(id, { status })
        .then(() => {
          const newCashoutRequest = cashoutRequest.filter(
            (cashout) => cashout.transactionId !== id);
          setCashoutRequest(newCashoutRequest);
        })
        .catch(() => {})
        .finally(() => setLoading(false));
    } catch (error) {
      setMessage(error.message);
    }
  };

  return {
    setRefresh,
    cashoutRequest,
    handleCashout,
    loading,
    message,
  };
};

export default useCashout;
