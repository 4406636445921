/* eslint-disable react/prop-types */
import React, { useState, useContext } from 'react';
import { Button } from '@rmwc/button';
import { Snackbar } from '@rmwc/snackbar';
import Icon from '@mdi/react';
import {
  mdiAccount, mdiQrcode, mdiFileDocumentMultipleOutline
} from '@mdi/js';

import PersonalData from './personal-data/PersonalData';
import Documents from './documents/Documents';
import TwoFAuthentication from '../2FAuthentication/TwoFAuthentication';
import ProfileDescription from './profile-description/ProfileDescription';

import LoadingEffect from '../loading/Loading';

import styles from './profile.module.css';

import { ProfileContext } from '../../context/ProfileContext';
import { UserDataContext } from '../../context/UserContext';
import UserApi from '../../api/UserApi';

const Profile = () => {
    const [section, setSection] = useState(0);
    const {
       open, setOpen, snackMessage, profileSectionAction,
       setSaveProfileData, exist, profile = {}, setTransactionId,
      } = useContext(ProfileContext);

    const { basicUserInformation } = useContext(UserDataContext);
    const { tfaProvider } = basicUserInformation;

    const saveData = async () => {
      const { documents, user } = profile;
      const formData = new FormData();
      const contentType = Object.keys(documents).length > 0
        ? 'multipart/form-data'
        : 'application/json';
      if (Object.keys(documents).length > 0) {
        Object.keys(documents).forEach((key) =>
          formData.append(key, documents[key][0]));
      }

      formData.append('user', JSON.stringify(user));
      return UserApi.saveUserDocuments(formData, contentType);
    };

    return (<>
      {((exist && !profile.user.uploadedDocuments) || !profile?.user)
      ? <div className={styles.container} style={{ margin: 'auto' }}>
        <div className={styles.steps}>
          <Button raised disabled={(section !== 0)}
                  onClick={() => setSection(0)} className={styles['step-button']}>
            <Icon path={mdiAccount} className={styles['step-icon']} size={0.7}/>
            Datos Personales
          </Button>
          <Button raised disabled={(section !== 1)}
                  onClick={() => setSection(1)} className={styles['step-button']}>
            <Icon path={mdiFileDocumentMultipleOutline} className={styles['step-icon']} size={0.7}/>
            Documentos
          </Button>
          <Button raised disabled={(section !== 2)}
                  onClick={() => setSection(2)} className={styles['step-button']}>
            <Icon path={mdiQrcode} className={styles['step-icon']} size={0.8}/>
            Autenticación Biometrica
          </Button>
        </div>
        <hr/>

        {(section === 0)
        ? <PersonalData />
        : (section === 1)
        ? <Documents />
        : <TwoFAuthentication provider={tfaProvider} isValid={(event) => {
          const { comeBack, verificationStatus, transactionId } = event;
          if (comeBack) return setSection(1);
          setTransactionId(transactionId);
          if (verificationStatus) setSaveProfileData(verificationStatus);
          return '';
        }} isPayment={false}/>}
        {(section === 1 || section === 0)
          ? <div className={styles.buttons}>
            <Button style={{ marginRight: 'auto' }} disabled={(section === 0)}
                    className={styles['buttons-next']}
                    raised onClick={() => setSection(section - 1)}>
              Anterior
            </Button>
            <Button style={{ marginLeft: 'auto' }}
                    className={styles['buttons-next']}
                    raised onClick={() => {
                    if (section !== 2 && (profileSectionAction(section))) {
                      setSection(section + 1);
                      saveData();
                    } else setSaveProfileData(true);
                  }} label={(section !== 2) ? 'Siguiente' : 'Guardar Datos'}/>
          </div>
          : ''
        }
      </div>
      : <div className={styles.description}>
        {profile?.user && profile?.documents
        ? <>
          <ProfileDescription/>
        </>
        : <LoadingEffect/> }
      </div>
      }
      <Snackbar
          open={open}
          onClose={evt => setOpen(false)}
          message={snackMessage}
          dismissesOnAction/>
    </>);
};

export default Profile;
