import React from 'react';
import PropTypes from 'prop-types';

const pattern = /[\\<\\>\\?]/g;

const NumberValidator = ({ required = false, value = '' }) => {
  if (value.match(pattern) || (!value.trim() && required)) {
    return (
      <span style={{ color: '#FF6347', fontSize: 'small' }}>
        El valor ingresado no es valido
      </span>);
  } else return <></>;
};

NumberValidator.propTypes = {
    required: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
};

export default NumberValidator;
