import ReactGA from 'react-ga';

const useAnalytics = (category = 'Event category') => {
  const trackingCode = process.env.REACT_APP_GA_TRACKING_CODE;
  ReactGA.initialize(trackingCode);

  const trackEvent = (action = 'accion', label = '') => {
    ReactGA.event({ category, action, label });
  };

  const trackViewPage = () => ReactGA.pageview(window.location.pathname);

  return {
    trackEvent,
    trackViewPage,
  };
};

export default useAnalytics;
