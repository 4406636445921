/* eslint-disable max-lines */
/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { TextField } from '@rmwc/textfield';
import styles from './Graphic.module.css';
import { mdiRectangle } from '@mdi/js';
import Icon from '@mdi/react';
import { ProjectContext } from '../../../../../context/ProjectContext';
import Utils from '../../../../../utils/Utils';
import calculateValuesGraphic from './calculateValuesGrafic';
import { convertDataSimulator } from './converDataSimulator';
import { Button } from '@rmwc/button';
import { UserDataContext } from '../../../../../context/UserContext';
import TableAdminSection from './TableAdminSection';

const options = {
  responsive: true,
  interaction: {
    mode: 'index',
    intersect: false,
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        display: false,
      },
      ticks: {
        callback(value) {
          return value <= 1 ? '' : `${Utils.formatterEmpty.format(value)} COP`;
        },
      },
    },
  },
};
// eslint-disable-next-line react/prop-types
const Graphic = ({ bg, project: dataProject }) => {
  const {
    panels = 0,
    setPanels,
    setDataFormProject,
  } = useContext(ProjectContext);
  const { basicUserInformation } = useContext(UserDataContext);
  const [open, setOpen] = useState(false);

  let dataSimulator = {};
  // eslint-disable-next-line react/prop-types
  const { status, info } = convertDataSimulator(dataProject.project);
  if (status) {
    const dataMock = {
      ...info,
      cantidadPanelesParcela: panels,
    };
    const dataResult = calculateValuesGraphic(dataMock);
    dataSimulator = dataResult;
  }

    // eslint-disable-next-line react/prop-types
    const maxPanels = Math.ceil(dataProject?.project?.noOfDigitalPanels * 0.44);

  useEffect(() => {
    if (Object.keys(dataSimulator).length) {
      setDataFormProject(dataSimulator);
    }
    // eslint-disable-next-line
  }, [panels]);

  const validateData = !Object.keys(dataSimulator).length
    ? []
    : dataSimulator.dataSimulator;

  const data = {
    labels: Array(20)
      .fill(0)
      .map((x, idx) => `${idx + 1}`),
    datasets: [
      {
        label: 'Ganacia Neta',
        stack: 'Stack 0',
        backgroundColor: '#2ad2c5',
        minBarLength: panels && validateData[0]?.bonus > 0 ? 5 : 0,
        maxBarThickness: 25,
        data: validateData.map((e) => Number(e?.netIncome.toFixed(2))),
      },
      {
        label: 'Bonificación',
        stack: 'Stack 0',
        backgroundColor: '#006f97',
        maxBarThickness: 25,
        minBarLength: panels && validateData[0]?.bonus > 0 ? 3 : 0,
        data: validateData.map((e) => Number(e?.bonus.toFixed(2))),
      },
      {
        label: 'Abono Capital',
        stack: 'Stack 0',
        maxBarThickness: 25,
        data: validateData.map((e) => Number(e?.capitalPayment.toFixed(2))),
        backgroundColor: '#c7c7c7',
      },
    ],
  };

  const numberFormatter = (number) => Math.round(parseFloat(number));

  const desc
    = dataProject?.project?.panelCost * panels - dataSimulator?.farmerInvestment;

  return (
    <div className={styles['simulator-container']}>
      <div className={bg ? styles['container-background'] : ''}></div>
      <h3>Simulador de inversión</h3>
      <div className={styles['form-control']}>
        <TableAdminSection open={open} setOpen={setOpen} data={dataSimulator} />
        <h5>¿Cuántos paneles quieres?</h5>
        <TextField
          className={styles['control-text-field']}
          value={panels}
          outlined
          onKeyPress={(e) => {
            if (!(e.key >= 0 && e.key <= 9)) e.preventDefault();
          }}
          onChange={(e) =>
            e.target.value <= maxPanels && e.target.value >= 0
              ? setPanels(e.target.value)
              : panels
          }
        />
        {basicUserInformation?.role !== 'user' ? (
          <Button
            label={'Tables'}
            outlined
            onClick={(e) => {
              setOpen(!open);
            }}
          />
        ) : (
          <></>
        )}
      </div>
      <div className={styles['sum-container']}>
        <div className={styles['values-title']}>
          <h4>Costos</h4>
        </div>
        <h5 className={styles['value-first-element']}>
          <span>
            <Icon
              className={styles['cyan-blue']}
              path={mdiRectangle}
              size={1}
            />
            Precio Normal
          </span>
          <span>
            {`${Utils.formatterEmpty.format(
              numberFormatter(dataProject?.project?.panelCost * panels)
            )} COP`}
          </span>
        </h5>
        <h5>
          <span>
            <Icon
              className={styles['dark-blue']}
              path={mdiRectangle}
              size={1}
            />
            Descuento
          </span>
          <span>
            {`${Utils.formatterEmpty.format(numberFormatter(desc || 0))} COP`}
          </span>
        </h5>
        <hr />
        <h5>
          <span>
            <Icon
              className={`${styles.transparent} ${styles.spacing}`}
              path={mdiRectangle}
              size={1}
            />
            Costo Parcela
          </span>
          <span>
            {`${Utils.formatterEmpty.format(
              numberFormatter(dataSimulator?.farmerInvestment || 0)
            )} COP`}
          </span>
        </h5>
        <div className={styles['values-title']}>
          <h4>Beneficios</h4>
        </div>
        <h5 className={styles['value-first-element']}>
          <span>
            <Icon
              className={styles['cyan-blue']}
              path={mdiRectangle}
              size={1}
            />
            Total Ganancia Neta
          </span>
          <span>
            {`${Utils.formatterEmpty.format(
              numberFormatter(dataSimulator?.totalNetIncome || 0)
            )} COP`}
          </span>
        </h5>
        <h5>
          <span>
            <Icon
              className={styles['dark-blue']}
              path={mdiRectangle}
              size={1}
            />
            Total de Bonificación
          </span>
          <span>
            {`${Utils.formatterEmpty.format(
              numberFormatter(dataSimulator?.totalBonus || 0)
            )} COP`}
          </span>
        </h5>
        <h5>
          <span>
            <Icon
              className={styles['light-grey']}
              path={mdiRectangle}
              size={1}
            />
            Total de Abono Capital
          </span>
          <span>
            {`${Utils.formatterEmpty.format(
              numberFormatter(dataSimulator?.totalCapitalPayment || 0)
            )} COP`}
          </span>
        </h5>
        <hr />
        <h5>
          <span>
            <Icon className={styles.transparent} path={mdiRectangle} size={1} />
            Ingresos Totales
          </span>
          <span>
            {`${Utils.formatterEmpty.format(
              numberFormatter(dataSimulator?.totalCashFlow || 0)
            )} COP`}
          </span>
        </h5>
      </div>
      <Bar
        className={styles['graphic-end']}
        data={data}
        options={options}
        size={32}
      />
    </div>
  );
};

export default Graphic;
