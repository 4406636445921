import React, { useContext } from 'react';
import FileInput from '../../file-input/FileInput';
import { ProfileContext } from '../../../context/ProfileContext';

import styles from './document.module.css';

const Documents = () => {
  const { dispatch, profile, setSnackMessage, setOpen } = useContext(ProfileContext);
  const { documents = {}, user = {} } = profile;
  const objContext = { documents, setSnackMessage, setOpen };

  if (user.typePerson === '1') {
    delete documents.existence;
    delete documents.legal;
  }

    return <div className={styles.documents}>
      <div className={styles.content}>
        <div className={styles.content_1}>
          <h2>Verifica tus datos</h2>
          <p>¿Por qué es necesario este proceso?</p>
          <p>Desde la parte jurídica nos permite:</p>
          <ul>
            <li>
              La correcta celebración de contratos.
            </li>
            <li>
              Un adecuado manejo del dinero al momento de transferir las utilidades
              de los proyectos en los que participes.
            </li>
            <li>
              Corroborar que efectivamente seas el titular de la cuenta bancaria que registres.
            </li>
            <li>
              Verificar que no hayas cometido delitos económicos, ya que por ley
              no podrías celebrar un contrato CMC.
            </li>
            <li>
              Prevenir el lavado de activos y financiamiento al terrorismo.
            </li>
          </ul>
        </div>
        <div className={styles.content_2}>
          <form className={styles.file__documents__input}>
            <h2>Carga tus documentos</h2>
            <FileInput label={'Cedula de ciudadania'} name={'nit'} id={'nits'}
                       onFilesChosen={(data) =>
                        dispatch({ type: 'ADD_DOCUMENT', documents: { nit: data } })}
                       validate={documents.nit}
                       context={objContext}
                        />
            <FileInput label={'RUT'} name={'rut'} id={'rut'}
                       onFilesChosen={(data) =>
                        dispatch({ type: 'ADD_DOCUMENT', documents: { rut: data } })}
                       validate={documents.rut}
                       context={objContext}
                        />
            <FileInput label={'Declaración de bienes'} name={'declaration'} id={'declaration'}
                       onFilesChosen={(data) =>
                        dispatch({ type: 'ADD_DOCUMENT', documents: { declaration: data } })}
                       validate={documents.declaration}
                       context={objContext}
                        />
            <FileInput label={'Ultima declaración de renta'} name={'incomes'} id={'incomes'}
                       onFilesChosen={(data) =>
                        dispatch({ type: 'ADD_DOCUMENT', documents: { incomes: data } })}
                       validate={documents.incomes}
                       context={objContext}
                       />
            <FileInput label={'Certificado bancario'} name={'bank'} id={'bank'}
                       onFilesChosen={(data) =>
                        dispatch({ type: 'ADD_DOCUMENT', documents: { bank: data } })}
                       validate={documents.bank}
                       context={objContext}
            />
            {
              user.typePerson !== '1'
              ? <>
                <FileInput label={'Representacion legal'} name={'legal'} id={'legal'}
                           onFilesChosen={(data) =>
                            dispatch({ type: 'ADD_DOCUMENT', documents: { legal: data } })}
                           validate={documents.legal}
                           context={objContext}
                  />
                <FileInput label={'Certificado de existencia'} name={'existence'} id={'existence'}
                           onFilesChosen={(data) =>
                            dispatch({ type: 'ADD_DOCUMENT', documents: { existence: data } })}
                           validate={documents.existence}
                           context={objContext}
                />
              </>
               : <></>
            }
          </form >
        </div>
      </div>
    </div>;
};

export default Documents;
