import React, { useEffect, useCallback } from 'react';
import DrawerWrapper from '../mdc/drawer/DrawerWrapper';
import Toolbar from '../mdc/toolbar/Toolbar';
import SiteBody from '../mdc/SiteBody';
import Recharge from '../recharge/recharge';
import useAnalytics from '../../hooks/useAnalytics';

const RechargeSite = () => {
  const { trackViewPage } = useAnalytics('recharge');
  const setEvent = useCallback(trackViewPage, []);
  useEffect(() => setEvent(), [setEvent]);
    return (<>
      <Toolbar/>
      <DrawerWrapper/>
      <SiteBody>
        <Recharge/>
      </SiteBody>
    </>
    );
};

export default RechargeSite;
