/* eslint-disable react/prop-types */
import React from 'react';
import { Document, Page, Text, Image, View } from '@react-pdf/renderer';
import logo from '../../../res/images/logoWhite.png';
import { styles } from './styleSuscription';
import image from '../../../res/images/isotipo4.png';

const CardAmount = (props) => {
  return (
    <View style={styles.cardAmount}>
      {props.children}
    </View>
  );
};

const TextPdf = ({ text, variable }) => {
  return (
    <View style={styles.container}>
      <Text style={styles.text_bold}>
        {text}
      </Text>
      <Text style={styles.text}>
        {variable}
      </Text>
    </View>
  );
};

const CardPerson = (props) => {
  return (
    <View style={styles.cardPerson}>
      {props.children}
    </View>
  );
};

const SuscriptionTablePdf = ({ data, recharge = false }) => {
  const {
    projectName = '', userName = '', nit = '', ubication = '',
    amount = '', panels = 0, interes = '', beginingDate = '',
    beginingDateOp = '', status = '', adminPercentage = '',
    reference = '', transactionDate = '',
  } = data;

  return (
    <Document>
      <Page size={'A4'}>
        <View style={styles.header}/>
        <Image source={logo} style={styles.image} />
        <Image source={image} style={styles.water_mark} opacity={0.1}/>
        <Text style={styles.tittle}>
          COMPROBANTE DE {(recharge) ? 'RECARGA' : 'SUSCRIPCIÓN'}
        </Text>
        <CardAmount>
          <Text style={styles.text_card} >
            Total pago: {amount}
          </Text>
        </CardAmount>
        <CardPerson>
          <Text style={styles.text_card_person} >
            NOMBRE DEL INVERSOR: {userName}
          </Text>
          <Text style={styles.text_card_person} >
            {(!recharge) ? `PROYECTO: ${projectName}` : 'TRANSFERENCIA: Recarga billetera'}
          </Text>
          <Text style={styles.text_card_person} >
            C.C/NIT: {nit}
          </Text>
        </CardPerson>
        <Text style={styles.hr_1}/>
        {
          (!recharge)
          ? <>
            <TextPdf text={'Referencia:'} variable={reference}/>
            <TextPdf text={'Ubicación del proyecto: '} variable={ubication}/>
            <TextPdf text={'No de paneles digitales comprados: '} variable={panels}/>
            <TextPdf text={'Inicio de etapa de construcción: '} variable={beginingDate}/>
            <TextPdf text={'Inicio de etapa de operación: '} variable={beginingDateOp}/>
            <TextPdf text={'Porcentaje de administración FARM ENERGY: '}
                     variable={adminPercentage}/>
            <TextPdf text={'Interés estimado E.A.: '} variable={interes}/>
            <TextPdf text={'Estado de la transacción:'} variable={status}/>
          </>
          : <>
            <TextPdf text={'Referencia de recarga:'} variable={reference}/>
            <TextPdf text={'Destino de la Recarga:'} variable={'Billetera virtual (wallet)'}/>
            <TextPdf text={'Fecha de la transaccion:'} variable={transactionDate}/>
            <TextPdf text={'Estado de la recarga:'} variable={status}/>
          </>
        }
        <Text style={styles.hr}/>
        <View style={styles.footer}/>
        <Image source={logo} style={styles.image_footer}/>
        <Text style={styles.contactanos_tittle} >
          contactanos
        </Text>
        <Text style={styles.contactanos} >
          www.energyfarm.app              celular: 3139183484
        </Text>
      </Page>
    </Document>
  );
};

export default SuscriptionTablePdf;
