/* eslint-disable react/prop-types */
import React from 'react';
import CustomTextField from '../../../../UI/CustomTextField/CustomTextField';
import styles from '../manage-project.module.css';

const LocationSection = ({ projectData, createProjectDispatch }) => {
  const handleChange = (e, param) => {
    const DISPATCH_PARAM = 'UPDATE_PROJECT';
    const data = {};
    data[param] = e.target.value;
    createProjectDispatch({ type: DISPATCH_PARAM, project: { projectData: data } });
  };
    return (
      <div className={styles['section-container']}>
        <CustomTextField label={'Ubicación'} className={styles.custom_text_field}
                         onChange={(e) => handleChange(e, 'address')}
                         required={true} value={projectData.address}/>
      </div>
);
};

export default LocationSection;
