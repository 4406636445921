import React, { useContext } from 'react';
import { LandinContext } from '../../../context/LandinContext';
import QuestionDataForm from '../QuestionDataForm';

const AboutUs = () => {
  const {
    aboutUs = {}, AboutUsDispatch,
  } = useContext(LandinContext);

  const setting = {
    endPoint: 'aboutUs',
    label: 'sobre nosotros',
    update: 'nueva pregunta sobre nosotros',
  };

  return (
    <QuestionDataForm setting={setting} data={[aboutUs, AboutUsDispatch]}/>
  );
};

export default AboutUs;
