export const projectStructure = {
    project: {
        projectData: {
            projectName: '',
            address: '',
            power: '0',
            minDiscount: '',
            maxDiscount: '',
            powerInstalled: '0',
            noOfDigitalPanels: '0',
            noOfPhysicalPanels: '0',
            panelCost: '',
            totalCost: '',
            farmsize: '',
            raisedMoney: '',
            energyCost: '',
            annualRest: '',
            UserMaxPanels: '',
            UserPercentagePanels: '',
            discount: {
                week1: '',
                week2: '',
                week3: '',
                week4: '',
                week5: '',
                week6: '',
                week7: '',
                week8: '',
                week9: '',
                week10: '',
                week11: '',
                week12: '',
                week13: '',
                week14: '',
                week15: '',
                week16: '',
                week17: '',
                week18: '',
                week19: '',
                week20: '',
                week21: '',
                week22: '',
                week23: '',
                week24: '',
            },
            amortization: {
                1: '',
                2: '',
                3: '',
                4: '',
                5: '',
                6: '',
                7: '',
                8: '',
                9: '',
                10: '',
                11: '',
                12: '',
                13: '',
                14: '',
                15: '',
                16: '',
                17: '',
                18: '',
                19: '',
                20: '',
                ipc: '',
            },
            solar: {
                january: '',
                february: '',
                march: '',
                april: '',
                may: '',
                june: '',
                july: '',
                august: '',
                september: '',
                october: '',
                november: '',
                december: '',
            },
            percentage: {
                administration: '',
                farmer: '',
                maintance: '',
            },
            contract: '',
            beginningDate: '',
            beginningDateSeed: '',
            beginningDateHarvest: '',
            text: '',
            location: {
                address: '',
                lat: 0,
                lng: 0,
            },
            projectStatus: '1',
        },
        mainPicture: [],
        auxPicture: [],
    },
};
