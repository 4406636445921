/* eslint-disable max-len */
import React, { useContext, useState } from 'react';
import useAnalytics from '../../../hooks/useAnalytics';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@rmwc/theme';
import {
  TopAppBar, TopAppBarFixedAdjust, TopAppBarRow, TopAppBarSection
} from '@rmwc/top-app-bar';
import { Link, useHistory } from 'react-router-dom';
import
{
  mdiChevronDown,
  mdiAccountOutline,
  mdiWalletOutline,
  mdiSproutOutline,
  mdiReload,
  mdiCurrencyUsd,
  mdiLogout,
  mdiMenu
} from '@mdi/js';
import { Button } from '@rmwc/button';
import Icon from '@mdi/react';
import LogoFarmWhite from '../../../res/images/logoWhite.png';
import DrawerContext from '../drawer/DrawerContext';
import { Menu, MenuItem, MenuSurfaceAnchor } from '@rmwc/menu';
import { UserDataContext } from '../../../context/UserContext';
import { auth } from '../../../firebase';

import Account from './../../../res/images/profile.svg';

import styles from './toolbar.module.css';

const ToolbarTheme = {
  primary: '#1977ff',
  primaryBg: '#fff',
  secondary: '#6ec901',
  secondaryBg: '#6ec901',
  error: '#d43551',
  background: '#fff',
  onPrimary: '#1977ff',
  onSecondary: '#0a3066',
};

const Toolbar = ({ useCustomTheme = true }) => {
  const { setDrawerOpen } = useContext(DrawerContext);
  const { user } = useContext(UserDataContext);
  const [open, setOpen] = useState(false);
  const { trackEvent } = useAnalytics('toolbar');
  const history = useHistory();

  const renderToolbar = (content) => {
    if (useCustomTheme) {
      return (
        <ThemeProvider options={ToolbarTheme}>
          <TopAppBar className={styles.navBar}>
            {content}
          </TopAppBar>
        </ThemeProvider>
      );
    }
    return (<TopAppBar>{content}</TopAppBar>);
  };

  const PopUpMenu = () => {
    const shortName = () => {
      if (!user.displayName) return user.email.split('@')[0];

      if (user?.displayName.split(' ').length === 2) {
        return `${user?.displayName.split(' ')[0]} ${user?.displayName.split(' ')[1]}`;
      } else if (user?.displayName.split(' ') <= 4) {
        return `${user?.displayName.split(' ')[0]} ${user?.displayName.split(' ')[2]}`;
      } else {
        return `${user?.displayName.split(' ')[0]} ${user?.displayName.split(' ')[3]}`;
      }
    };

    const surfaceComponent = () => {
      if (user) {
        return styles.menu;
      } else {
        return styles['menu-unloggin'];
      }
    };

    return <div className={styles['toolbar-content']} >
      <MenuSurfaceAnchor className={styles.surface}>
        <Menu
        className={surfaceComponent()}
        open={open}
        onClose={() => setOpen(false)}
        style={{ top: '4.5rem' }}
        >
          {(user)
          ? <><MenuItem className={styles.menu_link} onClick={() => {
              trackEvent('click', 'perfil');
              history.push('/profile');
            }}>
            <Icon path={mdiAccountOutline} size={1} />Mi Perfil
          </MenuItem><MenuItem className={styles.menu_link} onClick={() => {
              trackEvent('click', 'billetera');
              history.push('/wallet');
            }}>
            <Icon path={mdiWalletOutline} size={1} />Billetera
          </MenuItem><MenuItem className={styles.menu_link} onClick={() => {
              trackEvent('click', 'mis inversiones');
              history.push('/myProjects');
            }}>
            <Icon path={mdiSproutOutline} size={1} />Mis inversiones
          </MenuItem><MenuItem className={styles.menu_link} onClick={() => {
              trackEvent('click', 'mis recargas');
              history.push('/recharge');
            }}>
            <Icon path={mdiReload} size={1} />Mis recargas
          </MenuItem><MenuItem className={styles.menu_link} onClick={() => {
              trackEvent('click', 'retirar saldo');
              history.push('/withDraw');
            }}>
            <Icon path={mdiCurrencyUsd} size={1} />Retirar saldo
          </MenuItem><MenuItem className={styles.menu_link} onClick={() => {
              trackEvent('click', 'cerrar sesion');
              closeSession();
            }}>
            <Icon path={mdiLogout} size={1} />Cerrar sesión
          </MenuItem></>
            : <>
              <MenuItem className={styles.menu_link_unlogged} onClick={() => {
                  trackEvent('click', 'iniciar sesion');
                  history.push('/');
                }}>
                Iniciar Sesión
              </MenuItem>
              <MenuItem className={styles.menu_link_unlogged} onClick={() => {
                  trackEvent('click', 'crear cuenta');
                  history.push('/signup');
                }}>
                Crear cuenta
              </MenuItem>
            </>
          }
        </Menu>
        <div className={styles['user-data']} onClick={() => setOpen(true)} style={{ display: 'flex', alignItems: 'center' }}>
          { (user)
            // eslint-disable-next-line multiline-ternary
            ? <>
              <img src={!user?.photoURL ? Account : user.photoURL}
                   alt={'profile_photo'}
                   className={styles.img}/>
              <p className={styles.name}>{shortName()}</p>
              <Icon path={mdiChevronDown} size={1} className={styles['user-data-icon']}/>
            </>
              : <Icon path={mdiMenu} className = {styles['unloggin-button']} size={1} />
          }
        </div>
      </MenuSurfaceAnchor>
    </div>;
  };

  const closeSession = async () => {
    await auth.signOut();
    history.push('/');
  };

  return (
    <>
      {renderToolbar(
        <TopAppBarRow className={styles.fixed}>
          <TopAppBarSection alignStart>
            {(user)
             ? <>
               <Icon path={mdiMenu} className={styles.hamburguer} size={1} onClick={() => setDrawerOpen(true)} style={{ color: 'white' }}/>
               <Link to={'/'}>
                 <img className={styles.Logo} src={LogoFarmWhite} width={'226px'} height={'28px'} alt={'Energy farm logo'}/>
               </Link>
             </>
             : <>
               <img className={styles.Logo} src={LogoFarmWhite} width={'226px'} height={'28px'} alt={'Energy farm logo'}/>
             </>
            }
          </TopAppBarSection>
          <TopAppBarSection alignEnd>
            {(user)
            ? <>
              <PopUpMenu></PopUpMenu>
            </>
            : <>
              <Link to={'/'} onClick={() => trackEvent('click', 'iniciar sesion')}>
                <Button className={styles.unlogginbutton} label={'Iniciar sesion'}/>
              </Link>
              <Link to={'/signup'} onClick={() => trackEvent('click', 'crear cuenta')}>
                <Button className={styles.unlogginbutton} label={'Crear Cuenta'}/>
              </Link>
              <PopUpMenu></PopUpMenu>
            </>
            }
          </TopAppBarSection>
        </TopAppBarRow>
      )}
      <TopAppBarFixedAdjust/>
    </>
  );
};

Toolbar.propTypes = {
  useCustomTheme: PropTypes.bool,
};

export default Toolbar;
