/* eslint-disable react/prop-types */
import React from 'react';
import styles from '../manage-project.module.css';
import { TextField } from '@rmwc/textfield';
import NumberValidator from '../../../../Utils/NumberValidator';

const PercentageField = ({
    disabled, label, type, textarea,
    required, dispatchType, placeholder,
    dispatchParam, data = {}, dispatch,
}) => {
    const projectData = {};
    return <div className={styles.custom_text_field}>
      <TextField label={label} textarea={textarea} type={(type === 'date' ? 'date' : '')}
                 placeholder={placeholder} value={data[`${dispatchParam}`]}
                 disabled={disabled}
                 trailingIcon={'percent'}
                 onChange={(e) => {
                   data[`${dispatchParam}`] = (e.target.value);
                   dispatch({ type: dispatchType, project: { projectData } });
                 }}/>
      {(required) ? <NumberValidator required={true} value={data[`${dispatchParam}`]}/> : <></>}
    </div>;
};

const Percentage = ({ percentage, createProjectDispatch }) => {
    return (
      <div className={styles['section-container']}>
        <div style={{ display: 'flex', justifyContent: 'center', columnGap: '1rem' }}>
          <div style={{ width: '100%' }}>
            <PercentageField label={'Granjero'} dispatchType={'UPDATE_PROJECT'}
                             dispatchParam={'farmer'} data={percentage} value={'como'}
                             dispatch={createProjectDispatch} placeholder={''}/>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', columnGap: '1rem' }}>
          <div style={{ width: '100%' }}>
            <PercentageField label={'mantenimiento'} dispatchType={'UPDATE_PROJECT'}
                             dispatchParam={'maintance'} data={percentage} value={'como'}
                             dispatch={createProjectDispatch} placeholder={''}/>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', columnGap: '1rem' }}>
          <div style={{ width: '100%' }}>
            <PercentageField label={'Administración'} dispatchType={'UPDATE_PROJECT'}
                             dispatchParam={'administration'} data={percentage} value={'como'}
                             dispatch={createProjectDispatch} placeholder={''}/>
          </div>
        </div>
      </div>
    );
};

export default Percentage;
