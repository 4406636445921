/* eslint-disable react/prop-types */
import React from 'react';
import Icon from '@mdi/react';
import FileInput from '../../../../file-input/FileInput';
import styles from '../manage-project.module.css';
import { mdiImageArea } from '@mdi/js';

const CustomImage = ({ text, id, mainPicture, imgData, dispatch, clean }) => {
    const imgId = mainPicture ? 'main-photo' : `img-${id}`;
    if (Array.isArray(imgData)) {
     const [imgExtracted] = (imgData || []).filter((data) => data.id === id);
     imgData = imgExtracted;
    }
    return <div className={styles['custom-image']}>
      <div className={styles['gallery-icon']}>
        <div>
          <Icon path={mdiImageArea} color={'gray'} size={'10rem'}/>
          <div style={{
              position: 'absolute', right: (mainPicture) ? '20%' : '-1rem', top: '0.5rem',
            }}>

            <FileInput label={'Foto principal'} name={imgId} id={imgId} customIcon={true}
                       clean={clean} img={imgData}
                       onFilesChosen={(data) => {
                         if (!clean) {
                          const object = mainPicture
                            ? { mainPicture: data }
                            : { auxPicture: [{ id, file: data }] };
                          dispatch({ type: 'UPDATE_PROJECT', project: object });
                         }
                      }}/>
          </div>
        </div>
      </div>
      <p>{text}</p>
    </div>;
};

const GallerySection = ({
    mainPicture,
    createProjectDispatch,
    clean,
    auxPicture,
}) => {
    return (
      <>
        <div className={styles['manage-gallery']}>
          <h3>Images</h3>
          <CustomImage text={'Imagen Principal'} mainPicture={true} imgData={mainPicture[0]}
                       dispatch={createProjectDispatch} clean={clean}/>
          <h3>Galeria</h3>
          <div className={styles['aux-images']}>
            <CustomImage text={'Imagen 1'} id={'0'} imgData={auxPicture}
                         dispatch={createProjectDispatch} clean={clean}/>
            <CustomImage text={'Imagen 2'} id={'1'} imgData={auxPicture}
                         dispatch={createProjectDispatch} clean={clean}/>
            <CustomImage text={'Imagen 3'} id={'2'} imgData={auxPicture}
                         dispatch={createProjectDispatch} clean={clean}/>
            <CustomImage text={'Imagen 4'} id={'3'} imgData={auxPicture}
                         dispatch={createProjectDispatch} clean={clean}/>
          </div>
        </div>
      </>
    );
};

export default GallerySection;
