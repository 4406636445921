import React from 'react';
import Icon from '@mdi/react';
import { mdiAlertCircleOutline } from '@mdi/js';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogButton
} from '@rmwc/dialog';
import { Link } from 'react-router-dom';

const InternalError = () => {
    return (
      <Dialog open={true}>
        <DialogTitle>
          UPSS !!!!!
        </DialogTitle>
        <DialogContent>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Icon path={mdiAlertCircleOutline} size={6}/>
          </div>
          Lo sentimos parece que tenemos un problema con nuestros servicios
          internos te recomendamos que intentes realizar el proceso
          más tarde
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-around' }}>
          <Link to={'/'} style={{ textDecoration: 'none' }}>
            <DialogButton isDefaultAction unelevated>
              Volver al inicio
            </DialogButton>
          </Link>
        </DialogActions>
      </Dialog>
    );
};

export default InternalError;
