import React, { useState, useEffect } from 'react';
import { TextField } from '@rmwc/textfield';
import { useHistory, Link } from 'react-router-dom';
import { Button } from '@rmwc/button';
import { Dialog, DialogTitle, DialogButton, DialogActions } from '@rmwc/dialog';
import Toolbar from '../../mdc/toolbar/Toolbar';
import SiteBody from '../../mdc/SiteBody';
import { Snackbar, SnackbarAction } from '@rmwc/snackbar';
import styles from './sigin.module.css';
import { auth, googleAuthProvider } from '../../../firebase';
import LoginImg from './../../../res/images/logoLogin.png';
import Icon from '@mdi/react';
import { mdiGoogle } from '@mdi/js';
import ReCAPTCHA from 'react-google-recaptcha';
import ReCaphaApi from '../../../api/ReCaptchaApi';
import UserApi from '../../../api/UserApi';
import useAnalytics from '../../../hooks/useAnalytics';

const emailPattern = '^[a-z A-Z -\\.]+@([a-z A-Z -]+\\.)+[a-z A-Z -]{2,4}$';

const SignIn = () => {
  const recaptchaRef = React.useRef();

    const [numberOfRequest, setNumberOfRequest] = useState(0);
    const [email, setEmail] = useState('');
    const [resetEmail, setResetEmail] = useState('');
    const [checkReCaptcha, setCheckReCaptcha] = useState(false);
    const { trackEvent } = useAnalytics(window.location.pathname);

    const history = useHistory();

    const [pass, setPas] = useState('');
    const [snackMessage, setSnackMessage] = useState('');
    const [open, setOpen] = useState(false);

    const onChange = async (data) => {
      setCheckReCaptcha((await ReCaphaApi.checkResponse(data)).status);
    };

    const login = () => {
      if (email.match(emailPattern) === null) setSnackMessage('Invalid Email Format');
      else if (pass.length === 0 || email.length === 0) {
        setSnackMessage('Make Sure To Fill All Fields');
      } else if ((numberOfRequest > 1 && checkReCaptcha)
      | (numberOfRequest <= 2 && !checkReCaptcha)) {
        setNumberOfRequest(numberOfRequest + 1);
        if (numberOfRequest > 1 && checkReCaptcha) {
          recaptchaRef.current.reset();
          setCheckReCaptcha(false);
        }

        auth.signInWithEmailAndPassword(email, pass)
          .then(async (resp) => {
            await trackEvent('login', 'successful');
            setSnackMessage('Logging');
            setNumberOfRequest(0);
            history.push('/');
          })
          .catch((error) => {
            trackEvent('login', 'failed');
            setSnackMessage(error.message);
          });
      };
    };

    const recoveryPass = () => {
      auth.sendPasswordResetEmail(resetEmail)
      .then(() => setSnackMessage('Password reset email sent!'))
      .catch((error) => setSnackMessage(error.message));
    };

    useEffect(() => {
      auth.getRedirectResult()
        .then(({ user }) => {
          if (!user) return;
          UserApi.saveBasicUserData(user.providerData)
          .then(() => trackEvent('login-google', 'successful'))
          .catch((error) => {
            trackEvent('login-google', 'failed');
            setSnackMessage(error.message);
          })
          .finally(history.push('/'));
        });
    });

    const Google = () => auth.signInWithRedirect(googleAuthProvider);

    return (<>
      <Toolbar/>
      <SiteBody>

        <div className={styles.container}>
          <img alt={'BPSmart Logo'} src={LoginImg} width={'80%'}/>
          <div className={styles.content}>
            <h1>Iniciar sesión</h1>
            <div className={styles.grid}>
              <TextField className={styles.large} label={'Correo'} value={email}
                         pattern={'^[a-z A-Z -\\.]+@([a-z A-Z -]+\\.)+[a-z A-Z -]{2,4}$'}
                         onChange={(e) => setEmail(e.target.value.toString())}
                         icon={'account'}
                         outlined
                         />
              <TextField className={styles.large} value={pass}
                         label={'Contraseña'}
                         type={'password'}
                         onChange={(e) => setPas(e.target.value.toString())}
                         icon={'lock'}
                         outlined/>
            </div>
            <div className ={styles['login-buttons']}>
              <Link to={'/signup'} className={styles['link-signup']}>
                <Button className={styles['signup-button']}
                        onClick={() => trackEvent('redirect', 'create-account')}>
                  ¿No tienes cuenta? crea una
                </Button>
              </Link>
              <Button label={'Entrar a Energy Farm'} raised
                      className={styles.small}
                      onClick={() => login()}/>
              <Button className={styles['google-button']} raised
                      onClick={() => Google()}
                      >
                <Icon path={mdiGoogle} size={0.8}/>
                Entrar con google
              </Button>
            </div>

            {(numberOfRequest > 2)
            ? <form onSubmit={() => { recaptchaRef.current.execute(); }}>
              <ReCAPTCHA
                    ref={recaptchaRef}
                    size={'normal'}
                    sitekey={'6Lcu-lMcAAAAAGGiPriuHwqa1MbAA9F9d4BQYxM6'}
                    onChange={onChange}
                    grecaptcha
              />
            </form>
             : <></>}

            <Snackbar
          open={snackMessage} leading
          message={snackMessage}
          dismissesOnAction
          action={
            <SnackbarAction label={'Dismiss'}/>
          }/>
            <Button className={styles['forgot-password']}
                    onClick={() => {
                      trackEvent('click', 'forgot-password');
                      setOpen(true);
                    } }>
              ¿Olvidaste tu Contraseña?
            </Button>
          </div>
          <Dialog
        open={open}
        onClose={evt => setOpen(false)}>
            <DialogTitle style={{ padding: '2rem' }}>Ingresa el correo electrónico</DialogTitle>
            <TextField label={'Correo'} value={resetEmail} style={{ margin: 'auto' }}
                       pattern={'^[a-z A-Z -\\.]+@([a-z A-Z -]+\\.)+[a-z A-Z -]{2,4}$'}
                       onChange={(e) => setResetEmail(e.target.value.toString())}/>
            <DialogActions style={{ paddingTop: '2rem' }}>
              <DialogButton action={'close'}>Cancelar</DialogButton>
              <DialogButton action={'accept'} isDefaultAction onClick={() => recoveryPass()}>
                Enviar
              </DialogButton>
            </DialogActions>
          </Dialog>
        </div>
      </SiteBody>
    </>);
};

export default SignIn;
