import React from 'react';
import DrawerWrapper from '../mdc/drawer/DrawerWrapper';
import Toolbar from '../mdc/toolbar/Toolbar';
import SiteBody from '../mdc/SiteBody';
import GeneralContent from '../general/General';
import ProjectContext from '../../context/ProjectContext';
import ProfileContext from '../../context/ProfileContext';

const General = () => {
    return <>
      <Toolbar/>
      <DrawerWrapper/>
      <SiteBody>
        <ProjectContext>
          <ProfileContext>
            <GeneralContent/>
          </ProfileContext>
        </ProjectContext>
      </SiteBody>
    </>;
};

export default General;
