import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import styles from '../projects/Project-Description/Description/Sections/Graphic.module.css';
import { TextField } from '@rmwc/textfield';
import { Icon } from '@rmwc/icon';
import { mdiRectangle } from '@mdi/js';
import Utils from '../../utils/Utils';
import { ProjectContext } from '../../context/ProjectContext';

const CrowdfundingSimulator = ({ project: dataProject, bg = true }) => {
  const [monthlyPaymnet, setMonthlyPayment] = useState(0);
  const [durationProject, setDurationProject] = useState(0);
  const [totalProject, setTotalProject] = useState(0);
  const {
    panels = 0,
    setPanels,
  } = useContext(ProjectContext);

  useEffect(() => {
    const { payment, time, totalProjectCost } = Utils.simulateCredit({
      annualRate: dataProject.project.annualRest * 100,
      duration: dataProject.project.contract,
      projectCost: dataProject?.project?.panelCost * panels,
    });

    setMonthlyPayment(payment);
    setDurationProject(time);
    setTotalProject(totalProjectCost);
    // eslint-disable-next-line
  }, [panels]);

  const numberFormatter = (number) => Math.round(parseFloat(number));

  return (
    <div className={styles['simulator-container']}>
      <div className={bg ? styles['container-background'] : ''}></div>
      <h3>Simulador de inversión</h3>
      <div className={styles['form-control']}>
        <h5>¿Cuántos paneles quieres?</h5>
        <TextField
          className={styles['control-text-field']}
          value={panels}
          outlined
          onKeyPress={(e) => {
            if (!(e.key >= 0 && e.key <= 9)) e.preventDefault();
          }}
          onChange={(e) => setPanels(e.target.value)}
        />
      </div>
      <div className={styles['sum-container']}>
        <div className={styles['values-title']}>
          <h4>Costos</h4>
        </div>
        <h5 className={styles['value-first-element']}>
          <span>
            <Icon
              className={styles['cyan-blue']}
              path={mdiRectangle}
              size={1}
            />
            Precio Normal
          </span>
          <span>
            {`${Utils.formatterEmpty.format(
              numberFormatter(dataProject?.project?.panelCost * panels)
            )} COP`}
          </span>
        </h5>
        <h5 className={styles['value-first-element']}>
          <span>
            <Icon
              className={styles['cyan-blue']}
              path={mdiRectangle}
              size={1}
            />
            Tasa de interes anual
          </span>
          <span>
            {`${Utils.formatterEmpty.format(
              numberFormatter(dataProject?.project?.annualRest * 100)
            )} %`}
          </span>
        </h5>
        <h5 className={styles['value-first-element']}>
          <span>
            <Icon
              className={styles['cyan-blue']}
              path={mdiRectangle}
              size={1}
            />
            Período del prestamo
          </span>
          <span>
            {`${Utils.formatterEmpty.format(
              numberFormatter(dataProject?.project?.contract)
            )} años`}
          </span>
        </h5>

        <h5 className={styles['value-first-element']}>
          <span>
            <Icon
              className={styles['cyan-blue']}
              path={mdiRectangle}
              size={1}
            />
            Pago mensual
          </span>
          <span>
            {`${Utils.formatterEmpty.format(
              numberFormatter(monthlyPaymnet || 0)
            )} COP`}
          </span>
        </h5>
        <h5>
          <span>
            <Icon
              className={styles['dark-blue']}
              path={mdiRectangle}
              size={1}
            />
            Número de pagos
          </span>
          <span>
            {Utils.formatterEmpty.format(
              numberFormatter(durationProject || 0)
            )}
          </span>
        </h5>

        <h5 className={styles['value-first-element']}>
          <span>
            <Icon
              className={styles['cyan-blue']}
              path={mdiRectangle}
              size={1}
            />
            Importe total de los interes
          </span>
          <span>
            {`${Utils.formatterEmpty.format(
              numberFormatter(totalProject - dataProject?.project?.panelCost * panels || 0)
            )} COP`}
          </span>
        </h5>
        <hr />
        <h5>
          <span>
            <Icon className={styles.transparent} path={mdiRectangle} size={1} />
            Costo total del préstamo
          </span>
          <span>
            {`${Utils.formatterEmpty.format(
              numberFormatter(totalProject || 0)
            )} COP`}
          </span>
        </h5>
      </div>
    </div>
  );
};

CrowdfundingSimulator.propTypes = {
  project: PropTypes.object.isRequired,
  bg: PropTypes.bool,
};

export default CrowdfundingSimulator;
