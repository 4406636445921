/* eslint-disable react/prop-types */
import React from 'react';
import { TextField } from '@rmwc/textfield';
import styles from '../manage-project.module.css';
import NumberValidator from '../../../../Utils/NumberValidator';

const CustomSolarField = ({
    disabled, label, type, textarea,
    required, dispatchType, placeholder,
    dispatchParam, data = {}, dispatch,
}) => {
    const projectData = {};
    return <div className={styles.custom_text_field}>
      <TextField label={`${label} (HSP)`} textarea={textarea} type={(type === 'date' ? 'date' : '')}
                 placeholder={placeholder} value={data[`${dispatchParam}`]}
                 disabled={disabled}
                 onChange={(e) => {
                   data[`${dispatchParam}`] = (e.target.value);
                   dispatch({ type: dispatchType, project: { projectData } });
                 }}/>
      {(required) ? <NumberValidator required={true} value={data[`${dispatchParam}`]}/> : <></>}
    </div>;
};

const SolarRangeSection = ({ solar, createProjectDispatch }) => {
  return (
    <div className={styles['section-container']}>
      <div style={{ display: 'flex', justifyContent: 'center', columnGap: '1rem' }}>
        <div style={{ width: '100%' }}>
          <CustomSolarField label={'Enero'} dispatchType={'UPDATE_PROJECT'}
                            dispatchParam={'january'} data={solar} value={'como'}
                            dispatch={createProjectDispatch} placeholder={'2%'}/>
        </div>
        <div style={{ width: '100%' }}>
          <CustomSolarField label={'Febrero'} dispatchType={'UPDATE_PROJECT'}
                            dispatchParam={'february'} data={solar}
                            dispatch={createProjectDispatch} placeholder={'3.5'}/>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', columnGap: '1rem' }}>
        <div style={{ width: '100%' }}>
          <CustomSolarField label={'Marzo'} dispatchType={'UPDATE_PROJECT'}
                            dispatchParam={'march'} data={solar}
                            dispatch={createProjectDispatch} placeholder={'2%'}/>
        </div>
        <div style={{ width: '100%' }}>
          <CustomSolarField label={'Abril'} dispatchType={'UPDATE_PROJECT'}
                            dispatchParam={'april'} data={solar}
                            dispatch={createProjectDispatch} placeholder={'3.5'}/>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', columnGap: '1rem' }}>
        <div style={{ width: '100%' }}>
          <CustomSolarField label={'may'} dispatchType={'UPDATE_PROJECT'}
                            dispatchParam={'may'} data={solar}
                            dispatch={createProjectDispatch} placeholder={'2%'}/>
        </div>
        <div style={{ width: '100%' }}>
          <CustomSolarField label={'Junio'} dispatchType={'UPDATE_PROJECT'}
                            dispatchParam={'june'} data={solar}
                            dispatch={createProjectDispatch} placeholder={'3.5'}/>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', columnGap: '1rem' }}>
        <div style={{ width: '100%' }}>
          <CustomSolarField label={'Julio'} dispatchType={'UPDATE_PROJECT'}
                            dispatchParam={'july'} data={solar}
                            dispatch={createProjectDispatch} placeholder={'2%'}/>
        </div>
        <div style={{ width: '100%' }}>
          <CustomSolarField label={'Agosto'} dispatchType={'UPDATE_PROJECT'}
                            dispatchParam={'august'} data={solar}
                            dispatch={createProjectDispatch} placeholder={'3.5'}/>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', columnGap: '1rem' }}>
        <div style={{ width: '100%' }}>
          <CustomSolarField label={'Septiembre'} dispatchType={'UPDATE_PROJECT'}
                            dispatchParam={'september'} data={solar}
                            dispatch={createProjectDispatch} placeholder={'2%'}/>
        </div>
        <div style={{ width: '100%' }}>
          <CustomSolarField label={'Octubre'} dispatchType={'UPDATE_PROJECT'}
                            dispatchParam={'october'} data={solar}
                            dispatch={createProjectDispatch} placeholder={'3.5'}/>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', columnGap: '1rem' }}>
        <div style={{ width: '100%' }}>
          <CustomSolarField label={'Noviembre'} dispatchType={'UPDATE_PROJECT'}
                            dispatchParam={'november'} data={solar}
                            dispatch={createProjectDispatch} placeholder={'2%'}/>
        </div>
        <div style={{ width: '100%' }}>
          <CustomSolarField label={'Diciembre'} dispatchType={'UPDATE_PROJECT'}
                            dispatchParam={'december'} data={solar}
                            dispatch={createProjectDispatch} placeholder={'3.5'}/>
        </div>
      </div>
    </div>
  );
};

export default SolarRangeSection;
