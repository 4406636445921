/* eslint-disable react/prop-types */
import React from 'react';
import CustomTextField from '../../../../UI/CustomTextField/CustomTextField';
import styles from '../manage-project.module.css';
import { Select } from '@rmwc/select';

const projectStatus = [
    { label: 'Siembra', value: 1 },
    { label: 'Cultivo', value: 2 },
    { label: 'Cosecha', value: 3 },
];

const ProgresSection = ({ projectData, createProjectDispatch }) => {
  const handleChange = (e, param) => {
    const DISPATCH_PARAM = 'UPDATE_PROJECT';
    const data = {};
    data[param] = e.target.value;
    createProjectDispatch({ type: DISPATCH_PARAM, project: { projectData: data } });
  };
  return (
    <div className={styles['section-container']}>
      <div style={{ width: '100%' }}>
        <CustomTextField label={'Fecha de inicio de siembra'} className={styles.custom_text_field}
                         onChange={(e) => handleChange(e, 'beginningDateSeed')}
                         type={'date'} value={projectData.beginningDateSeed}/>
      </div>
      <div style={{ width: '100%' }}>
        <CustomTextField label={'Fecha de inicio de cosecha'} className={styles.custom_text_field}
                         onChange={(e) => handleChange(e, 'beginningDateHarvest')}
                         type={'date'} value={projectData.beginningDateHarvest}/>
      </div>
      <div style={{ width: '100%' }}>
        <CustomTextField label={'Fecha de inicio de operación'} className={styles.custom_text_field}
                         onChange={(e) => handleChange(e, 'beginningDate')}
                         type={'date'} value={projectData.beginningDate}/>
      </div>
      <div style={{ marginTop: '0.5rem', textAlign: 'left' }}>
        <Select
                  label={'Estado del proyecto'}
                  options={projectStatus}
                  value={projectData?.projectStatus}
                  onChange={(e) => {
                          createProjectDispatch({
                            type: 'UPDATE_PROJECT',
                            project: { projectData: { projectStatus: e.target.value } },
                          });
                    }}
                  />
      </div>
    </div>
  );
};

export default ProgresSection;
