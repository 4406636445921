import { StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    water_mark: {
      position: 'absolute',
      width: '450px',
      marginTop: '200px',
      marginLeft: '70px',
      opacity: 0.25,
    },
    header: {
      height: '70px',
      width: '1000',
      backgroundColor: '#006F97',
    },
    cardAmount: {
      position: 'absolute',
      border: '2px solid #006F97',
      color: 'black',
      padding: '10px',
      marginTop: '590px',
      marginLeft: '330px',
    },
    card: {
      position: 'absolute',
      color: 'white',
      padding: '10px',
      marginTop: '18px',
      marginLeft: '360px',
    },
    cardPerson: {
      position: 'absolute',
      color: 'black',
      padding: '10px',
      marginTop: '150px',
      marginLeft: '45px',
      border: '2px solid #006F97',
    },
    text_card: {
      fontSize: '16',
    },
    text_card_person: {
      fontSize: '13',
      marginBottom: '5px',
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      marginLeft: '30',
      marginRight: '80',
    },
    image: {
      width: '200px',
      marginLeft: '32',
      marginTop: '25px',
      position: 'absolute',
    },
    image_footer: {
      width: '170px',
      marginLeft: '20',
      marginTop: '790px',
      position: 'absolute',
    },
    contactanos_tittle: {
        color: 'white',
        marginLeft: '230',
        fontSize: '14',
        marginTop: '787px',
        position: 'absolute',
    },
    contactanos: {
        color: 'white',
        marginLeft: '230',
        fontSize: '12',
        marginTop: '808px',
        position: 'absolute',
    },
    tittle: {
      textAlign: 'center',
      fontSize: '22',
      marginTop: '25',
      fontWeight: '100',
    },
    tittle_2: {
      textAlign: 'center',
      fontSize: '18',
      fontWeight: '100',
    },
    text: {
      textAlign: 'initial',
      padding: '5',
      fontSize: '13',
      fontWeight: '100',
      textTransform: 'uppercase',
    },
    text_bold: {
      textAlign: 'initial',
      padding: '5',
      fontSize: '13',
      marginLeft: '18',
      fontWeight: 'bolder',
      textTransform: 'uppercase',
    },
    hr: {
      borderBottom: '1px solid darkgray',
      marginTop: '50px',
      marginBottom: '50px',
      zIndex: '-2',
    },
    hr_1: {
      borderBottom: '1px solid darkgray',
      marginTop: '160px',
      marginBottom: '50px',
      zIndex: '-2',
    },
    footer: {
        position: 'absolute',
        height: '70px',
        width: '1000',
        backgroundColor: '#006F97',
        marginTop: '771.6px',
    },
  });

export { styles };
