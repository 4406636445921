/* eslint-disable react/prop-types */
import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { firestore } from '../../../firebase';
import PaymentResponseApi from '../../../api/PaymentResponseApi';
import Contrato from '../../../resources/contrato.pdf';
import { UserDataContext } from '../../../context/UserContext';
import { ProjectContext } from '../../../context/ProjectContext';
import usePaymentData from '../../../hooks/usePaymentData';
import LoadingEffect from '../../loading/Loading';
import UIPaymentResponse from './UIPaymentResponse';
import InternalError from './InternalError';

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const PaymentResponse = () => {
  const {
    initialBillState, checkData,
    initialProjectState, checkProject,
  } = usePaymentData();

  const [bill, setBill] = useState(initialBillState);
  const [project, setProject] = useState(initialProjectState);
  const [status, setStatus] = useState('loading');
  const { data = {} } = bill;
  const { basicUserInformation = {} } = useContext(UserDataContext);
  const { user = {} } = basicUserInformation;
  const { projects = [] } = useContext(ProjectContext);

  const saveContract = async (dataEpayco = {}) => {
    if (dataEpayco.x_description) {
      const path = firestore
        .collection('users')
        .doc(user.email)
        .collection('contracts');

      await path.add({
        projectName: dataEpayco.x_description,
        contract: Contrato,
        fecha: new Date(),
      });
    }
  };

  const query = useQuery();

  useEffect(() => {
    const fetchData = async () => {
      await PaymentResponseApi.getPaymentResponseData(query.get('ref_payco'))
      .then((resp = {}) => {
        setBill(resp);
        setStatus(resp.success);
      })
      .then(() => saveContract(data));
    };
    fetchData();
  // eslint-disable-next-line
  }, []);

  const pdfData = {
    reference: data.x_id_invoice,
    projectName: project.name,
    userName: user.name,
    nit: user.nit,
    ubication: project.address,
    amount: `${data.x_amount} ${data.x_currency_code}`,
    panels: data.x_extra2,
    interes: '0.01',
    beginingDate: `${project.date1}`,
    beginingDateOp: `${project.date1}`,
    status: data.x_response,
    adminPercentage: `${project.admin} %`,
    transactionDate: data.x_transaction_date,
  };

  // eslint-disable-next-line
  useEffect(() => {
    if (!query.get('ref_payco')) return;
    const aux = projects.filter(project => project.projectId === data?.x_id_invoice.split('_')[0]);
    setProject({
      name: `${aux[0]?.project.projectName || ''}`,
      address: `${aux[0]?.project.address || ''}`,
      admin: `${aux[0]?.project.percentage.administration || ''}`,
      date1: `${aux[0]?.project.beginningDate || ''}`,
      date2: `${aux[0]?.project.beginningDate || ''}`,
    });
  // eslint-disable-next-line
  }, [projects.length > 0 && data.x_id_invoice !== '']);

  if (status !== 'loading' && status !== true) return <InternalError/>;
  if (checkProject(project) && checkData(data)) {
    return <UIPaymentResponse data={data} user={user} project={project} pdfData={pdfData}/>;
  } else {
    return <LoadingEffect/>;
  }
};

export default PaymentResponse;
