import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogButton, DialogContent, DialogTitle } from '@rmwc/dialog';
import {
  DataTable, DataTableContent, DataTableHead,
  DataTableBody, DataTableRow, DataTableHeadCell, DataTableCell
} from '@rmwc/data-table';

const DialogHistory = ({ history, isOpen, onClose, projectId }) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      onClosed={onClose}
    >
      <DialogTitle>Historial</DialogTitle>
      <DialogContent>
        <DataTable style={{ width: '100%' }}>
          <DataTableContent>
            <DataTableHead>
              <DataTableRow>
                <DataTableHeadCell>Cantidad de paneles</DataTableHeadCell>
                <DataTableHeadCell>Costo de paneles</DataTableHeadCell>
                <DataTableHeadCell>Porcentaje de participación</DataTableHeadCell>
                <DataTableHeadCell>Fecha</DataTableHeadCell>
              </DataTableRow>
            </DataTableHead>
            <DataTableBody>
              {
                (history || []).filter((event) => event.projectId === projectId)
                .map((element, idx) => (
                  <DataTableRow key={idx} style={{ marginTop: '5rem' }} >
                    <DataTableCell>{element.panels}</DataTableCell>
                    <DataTableCell>{(element.amount).toFixed(2)}</DataTableCell>
                    <DataTableCell>
                      {element.sharePercentage.toFixed(2)}
                    </DataTableCell>
                    <DataTableCell>{new Date(
                      // eslint-disable-next-line no-underscore-dangle
                      element.lastUpdate._seconds * 1000
                      ).toLocaleString()
                      .split('T')
                      }</DataTableCell>
                  </DataTableRow>
                ))
              }
            </DataTableBody>
          </DataTableContent>
        </DataTable>
      </DialogContent>
      <DialogActions>
        <DialogButton action={'close'} onClick={onClose}>Cancel</DialogButton>
      </DialogActions>
    </Dialog>
  );
};

DialogHistory.propTypes = {
  history: PropTypes.array,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  projectId: PropTypes.string,
};

export default DialogHistory;
