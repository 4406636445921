import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { TextField } from '@rmwc/textfield';
import { Button } from '@rmwc/button';
import SiteBody from '../../mdc/SiteBody';
import { Snackbar, SnackbarAction } from '@rmwc/snackbar';
import { auth } from '../../../firebase';
import useAnalytics from '../../../hooks/useAnalytics';

import styles from './reset-pass.module.css';

const GetQueryParams = () => {
    return new URLSearchParams(useLocation().search);
};

const ResetPass = () => {
    const [pass, setPass] = useState('');
    const [auxPass, setAuxPass] = useState('');
    const [snackMessage, setSnackMessage] = useState('');
    const { trackViewPage } = useAnalytics('resetPass');
    const setEvent = useCallback(trackViewPage, []);
    useEffect(() => setEvent(), [setEvent]);
    const query = GetQueryParams();
    const history = useHistory();

    const checkPass = async () => {
        if (pass === auxPass) {
            const code = query.get('oobCode');
            // auth.currentUser.updatePassword()
            auth.verifyPasswordResetCode(code).then((resp) => {
                auth.confirmPasswordReset(code, pass)
                .then(() => {
                    setSnackMessage('Password has been changed', resp);
                    history.push('/');
                })
                .catch((err) => setSnackMessage(err.message));
            })
            .catch((err) => setSnackMessage(err.message));
        } else setSnackMessage('Both pass are different');
    };

    return (<>
      <SiteBody>
        <div className={styles.contents}>
          <h3>Cambiar Contraseña</h3>
          <hr/>
          <TextField label={'Nueva Contraseña'} className={styles.select} value={pass}
                     type={'password'}
                     pattern={'^[a-z A-Z -\\.]+'}
                     outlined
                     onChange={(e) => setPass(e.target.value.toString())}/>
          <TextField label={'Vuelve a escribir la Contraseña'}
                     className={styles.select}
                     value={auxPass}
                     type={'password'}
                     outlined
                     pattern={'^[a-z A-Z -\\.]+'}
                     onChange={(e) => setAuxPass(e.target.value.toString())}/>
          <Button onClick={() => checkPass()} className={styles.button} raised>Guardar</Button>
        </div>
        <Snackbar
          open={snackMessage} leading
          message={snackMessage}
          dismissesOnAction
          action={
            <SnackbarAction label={'Dismiss'}/>
          }/>
      </SiteBody>
    </>);
};

export default ResetPass;
