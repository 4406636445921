import React from 'react';
import Project from './manage-project/manage-project';

const ManageProjects = () => {
    return <>
      <Project/>
    </>;
};

export default ManageProjects;
