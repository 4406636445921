import { useEffect, useState } from 'react';
import ProjectApi from '../api/ProjectApi';
import Utils from '../utils/Utils';

const useProjectRecords = () => {
  const [projectId, setProjectId] = useState('');
  const [period, setPeriod] = useState('');
  const [records, setRecords] = useState({});
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [weeksMonth, setWeeksMonth] = useState([]);
  const defaultObject = {
    activeProduced: [],
    activeImported: [],
    activeExported: [],
    reactiveImported: [],
    reactiveExported: [],
    reactiveCost: [],
    activeCost: [],
    activeSold: [],
    reactiveSold: [],
  };

  useEffect(() => {
    if (!projectId || !period) return;
    setLoading(true);
    ProjectApi.getProjectRegistriesByProjectId({ projectId, period })
      .then(({ data }) => {
        const { lastUpdate, user, year, ...all } = data;
        let records = { ...all };
        if (Object.keys(all).length === 0) records = { ...defaultObject };
        const weeks = Utils.getWeeks(period);
        setWeeksMonth(weeks);
        setRecords({ ...records });
      })
      .catch((error) => setError(error.message))
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, [period, projectId, setRecords]);

  return {
    setPeriod,
    setProjectId,
    projectId,
    period,
    weeksMonth,
    records,
    error,
    loading,
  };
};

export default useProjectRecords;
