/* eslint-disable react/prop-types */
import React from 'react';
import styles from '../manage-project.module.css';
import { TextField } from '@rmwc/textfield';
import NumberValidator from '../../../../Utils/NumberValidator';

const DiscountField = ({
    disabled, label, type, textarea,
    required, dispatchType, placeholder,
    dispatchParam, data = {}, dispatch,
}) => {
    const projectData = {};
    return <div className={styles.custom_text_field}>
      <TextField label={label} textarea={textarea} type={(type === 'date' ? 'date' : '')}
                 placeholder={placeholder} value={data[`${dispatchParam}`]}
                 disabled={disabled}
                 trailingIcon={'percent'}
                 onChange={(e) => {
                   data[`${dispatchParam}`] = (e.target.value);
                   dispatch({ type: dispatchType, project: { projectData } });
                 }}/>
      {(required) ? <NumberValidator required={true} value={data[`${dispatchParam}`]}/> : <></>}
    </div>;
};

const Discounts = ({ data, createProjectDispatch }) => {
    return (
      <div className={styles['section-container']}>
        <div style={{ display: 'flex', justifyContent: 'center', columnGap: '1rem' }}>
          <div style={{ width: '100%' }}>
            <DiscountField label={'semana 1'} dispatchType={'UPDATE_PROJECT'}
                           dispatchParam={'week1'} data={data} value={'como'}
                           dispatch={createProjectDispatch} placeholder={''}/>
          </div>
          <div style={{ width: '100%' }}>
            <DiscountField label={'semana 2'} dispatchType={'UPDATE_PROJECT'}
                           dispatchParam={'week2'} data={data}
                           dispatch={createProjectDispatch} placeholder={''}/>
          </div>
          <div style={{ width: '100%' }}>
            <DiscountField label={'semana 3'} dispatchType={'UPDATE_PROJECT'}
                           dispatchParam={'week3'} data={data}
                           dispatch={createProjectDispatch} placeholder={''}/>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', columnGap: '1rem' }}>
          <div style={{ width: '100%' }}>
            <DiscountField label={'semana 4'} dispatchType={'UPDATE_PROJECT'}
                           dispatchParam={'week4'} data={data} value={'como'}
                           dispatch={createProjectDispatch} placeholder={''}/>
          </div>
          <div style={{ width: '100%' }}>
            <DiscountField label={'semana 5'} dispatchType={'UPDATE_PROJECT'}
                           dispatchParam={'week5'} data={data}
                           dispatch={createProjectDispatch} placeholder={''}/>
          </div>
          <div style={{ width: '100%' }}>
            <DiscountField label={'semana 6'} dispatchType={'UPDATE_PROJECT'}
                           dispatchParam={'week6'} data={data}
                           dispatch={createProjectDispatch} placeholder={''}/>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', columnGap: '1rem' }}>
          <div style={{ width: '100%' }}>
            <DiscountField label={'semana 7'} dispatchType={'UPDATE_PROJECT'}
                           dispatchParam={'week7'} data={data} value={'como'}
                           dispatch={createProjectDispatch} placeholder={''}/>
          </div>
          <div style={{ width: '100%' }}>
            <DiscountField label={'semana 8'} dispatchType={'UPDATE_PROJECT'}
                           dispatchParam={'week8'} data={data}
                           dispatch={createProjectDispatch} placeholder={''}/>
          </div>
          <div style={{ width: '100%' }}>
            <DiscountField label={'semana 9'} dispatchType={'UPDATE_PROJECT'}
                           dispatchParam={'week9'} data={data}
                           dispatch={createProjectDispatch} placeholder={''}/>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', columnGap: '1rem' }}>
          <div style={{ width: '100%' }}>
            <DiscountField label={'semana 10'} dispatchType={'UPDATE_PROJECT'}
                           dispatchParam={'week10'} data={data} value={'como'}
                           dispatch={createProjectDispatch} placeholder={''}/>
          </div>
          <div style={{ width: '100%' }}>
            <DiscountField label={'semana 11'} dispatchType={'UPDATE_PROJECT'}
                           dispatchParam={'week11'} data={data}
                           dispatch={createProjectDispatch} placeholder={''}/>
          </div>
          <div style={{ width: '100%' }}>
            <DiscountField label={'semana 12'} dispatchType={'UPDATE_PROJECT'}
                           dispatchParam={'week12'} data={data}
                           dispatch={createProjectDispatch} placeholder={''}/>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', columnGap: '1rem' }}>
          <div style={{ width: '100%' }}>
            <DiscountField label={'semana 13'} dispatchType={'UPDATE_PROJECT'}
                           dispatchParam={'week13'} data={data} value={'como'}
                           dispatch={createProjectDispatch} placeholder={''}/>
          </div>
          <div style={{ width: '100%' }}>
            <DiscountField label={'semana 14'} dispatchType={'UPDATE_PROJECT'}
                           dispatchParam={'week14'} data={data}
                           dispatch={createProjectDispatch} placeholder={''}/>
          </div>
          <div style={{ width: '100%' }}>
            <DiscountField label={'semana 15'} dispatchType={'UPDATE_PROJECT'}
                           dispatchParam={'week15'} data={data}
                           dispatch={createProjectDispatch} placeholder={''}/>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', columnGap: '1rem' }}>
          <div style={{ width: '100%' }}>
            <DiscountField label={'semana 16'} dispatchType={'UPDATE_PROJECT'}
                           dispatchParam={'week16'} data={data} value={'como'}
                           dispatch={createProjectDispatch} placeholder={''}/>
          </div>
          <div style={{ width: '100%' }}>
            <DiscountField label={'semana 17'} dispatchType={'UPDATE_PROJECT'}
                           dispatchParam={'week17'} data={data}
                           dispatch={createProjectDispatch} placeholder={''}/>
          </div>
          <div style={{ width: '100%' }}>
            <DiscountField label={'semana 18'} dispatchType={'UPDATE_PROJECT'}
                           dispatchParam={'week18'} data={data}
                           dispatch={createProjectDispatch} placeholder={''}/>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', columnGap: '1rem' }}>
          <div style={{ width: '100%' }}>
            <DiscountField label={'semana 19'} dispatchType={'UPDATE_PROJECT'}
                           dispatchParam={'week19'} data={data} value={'como'}
                           dispatch={createProjectDispatch} placeholder={''}/>
          </div>
          <div style={{ width: '100%' }}>
            <DiscountField label={'semana 20'} dispatchType={'UPDATE_PROJECT'}
                           dispatchParam={'week20'} data={data}
                           dispatch={createProjectDispatch} placeholder={''}/>
          </div>
          <div style={{ width: '100%' }}>
            <DiscountField label={'semana 21'} dispatchType={'UPDATE_PROJECT'}
                           dispatchParam={'week21'} data={data}
                           dispatch={createProjectDispatch} placeholder={''}/>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', columnGap: '1rem' }}>
          <div style={{ width: '100%' }}>
            <DiscountField label={'semana 22'} dispatchType={'UPDATE_PROJECT'}
                           dispatchParam={'week22'} data={data} value={'como'}
                           dispatch={createProjectDispatch} placeholder={''}/>
          </div>
          <div style={{ width: '100%' }}>
            <DiscountField label={'semana 23'} dispatchType={'UPDATE_PROJECT'}
                           dispatchParam={'week23'} data={data}
                           dispatch={createProjectDispatch} placeholder={''}/>
          </div>
          <div style={{ width: '100%' }}>
            <DiscountField label={'semana 24'} dispatchType={'UPDATE_PROJECT'}
                           dispatchParam={'week24'} data={data}
                           dispatch={createProjectDispatch} placeholder={''}/>
          </div>
        </div>
      </div>
    );
};

export default Discounts;
