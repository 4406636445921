import React, { createContext, useReducer, useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { createProjectReducer, ProjectReducer } from '../reducer/ProjectReducer';
import { projectStructure } from '../dto/project';
import projectApi from '../api/ProjectApi';
import { UserDataContext } from '../context/UserContext';

export const ProjectContext = createContext('projects');

const ProjectContextProvider = ({ children }) => {
    const { user } = useContext(UserDataContext);

    const [createProject, createProjectDispatch]
        = useReducer(createProjectReducer, (projectStructure || {}));
    const [projects, ProjectsDispatch]
        = useReducer(ProjectReducer, []);

    const [projectsByUser = [], setProjectsByUser] = useState([]);

    const [selectedProject, setSelectedProject]
        = useState();

    const [dataFormProject, setDataFormProject] = useState({});

    const [panels, setPanels] = useState(0);

    const [discount, setDiscount] = useState([]);
    const [loading, setLoading] = useState(true);

    const share = {
        createProject,
        createProjectDispatch,
        projects,
        selectedProject,
        setSelectedProject,
        projectsByUser,
        panels,
        setPanels,
        dataFormProject,
        setDataFormProject,
        loading,
        discount,
        setDiscount,
    };

    useEffect(() => {
        projectApi.getProjects().then((response) =>
        ProjectsDispatch({ type: 'PROJECTS', projects: response }));

        if (user?.email) {
            projectApi.getProjectsByUser(user.email).then((resp) => setProjectsByUser(resp));
        }
        setLoading(false);
    // eslint-disable-next-line
    }, []);

    return <ProjectContext.Provider value={share}>{children}</ProjectContext.Provider>;
};

ProjectContextProvider.propTypes = {
    children: PropTypes.oneOfType(
        [PropTypes.object, PropTypes.arrayOf(PropTypes.element)]).isRequired,
};

export default ProjectContextProvider;
