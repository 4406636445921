import React from 'react';
import ScriptTag from 'react-script-tag';
import PropTypes from 'prop-types';

const PaymentGateWay = ({ paymentData = {} }) => {
  const { total = 0, projectName, projectId, email, panels = 0, transactionType } = paymentData;
  const testEpayco = process.env.REACT_APP_GCP_ENV !== 'prod';

  const date = new Date().getTime();
  return (
    <>
      <ScriptTag
        src={'https://checkout.epayco.co/checkout.js'}
        className={'epayco-button'}
        data-epayco-key={'fe4c706cc5cc2e0cd985fd6f04525f4a'}
        data-epayco-amount={total}
        data-epayco-name={projectName}
        data-epayco-description={projectName}
        data-epayco-currency={'cop'}
        data-epayco-country={'co'}
        data-epayco-test={`${testEpayco}`}
        data-epayco-external={'false'}
        data-epayco-invoice={`${projectId}_${date}`}
        data-epayco-extra1={email}
        data-epayco-extra2={panels}
        data-epayco-extra3={transactionType}
      ></ScriptTag>
    </>
  );
};

PaymentGateWay.propTypes = {
  paymentData: PropTypes.object,
};

export default PaymentGateWay;
