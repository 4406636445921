/* eslint-disable react/prop-types, max-lines */
import React, { useContext, useState } from 'react';
import TwoFAuthentication from '../../2FAuthentication/TwoFAuthentication';
import { ProfileContext } from '../../../context/ProfileContext';
import { Dialog } from '@rmwc/dialog';
import { UserDataContext } from '../../../context/UserContext';
import { firestore } from '../../../firebase';
import Account from './../../../res/images/profile.svg';
import { TextField } from '@rmwc/textfield';
import { Button } from '@rmwc/button';
import {
 mdiCameraPlusOutline, mdiPencilPlusOutline, mdiExitToApp, mdiTextBoxOutline,
 mdiFileAccountOutline, mdiTextBox, mdiFileCabinet, mdiFileDocument
} from '@mdi/js';
import Icon from '@mdi/react';
import styles from './profileDescription.module.css';
import Utils from '../../../utils/Utils';
import UserApi from '../../../api/UserApi';

const LoadingLabel = ({ loading, text }) => {
  if (!loading) {
    return <label>{text}
      <span className={styles['span-required']}> *</span></label>;
  } else {
    return <label className={styles['loading-label']}> cargando ... </label>;
  }
};

const DataForm = ({ user }) => {
  const [edit, setEdit] = useState(true);
  const [name, setName] = useState(user?.name || '');
  const [email, setEmail] = useState(user?.email || '');
  const [phoneNumber, setPhoneNumber] = useState(user?.phoneNumber || user?.phone || '');
  const [country, setCountry] = useState(user?.country || '');
  const [city, setCity] = useState(user?.city || '');
  const [address, setAddress] = useState(user?.address || '');
  const [department, setDepartment] = useState(user?.department || '');
  const [nit, setNit] = useState(user?.nit || '');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const button = () => {
    if (!edit) {
      return (
        <Button className={styles['button-edit']}
                onClick={() => setEdit(prevState => !prevState)}
                unelevated >
          <Icon path={mdiExitToApp} size={1}/>
        </Button>
      );
    } else {
      return (
        <Button className={styles['button-edit']}
                onClick={() => setEdit(prevState => !prevState)}
                unelevated >
          <Icon path={mdiPencilPlusOutline} size={1}/>
        </Button>
      );
    }
  };

  const googleForm = (user?.provider === 'google.com') ? styles['google-form'] : styles.form;

  const saveData = async () => {
    setLoading(true);
    setEdit(true);
    const path = firestore.collection('users').doc(user.email);
    const value = await path.get();
    const data = {
      name, address, city, country, department, email, phoneNumber, nit,
    };
    if (value.exists) {
      path.set({ user: data }, { merge: 'true' }).then(() => {
        setTimeout(() => {
          setLoading(false);
          setEdit(false);
        }, 1200);
      });
    }
  };
  const loadingDiv = (loading) ? styles['loading-div'] : styles.none;

  return (
    <div className={googleForm}>
      <h4>Información personal</h4>
      {button()}
      <div className={loadingDiv}>
        <h5>Guardando...</h5>
      </div>
      <div className={styles['form-input']}>
        <LoadingLabel loading={loading} text={'Nombres y apellidos'}/>
        <TextField className={styles.input} disabled={edit}
                   value={name} onChange={(e) => setName(e.target.value.toString())}
                   outlined/>
      </div>
      <div className={styles['form-input']}>
        <LoadingLabel loading={loading} text={'Número de identificación'}/>
        <TextField className={styles.input} value={nit}
                   type={'number'}
                   onKeyPress={Utils.preventTextInNumberInput}
                   onChange={(e) => setNit(e.target.value.toString())} disabled={edit}
                   outlined/>
      </div>
      <div className={styles['form-input']}>
        <LoadingLabel loading={loading} text={'Correo electrónico'}/>
        <TextField className={styles.input} disabled={edit}
                   value={email} onChange={(e) => setEmail(e.target.value.toString())}
                   outlined/>
      </div>
      <div className={styles['form-input']}>
        <LoadingLabel loading={loading} text={'Telefono'}/>
        <TextField className={styles.input} value={phoneNumber}
                   type={'number'}
                   onChange={(e) => setPhoneNumber(e.target.value.toString())}
                   onKeyPress={Utils.preventTextInNumberInput}
                   disabled={edit}
                   outlined/>
      </div>
      <div className={styles['form-input']}>
        <LoadingLabel loading={loading} text={'País de residencia'}/>
        <TextField className={styles.input} value={country}
                   onChange={(e) => setCountry(e.target.value.toString())}
                   disabled={edit} outlined/>
      </div>
      <div className={styles['form-input']}>
        <LoadingLabel loading={loading} text={'Departamento de residencia'}/>
        <TextField className={styles.input} value={department}
                   onChange={(e) => setDepartment(e.target.value.toString())}
                   disabled={edit} outlined/>
      </div>
      <div className={styles['form-input']}>
        <LoadingLabel loading={loading} text={'Ciudad de residencia'}/>
        <TextField className={styles.input} value={city}
                   onChange={(e) => setCity(e.target.value.toString())}
                   disabled={edit} outlined/>
      </div>
      <div className={styles['form-input']}>
        <LoadingLabel loading={loading} text={'Dirección de residencia'}/>
        <TextField className={styles.input} value={address}
                   onChange={(e) => setAddress(e.target.value.toString())}
                   disabled={edit} outlined/>
      </div>
      <Button label={'Guardar'} onClick={() => saveData()} className={styles.button} unelevated/>
      <Button label={'Verificar identidad'} onClick={() => setOpen(true)}/>
      <Dialog open={open}
              onClose={() => setOpen(false)}>
        {
          open
          && (<TwoFAuthentication provider={user.authProvider} isValid={(event) => setOpen(!open)}
                                  isPayment={false} newVerification={true} />)
        }
      </Dialog>
    </div>
    );
};

const PasswordForm = ({ user }) => {
  const [edit, setEdit] = useState(true);

  const button = () => {
    if (!edit) {
      return (
        <Button className={styles['button-edit']}
                onClick={() => setEdit(prevState => !prevState)}
                unelevated >
          <Icon path={mdiExitToApp} size={1}/>
        </Button>
      );
    } else {
      return (
        <Button className={styles['button-edit']}
                onClick={() => setEdit(prevState => !prevState)}
                unelevated >
          <Icon path={mdiPencilPlusOutline} size={1}/>
        </Button>
      );
    }
  };

  const dissapearForm = (user?.provider === 'google.com') ? styles.none : styles.form;

  return (
    <div className={dissapearForm}>
      <h4>Cambiar contraseña</h4>
      {button()}
      <div className={styles['form-input']}>
        <label>Antigua contraseña
          <span className={styles['span-required']}> *</span></label>
        <TextField className={styles.input} disabled={edit} outlined/>
      </div>
      <div className={styles['form-input']}>
        <label>Nueva contraseña
          <span className={styles['span-required']}> *</span></label>
        <TextField className={styles.input} disabled={edit} outlined/>
      </div>
      <div className={styles['form-input']}>
        <label>Confirma nueva contraseña
          <span className={styles['span-required']}> *</span></label>
        <TextField className={styles.input} disabled={edit} outlined/>
      </div>
      <Button label={'Guardar'} className={styles.button} unelevated/>
    </div>
  );
};

// eslint disabled prop-types
const DocumentModule = ({ profile }) => {
  const { documents } = profile;
  const handleClick = async (filePath) => {
    const { data = '' } = await UserApi.getSignedUrl(filePath) || {};
    const screenWidth = 1280;
    const screenHeight = 720;

    if (data) {
      const left = (window.screen.width - screenWidth) / 2;
      const top = (window.screen.height - screenHeight) / 4;
      const popUpObj = window.open(data, 'document', `width=${screenWidth},height=${screenHeight}`
        + `,menubar=yes,resizable=yes,scrollbars=no,status=no,location=no,top=${top},left=${left}`);
      popUpObj.focus();
    }
  };

  const pathfile = (position) => {
    switch (position) {
      case 0: return mdiFileAccountOutline;
      case 1: return mdiTextBox;
      case 2: return mdiFileDocument;
      case 3: return mdiFileCabinet;
      case 4: return mdiFileDocument;
      default: return mdiTextBoxOutline;
    };
  };

  return (
    <div className={styles['document-form']}>
      <h4>Tus documentos</h4>
      {
        Object.keys(documents).map((file, pos) =>
          <div key={pos} className={styles.file} onClick={() => handleClick(documents[file])}>
            <Icon className={styles['file-icon']} path={pathfile(pos)} size={2}/>
            <p className={styles['file-text']}>{file}</p>
          </div>
        )
      }
    </div>
  );
};

const ProfileDescription = () => {
  const { basicUserInformation, user } = useContext(UserDataContext);
  const { profile = {} } = useContext(ProfileContext);
    return <>
      <div className={styles.content}>
        <h1>Mi perfil</h1>
        <hr/>
        <div className={styles.description}>
          <div className={styles['profile-img']}>
            <img src={!user?.photoURL ? Account : user.photoURL}
                 alt={'profile_photo'}/>
            <div className={styles.icon} style={{ display: 'none' }}>
              <Icon path={mdiCameraPlusOutline} size={1}/>
            </div>
          </div>
          <div className={styles['documents-module']}>
            <DocumentModule profile={profile}/>
          </div>
          <div className={styles.flex}>
            <DataForm user={{
              ...basicUserInformation.user,
              authProvider: basicUserInformation.tfaProvider,
            }}/>
            <PasswordForm user={basicUserInformation.user}/>
          </div>
        </div>
      </div>
    </>;
};

export default ProfileDescription;
