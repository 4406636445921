/* eslint-disable react/prop-types */
import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogButton
} from '@rmwc/dialog';
import Icon from '@mdi/react';
import { mdiLoading, mdiAlertCircleOutline, mdiProgressCheck } from '@mdi/js';
import styles from './Dialog.module.css';

const DialogDrawer = ({ status, openValidate, setOpenValidate }) => {
  return (
    <>
      <Dialog
        open={openValidate}
        onClose={evt => {
          setOpenValidate(false);
        }}
      >
        <DialogTitle>
          {(status === 'loading')
               ? 'estamos validando tu identidad'
               : (status === 'error')
               ? 'UPS!'
               : (status === 'pass')
               ? 'validamos tu identidad correctamente'
               : (status === 'noValidate')
               ? 'tu identidad no pudo ser validada'
               : (status === 'maxPanels')
               ? 'Sobrepasaste el número de paneles'
               : <></>
               }
        </DialogTitle>
        <DialogContent>
          <div style={{ textAlign: 'center' }}>
            { (status === 'loading')
               ? <Icon className={styles['infinate-rotate']} path={mdiLoading} size={5}/>
               : (status === 'error')
               ? <Icon className={styles['error-icon']}
                       path={mdiAlertCircleOutline} size={5}/>
               : (status === 'pass')
               ? <Icon className={styles['pass-icon']}
                       path={mdiProgressCheck} size={5}/>
               : (status === 'noValidate')
               ? <Icon className={styles['pass-icon']}
                       path={mdiAlertCircleOutline} size={5}/>
               : (status === 'maxPanels')
               ? <Icon className={styles['pass-icon']}
                       path={mdiAlertCircleOutline} size={5}/>
               : <></>
            }
          </div>
          {(status === 'loading')
               ? 'esto puede tomar unos segundos'
               : (status === 'error')
               ? 'parece que hubo un error al validar tu identidad vuelve a intentarlo'
               : (status === 'pass')
               ? 'continua con tu proceso'
               : (status === 'noValidate')
               ? ''
               : (status === 'maxPanels')
               ? 'seras redireccionado al inicio'
               : <></>}
        </DialogContent>
        <DialogActions>
          <DialogButton action={'close'} isDefaultAction>
            Aceptar
          </DialogButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

  export default DialogDrawer;

