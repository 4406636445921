import { useState, useEffect } from 'react';
import Utils from '../utils/Utils';

const useChartData = ({ actualMonth, defaultprojectUser }) => {
  const [projectsUser, setProjectsUser] = useState(defaultprojectUser || []);
  const [chartData, setChartData] = useState();
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [monthlyIncome, setMonthlyIncome] = useState(0);
  const [share, setShare] = useState(0);
  const [costEffectiveness, setCostEffectiveness] = useState(0);
  const [startDate, setStartDate] = useState(actualMonth);
  const [endDate, setEndDate] = useState(actualMonth);
  const [btnPeriod, setBtnPeriod] = useState('week');

  const getRenuevePerProject = (project) => {
    const { earnings = [] } = project;
    let totalRenueve = 0;
    const flatArray = earnings.flat(Infinity);
    flatArray.forEach((record) => {
      const { total = [] } = record;
      totalRenueve += total.reduce((acc, curr) => acc + curr, 0);
    });
    return totalRenueve.toFixed(0);
  };

  const getTotalRevenuePerWeek = () => {
    const totalPerProject = projectsUser.map((project) => {
      const { earnings = [] } = project;
      const dateStart = new Date(startDate);
      const dateEnd = new Date(endDate);
      const actualDate = dateStart.getTime() === dateEnd.getTime()
        ? dateStart
        : new Date(actualMonth);

      const flatArray = earnings.filter((item) => {
        const { month } = item;
        const date = new Date(month);
        return (actualDate.getTime() === date.getTime());
      });
      return Utils.getRenuevePerProject(flatArray);
    });

    const dataAux = [];
    if (totalPerProject.length <= 1) {
      totalPerProject.forEach((item) => {
        dataAux.push(...item);
      });
    } else {
      for (let i = 0; i < totalPerProject.length; i++) {
        let value = 0;
        for (let j = 0; (j < totalPerProject[i].length && j < totalPerProject.length); j++) {
          value += (Number(totalPerProject[j][i]) || 0);
        }
        dataAux.push(value);
      }
    }

    setMonthlyIncome(dataAux.reduce((acc, curr) => acc + curr, 0));
    const labels = Utils.getWeeks(startDate);
    setChartData(Utils.generateChartData({
      labels,
      chartData: dataAux,
    }));
    return dataAux;
  };

  const groupedDataPerMonth = () => {
    const groupedDataPerMonth = Utils.groupBy(projectsUser, 'month', { startDate, endDate });
    const labels = Object.keys(groupedDataPerMonth);
    const data = Object.values(groupedDataPerMonth);
    const dataPerMonth = data.map((record) => {
      let add = 0;
      record.forEach((project) => (add += project.total));
      return add.toFixed(0);
    });

    setChartData(Utils.generateChartData({
      labels,
      chartData: dataPerMonth,
      period: 'month',
    }));
  };

  const groupedDataPerYear = () => {
    const startYear = (new Date(startDate)).getFullYear();
    const groupedData = Utils.groupBy(
      projectsUser,
      'year',
      { startDate: startYear.toString(), endDate });
    const labels = Object.keys(groupedData);
    const data = Object.values(groupedData);
    const dataPerYear = data.map((record) => {
      let add = 0;
      record.forEach((project) => (add += project.total));
      return add.toFixed(0);
    });

    setChartData(Utils.generateChartData({
      labels,
      chartData: dataPerYear,
      period: 'year',
    }));
  };

  useEffect(() => {
    if (projectsUser.length <= 0) return;
    setCostEffectiveness(0);
    setMonthlyIncome(0);
    setShare(0);

    let total = 0;
    setCostEffectiveness(prevState => parseFloat(prevState) / projectsUser.length);
    (projectsUser).forEach((item) => {
      setShare(prevState => prevState + item.sharePercentage);
      setCostEffectiveness(prevState => prevState + parseFloat(item.project.annualRest));
      total += parseInt(getRenuevePerProject(item));
    });
    setTotalRevenue(total);
    let data;
    switch (btnPeriod) {
      case 'week':
        data = getTotalRevenuePerWeek();
        break;
      case 'month':
        groupedDataPerMonth();
        break;
      case 'year':
        groupedDataPerYear();
        break;
      default:
        break;
      }

    if (data) setMonthlyIncome(data.reduce((acc, curr) => acc + curr, 0));
    // eslint-disable-next-line
  }, [projectsUser, btnPeriod, startDate, endDate]);

  return {
    getRenuevePerProject,
    startDate,
    endDate,
    totalRevenue,
    monthlyIncome,
    btnPeriod,
    share,
    costEffectiveness,
    chartData,
    setStartDate,
    setEndDate,
    setProjectsUser,
    setBtnPeriod,
  };
};

export default useChartData;
