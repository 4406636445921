export const createLandinReducer = (state, action) => {
    switch (action.type) {
        case 'GET_QUESTION': {
            return {
                ...state,
                questions: action.question,
            };
        }
        case 'ADD_QUESTION': {
            return {
                ...state,
                questions: [...state.questions, action.info],
            };
        }
        case 'DELETE_QUESTION': {
            return {
                ...state,
                questions: state.questions
                    .filter(element => element.idQuestion !== action.idQuestion),
            };
        }
        case 'UPDATE_QUESTION': {
            const { idQuestionSave, answer, question } = action.dataUpdate;
            return {
                ...state,
                questions: state.questions
                    .map(element => {
                        if (element.idQuestion === idQuestionSave) {
                            return {
                                ...element,
                                info: {
                                    ...element.info,
                                    answer,
                                    question,
                                },
                            };
                        }
                        return element;
                    }),
            };
        }
        default:
            return state;
    }
};
