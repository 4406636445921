/* eslint-disable max-lines */
/* eslint-disable multiline-ternary */
/* eslint-disable max-len */
import React, { useState, useContext, useEffect } from 'react';
import { Button } from '@rmwc/button';
import { Checkbox } from '@rmwc/checkbox';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import useAnalytics from '../../hooks/useAnalytics';
import Icon from '@mdi/react';
import PaymentContract from '../../resources/Contract';
import PaymentGateWay from '../paymentGateway/PaymentGateway';
import styles from './payment.module.css';
import ValidationDialog from './validation/dialog';
import UserApi from '../../api/UserApi';
import DialogDrawer from '../mdc/Dialog/Dialog';
import ValidationWallet from './validation/ValidateWallet';

import {
  Dialog, DialogTitle, DialogContent,
  DialogActions, DialogButton
} from '@rmwc/dialog';

import { ProjectContext } from '../../context/ProjectContext';
import { UserDataContext } from '../../context/UserContext';
import { ProfileContext } from '../../context/ProfileContext';

import {
  DataTable,
  DataTableRow,
  DataTableBody,
  DataTableCell
} from '@rmwc/data-table';
import { CardMedia } from '@rmwc/card';
import { BasicInformation, BasicInformationBase } from './payment-information/BasicInformation';
import { mdiCreditCardOutline, mdiWalletOutline, mdiAlertCircleOutline } from '@mdi/js';
import { DialogPayment, DialogPaymentConfirm } from './payment-information/DialogPayment';
import Utils from '../../utils/Utils';
import { Qr } from '../qr/Qr';

// eslint-disable-next-line react/prop-types
const SecondSection = ({ methods, dispatch, projectData }) => {
  // eslint-disable-next-line react/prop-types
  const { setTermsAndConditions, termsAndConditions, setSection, setPayment, payment, userVerified, statusQr } = methods;

  const { basicUserInformation } = useContext(UserDataContext);
  const { trackEvent } = useAnalytics(`payment - ${projectData}`);
  const { tfaProvider } = basicUserInformation;
  const [validate, setValidate] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openValidate, setOpenValidate] = useState(false);
  const [typeDialog, setTypeDialog] = useState('');

  const identityValidation = () => {
    if ((userVerified & termsAndConditions) || (validate & termsAndConditions)) {
      setSection(1);
      trackEvent('2fa', 'success');
    } else {
      setOpenDialog(true);
      setTypeDialog('notValidated');
      trackEvent('2fa', 'failed');
    }
  };

  useEffect(() => {
    if (statusQr === 'loading') setOpenValidate(true);
  }, [statusQr]);
  return <>
    <ValidationDialog openDialog={openDialog}
                      setOpenDialog={setOpenDialog}
                      type={typeDialog}/>

    <DialogDrawer openValidate={openValidate}
                  setOpenValidate={setOpenValidate}
                  status={statusQr}/>
    <div className={styles.second_section_body}>
      <div className={styles.second_section_body_title}>
        <font><b></b></font><font><b>CONTRATO
          MARCO DE COLABORACIÓN – FARM ENERGY S.A.S E.S.P.</b></font>
      </div>

      <div className={styles.second_section}>
        <div className={styles.second_section_text}>
          <PaymentContract />
        </div>
      </div>
    </div>
    <div className={styles.second_section_firm_accept}>
      <Checkbox label={'He leido y acepto el contrato Marco de Colaboracion '}
                onChange={(e) => {
                  trackEvent('check', 'terms & conditions');
                  setTermsAndConditions(e.currentTarget.checked);
                }}
                style={{ fontWeight: '100' }}
      />
      <div className={styles.second_section_firm_accept_content}>
        <div className={styles.second_section_firm_accept_info}>
          { /* eslint-disable-next-line */}
          <a
            onClick={async () => {
              if (!basicUserInformation.uploadedDocuments) {
                setTypeDialog('noDocuments');
                setOpenDialog(true);
                return;
              };
              setValidate(false);
              setPayment(true);
              trackEvent('click', '2fa');
            }} >
            Marco de validación de identidad y firma digital
          </a>
          {
            (payment)
              ? <Qr
                tfaProvider={tfaProvider}
                open={payment}
                setOpen={setPayment}
                validate={validate}
                setValidate={setValidate}
              />
              : <></>
          }
        </div>
        <Button raised className={styles.second_section_button}
                onClick={() => identityValidation()}>Continuar</Button>
      </div>
    </div>

  </>;
};

// eslint-disable-next-line react/prop-types
const ThirdSection = ({ methods, section, panels }) => {
  // eslint-disable-next-line react/prop-types
  const { auxProjectData = {}, projectId, basicUserInformation, setSection, setTermsAndConditions, total } = methods;
  const { project = {} } = auxProjectData;
  const { trackEvent } = useAnalytics('payment');

  // eslint-disable-next-line react/prop-types
  const { user = {} } = basicUserInformation;
  const { profile = {} } = useContext(ProfileContext);
  const { user: userProfile = {} } = profile;
  const { wallet = {} } = userProfile;

  const [openDialog, setOpenDialog] = useState(false);
  const [openWallet, setOpenWallet] = useState(false);
  const [typeDialog, setTypeDialog] = useState('noPay');

  const [openModal, setOpenModal] = useState(false);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [sharePercentage, setSharePercentage] = useState(0);
  const balance = wallet?.amount
    ? Utils.formatter.format(wallet.amount)
    : Utils.formatter.format(0);

  const dataRow = (text1, text2) => {
    return (
      <DataTableRow className={styles['table-row']}>
        <DataTableCell className={styles['table-cell']}>{text1}</DataTableCell>
        <DataTableCell className={styles['table-cell']}>{text2}</DataTableCell>
      </DataTableRow>
    );
  };
  const history = useHistory();

  const transactionData = {
    email: user.email,
    amount: total,
    projectId,
    panels,
    sharePercentage,
    description: project.projectName,
  };

  useEffect(() => {
    const percentage = (panels * 100) / project.noOfDigitalPanels;
    setSharePercentage(percentage);
  }, [panels, project.noOfDigitalPanels]);

  const walletPay = async () => {
    if (total <= 0) {
      setTypeDialog('noPanels');
      setOpenDialog(true);
      return false;
    } else if (wallet.amount < total) {
      setTypeDialog('noPay');
      setOpenDialog(true);
      return false;
    } else {
        const response = await UserApi.walletTransaction(transactionData);
        history.push(`/walletResponse?id=${response.data.reference}&email=${user.email}`);
      return true;
    }
  };

  const paymentData = {
    total,
    projectName: project.projectName,
    projectId,
    email: user?.email,
    panels,
    transactionType: '1',
    sharePercentage,
    description: project.projectName,
  };

  return (
    <div className={styles.third_section_contain}>
      <ValidationDialog openDialog={openDialog}
                        setOpenDialog={setOpenDialog}
                        type={typeDialog}/>
      <ValidationWallet openWallet={openWallet}
                        setOpenWallet={setOpenWallet}
                        action={walletPay}/>
      <p>COMPROBANTE DE SUSCRIPCIÓN Y CONDICIONES ESPECÍFICAS DEL PROYECTO</p>
      <DataTable className={styles.table}>
        <DataTableBody className={styles['table-body']}>
          {dataRow('Nombre del inversor;', user.name)}
          {dataRow('C.C / Nit:', user.nit)}
          {dataRow('Nombre del proyecto', project.projectName)}
          {dataRow('Ubicación del proyecto:', project.location?.address || project.address)}
          {dataRow('Valor de la inversión:', Utils.formatter.format(total))}
          {dataRow('N° de paneles digitales comprados:', panels)}
          {dataRow('Porcentaje de participación:', `${sharePercentage.toFixed(2)}%`)}
          {dataRow('Interés estimado E.A.:', '10.5%')}
          {dataRow('Inicio de etapa de construcción:', project.beginningDate)}
          {dataRow('Inicio de etapa de operación', project.beginningDate)}
          {dataRow('Porcentaje de administración FARM ENERGY', '10.3%')}
          {dataRow('Estado de la transacción', 'En espera para iniciar')}
        </DataTableBody>
      </DataTable>
      <p className={styles.third_section_contain_title}>Selecciona un metodo de pago</p>
      <div className={section !== 0 ? styles.payment_card_section_hidden : styles.payment_card_section}>
        <div className={styles.payment_card_section_one}>
          <Icon className={styles.icon_one} path={mdiCreditCardOutline} size={3.5} />
          <div className={styles.payment_cards_section}>
            <img
              src={'https://lh3.googleusercontent.com/yUG1c5r8TEHT0OTTwknXVjXfnPigYN_vqGoIk0D2YRjM7FYVAwoCPteewWeY8apORM7M'}
              alt={'pse_logo'} />
            <img
              src={'https://1000marcas.net/wp-content/uploads/2019/12/logo-Mastercard.png'}
              alt={'mastercard_logo'} />
            <img
              src={'https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/Visa_Inc._logo.svg/1200px-Visa_Inc._logo.svg.png'}
              alt={'visa_logo'} />
          </div>
          <div className={styles.payment_cards_section_two}>
            <input type={'checkbox'} className={styles.payment_hidden} />
            <small >Tarjeta de crédito / débido</small>
          </div>
        </div>
        <div className={styles.payment_card_section_two}>
          <div>
            <p>Saldo {balance} </p>
          </div>
          <Icon className={styles.icon_two}
                path={mdiWalletOutline}
                size={3.5}
                style={{ marginTop: '1rem' }}/>
          <div className={styles.payment_card_options}>
            <Button raised unelevated
                    className={styles['button-wallet']}
                    onClick={() => {
                      trackEvent('click', 'wallet');
                      setOpenWallet(true);
                    }}
            > Pagar con billetera </Button>
            <div className={styles.payment_card_option_wallet}>
              <small style={{ marginTop: '1rem' }}>Billetera virtual</small>
            </div>
          </div>
        </div>

      </div>

      <div className={styles.payment_epayco} onClick={() => trackEvent('click', 'epayco')}>
        <PaymentGateWay paymentData={paymentData} />
      </div>
      <Button
        raised
        onClick={() => {
          trackEvent('click', 'back');
          setSection(0);
          setTermsAndConditions(0);
        }}
        className={styles.third_section_button}
      >Volver</Button>
      <DialogPayment openModal={openModal} setOpenModal={setOpenModal} setOpenModalConfirm={setOpenModalConfirm} />
      <DialogPaymentConfirm openModalConfirm={openModalConfirm} setOpenModal={setOpenModal} setOpenModalConfirm={setOpenModalConfirm} />
    </div>);
};

const Payment = () => {
  const { projects = [], panels = 0, setPanels } = useContext(ProjectContext);
  const { basicUserInformation } = useContext(UserDataContext);
  const { setPayment, payment, userVerified, statusQr } = useContext(ProfileContext);

  const useQuery = () => new URLSearchParams(useLocation().search);

  const valueQueryPanels = useQuery().get('panels');

  useEffect(() => {
    setPanels(valueQueryPanels);
  }, [valueQueryPanels, setPanels]);

  const { projectId = '' } = useParams();
  const [auxProjectData = {}] = projects.filter((project) => project.projectId === projectId);
  const { project = {} } = auxProjectData;

  const [openDialog, setOpenDialog] = useState(false);
  const [section, setSection] = useState(0);
  const [termsAndConditions, setTermsAndConditions] = useState(0);

  const weeksBetween = (date1, date2) => {
    let diff = (date2.getTime() - date1.getTime()) / 1000;
    diff /= (60 * 60 * 24 * 7);
    return Math.abs(Math.round(diff));
  };

  const value = weeksBetween(new Date(), new Date(project?.beginningDateSeed) || new Date());
  const valuesPreDiscount = Object.values(project?.discount || {}).sort((a, b) => (b - a));
  const validateNumber = !value ? 0 : value - 1;
  const valueCurrentPresaleDiscount = value > valuesPreDiscount.length
  ? valuesPreDiscount[value.length - 1]
  : valuesPreDiscount[validateNumber];

  const subtotal = ((project?.panelCost || 1) * panels);
  const discount = (project?.panelCost * panels) - (((valueCurrentPresaleDiscount / 100) * project?.panelCost) * panels) || 0;
  const totalDes = subtotal - discount;
  const maxPanels = Math.ceil(project.noOfDigitalPanels * 0.44);
  const history = useHistory();
  const { trackEvent } = useAnalytics(`payment - ${projectId}`);

  useEffect(() => {
    if (valueQueryPanels > maxPanels) {
    setOpenDialog(true);
    setTimeout(() => {
        history.push('/');
    }, 3000);
    }
    // eslint-disable-next-line
  }, [maxPanels]);

  const share = {
    statusQr,
    section,
    setSection,
    termsAndConditions,
    setTermsAndConditions,
    auxProjectData,
    projectId,
    basicUserInformation,
    totalDes,
    setPayment,
    payment,
    userVerified,
    total: project?.isCrowdfunding ? subtotal : discount,
    panels,
  };

  return (<>
    <Dialog open={openDialog}>
      <DialogTitle>
        <div style={{ alignText: 'center' }}>
          el número de paneles permitido fue excedido
        </div>
      </DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Icon path={mdiAlertCircleOutline} size={6}/>
        </div>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-around' }}>
        <DialogButton onClick={() => trackEvent('error', 'número de paneles permitido fue excedido')}>
          Aceptar
        </DialogButton>
      </DialogActions>
    </Dialog>
    <div className={styles.payment}>
      <div className={styles.payment_card}>
        <div className={styles.payment_card_content}>
          <CardMedia
            className={styles.img}
            sixteenByNine
            style={{ backgroundImage: `url(${'https://powen.mx/wp-content/uploads/2021/05/34-1080x675.jpg'})` }} />
          <BasicInformation firstText={'Proyecto'} SecondText={auxProjectData?.project?.projectName} />
          <BasicInformation firstText={'Rentabilidad anual estimada'} SecondText={`${auxProjectData?.project?.annualRest * 100} %`} />

          <div className={styles.basic_information}>
            <p>Participación</p>
            <div className={styles.basic_information_participation}>
              <div className={styles.basic_information_participation_data}>
                <input value={panels} onChange={e =>
                  (e.target.value <= maxPanels && e.target.value >= 0)
                  ? setPanels(e.target.value)
                  : panels
                } />
                <p>Paneles</p>
              </div>
              <small >Puedes comprar Min 1 - Max {maxPanels}</small>
            </div>
          </div>

          <BasicInformationBase firstText={'Subtotal'} SecondText={Utils.formatterEmpty.format(subtotal)} />
          <BasicInformationBase firstText={'Descuento % pago'} SecondText={`${(valueCurrentPresaleDiscount || 0)} %`} extra percentage={false}/>
          <BasicInformationBase firstText={'Descuentos'} SecondText={Utils.formatterEmpty.format(auxProjectData?.project?.isCrowdfunding ? 0 : -totalDes)} />
          <BasicInformationBase firstText={'Total'} SecondText={Utils.formatterEmpty.format(auxProjectData?.project?.isCrowdfunding ? subtotal : discount)} bold={true} />
        </div>
      </div>
      <div className={styles.payment_tabs}>
        {(section === 0)
        ? <SecondSection projectData={auxProjectData?.project?.projectName} methods={share} />
        : (section === 1)
          ? <ThirdSection methods={share} panels={panels} section={section} /> : ''
    }
      </div>
    </div>
  </>);
};

export default Payment;
