import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './authenticator.module.css';
import { ProfileContext } from '../../context/ProfileContext';
import { TextField } from '@rmwc/textfield';
import GoogleAuth from './GoogleAuth';
import BpsAuth from './BpsAuth';
import { Button } from '@rmwc/button';
import DialogDrawer from '../mdc/Dialog/Dialog';
import recognition from '../../api/Recognition';

const TwoFAuthentication = ({
  provider = 'google',
  isValid,
  isPayment = true,
  newVerification = false,
}) => {
  const {
    profile, statusQr, userVerified, setPayment,
  } = useContext(ProfileContext);
  const [isVerifiedAccount, setVerifiedAccount] = useState(false);
  const [pinValue, setPinValue] = useState('');
  const [url, setUrl] = useState('');
  const [message, setMessage] = useState('');
  const [openValidate, setOpenValidate] = useState(false);
  const [disable, setDisable] = useState(false);
  const [secret, setSecret] = useState('');
  const isGoogleAuth = provider === 'google';

  useEffect(() => {
    switch (provider) {
      case 'google':
        recognition.get2FAuthentication(profile.user, newVerification)
          .then((response) => {
            const { stream: qrData, secretOtp, verifiedAccount } = response.data;
              setVerifiedAccount(verifiedAccount);
              setSecret(secretOtp);
              setUrl(verifiedAccount
                      ? newVerification ? qrData : ''
                      : qrData);
              setPayment(isPayment);
          });
        break;
      case 'bpsauth':
        recognition.sendBasicRequest(profile.user)
          .then((url) => {
            setUrl(url.data.urlQrMovil);
            setPayment(isPayment);
          });
        break;
      default:
        break;
    }
       // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (statusQr === 'loading') setOpenValidate(true);
  }, [statusQr]);

  const validatePin = async () => {
    const { email, nit } = profile.user;
    setDisable(true);
    await recognition.validate2FAuthentication({ token: pinValue, email, nit })
      .then((response) => {
        const { verified, transactionId } = response.data;
        if (verified) isValid({ provider, verificationStatus: verified, transactionId });
        else {
          setMessage('Pin incorrecto');
          setDisable(false);
        };
      });
  };

  return (
    <div className={styles['auth-container']}>
      { isGoogleAuth
        ? (<GoogleAuth qr={url} secret={secret} isVerifiedAccount={isVerifiedAccount}/>)
        : <BpsAuth qr={url} isVerifiedAccount={isVerifiedAccount}/>
      }
      <TextField
            label={'Pin'}
            value={pinValue}
            onChange={(e) => {
              setPinValue(e.target.value);
              setMessage('');
            }}
          />
      <p style={{ color: 'var(--mdc-theme-error)' }}>{message}</p>
      <DialogDrawer openValidate={openValidate}
                    setOpenValidate={setOpenValidate}
                    status={statusQr}/>
      <div className={styles['buttons-password']}>
        <Button label={'Atrás'}
                onClick={() => isValid({ comeBack: true })}
                className={styles['button-quantity']}
                disabled={disable}
                dense />
        <Button label={'Continuar'}
                onClick={isGoogleAuth
                  ? () => validatePin()
                  : isValid({ provider, verificationStatus: userVerified })}
                className={styles['button-quantity']}
                unelevated
                disabled={(isGoogleAuth ? !pinValue : !userVerified) || disable}/>
      </div>
    </div>
  );
};

TwoFAuthentication.propTypes = {
  provider: PropTypes.string,
  isValid: PropTypes.func,
  isPayment: PropTypes.bool,
  newVerification: PropTypes.bool,
};

export default TwoFAuthentication;
