import React, { useState, useEffect, useCallback } from 'react';
import { TextField } from '@rmwc/textfield';
import { Button } from '@rmwc/button';
import { Checkbox } from '@rmwc/checkbox';
import DrawerWrapper from '../../mdc/drawer/DrawerWrapper';
import Toolbar from '../../mdc/toolbar/Toolbar';
import SiteBody from '../../mdc/SiteBody';
import { Snackbar, SnackbarAction } from '@rmwc/snackbar';
import { useHistory } from 'react-router-dom';
import styles from './signup.module.css';
import Icon from '@mdi/react';
import { mdiGoogle } from '@mdi/js';
import { auth, googleAuthProvider } from '../../../firebase';
import signUpImg from './../../../res/images/homeSignup.png';
import UserApi from '../../../api/UserApi';
import useAnalytics from '../../../hooks/useAnalytics';

const SignUp = () => {
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [pass, setPas] = useState('');
    const [snackMessage, setSnackMessage] = useState('');
    const { trackViewPage } = useAnalytics('singup');
    const history = useHistory();

    const setEvent = useCallback(trackViewPage, []);
    useEffect(() => setEvent(), [setEvent]);

    const createAccount = () => {
      auth.fetchSignInMethodsForEmail(email).then((data) => {
        if (data.length > 0) {
          setSnackMessage('Email provided is already in user');
        } else {
          auth.createUserWithEmailAndPassword(email, pass)
          .then(async () => {
              await UserApi.saveBasicUserData({ name: `${name} ${lastName}`, email });
              setSnackMessage('Your account has been created');
              history.push('/');
          })
          .catch((error) => setSnackMessage(error.message));
        }
      })
    .catch((error) => setSnackMessage(error.message));
    };

    const Google = () => {
        auth.signInWithPopup(googleAuthProvider)
      .then(async (data) => {
        await UserApi.saveBasicUserData(data.user.providerData);
        history.push('/');
      })
      .catch((error) => setSnackMessage(error.message));
    };

    return (<>
      <DrawerWrapper/>
      <Toolbar/>
      <SiteBody>
        <div className={styles.container}>
          <img alt={'BPSmart Logo'} src={signUpImg}/>
          <div className={styles.content}>
            <h1>Crea tu cuenta</h1>
            <div className={styles.grid}>
              <TextField label={'Nombres'} className={styles.large} value={name} outlined
                         onChange={(e) => setName(e.target.value.toString())} icon={'account'}/>
              <TextField label={'Apellidos'} className={styles.large} value={lastName} outlined
                         onChange={(e) => setLastName(e.target.value.toString())} icon={'account'}/>
              <TextField label={'Correo electrónico:'} className={styles.large}
                         value={email} outlined
                         pattern={'^[a-z A-Z -\\.]+@([a-z A-Z -]+\\.)+[a-z A-Z -]{2,4}$'}
                         onChange={(e) => setEmail(e.target.value.toString())} icon={'email'}/>
              <TextField label={'Contraseña'} className={styles.large} value={pass} outlined
                         type={'password'} icon={'lock'}
                         onChange={(e) => setPas(e.target.value.toString())}/>
              <Checkbox label={'Acepto los Término y Condiciones de uso de la Plataforma.'}
                        className={styles.large}/>
            </div>
            <div className ={styles['login-buttons']} style={{ width: '100%' }}>

              <Button className={styles['google-button']} raised
                      onClick={() => Google()}
                      >
                <Icon path={mdiGoogle} size={0.8}/>
                Entrar con google
              </Button>
              <Button label={'Crear'} raised
                      className={styles.large} onClick={() => createAccount()}/>
            </div>
            <Button className={styles.large}>
              <Button className={styles['account-button']}
                      onClick={() => history.push('/') }>
                ¿Ya tienes cuenta? Inicia sesión
              </Button>
            </Button>
            <Snackbar
          open={snackMessage} leading
          message={snackMessage}
          dismissesOnAction
          action={
            <SnackbarAction label={'Dismiss'}/>
          }/>
          </div>
        </div>
      </SiteBody>
    </>);
};

export default SignUp;
