import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import 'normalize.css/normalize.css';
import './index.css';
import App from './App';
import UserData from './context/UserContext';

// console.log(JSON.stringify(process.env.REACT_APP_MY_ENV_VAR || 'DEFAULT'));
ReactDOM.render(<>
  <UserData>
    <App />
  </UserData>
</>
  , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
